<div class="main-container self-service-main-container"
style="margin-top: 100px; max-width: 500px; margin-left: 5%">
<div class="main-text"><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;'><a style="color: blue" href=""
        onclick="javascript:window.open('','_self').close()">Close</a></span></b></div>

    <div class="self-service-form-container" style="display:block; max-width: none;width:100%">
        <div class="title"><b><span style='font-size:14.0pt;font-family:"Arial",sans-serif;color:#100B0C'>Privacy Notice</span></b></div>
        <p class="form-title"><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>Last revised: 06<sup>th</sup> April 2023</span></b></p>
        </div>
        <p class="main-text"><span style='font-size:11.0pt;font-family:"Arial",sans-serif;'>This notice covers your interaction with this access management service (“service”) and the processing of personal information that Deloitte Touche Tohmatsu Ltd with registered office at 1 New Street Square, London, EC4A 3HQ (“DTTL”, “Deloitte Global”) performs as a data processor.  All references to “you” or “your” in this privacy notice are to Deloitte-external users and guests (e.g., Deloitte clients, vendors, suppliers) that have been granted access to use a Deloitte-managed application.<br><br> 

        If a Deloitte member firm or other organization decides to grant you access to use a Deloitte-managed application, then DTTL acts as a processor for all processing of your personal information connected with providing you access and use of such applications through this service. If you have questions about or wish to exercise your personal information protection rights in connection with the personal information processing by this service that are not covered by this privacy notice, please contact the Deloitte member firm or organization that decided to grant you access to use a Deloitte-managed application or refer to its privacy notice.</span></p>
    
    <div class="form-title"><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>Personal information which we process through this system as a data processor</span></b></div>
    <a class="anchor" id="whatcovers"></a>
    <p class="main-text"><span style='font-size:11.0pt;font-family:"Arial",sans-serif;'>When you access this service, DTTL may process your email address, name and photo on behalf of the data controller (i.e., the Deloitte member firm or organization that decided to grant you access to use a Deloitte-managed application). </span></p>

    <div class="form-title"><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>How we use information about you</span></b></div>
    <a class="anchor" id="whatcollect"></a>
    <p class="main-text"><span style='font-size:11.0pt;font-family:"Arial",sans-serif;'>Your personal information processed by this service as mentioned above will be used to enable you to access certain Deloitte-managed applications, and to log information about your access to maintain the security and protection of this service.</span></p>

    
    <div class="form-title"><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>Privacy practices of third parties</span></b></div>
    <a class="anchor" id="whatcollect"></a>
    <p class="main-text"><span style='font-size:11.0pt;font-family:"Arial",sans-serif;'>This privacy notice only addresses DTTL’s use of personal information collected through your interaction with this service, as a processor of the Deloitte member firm or organization that decided to grant you access to a Deloitte-managed application. Other websites or applications that may be accessible from this service have their own privacy notices and personal information collection, use and disclosure practices.  We encourage you to familiarize yourself with the privacy notices available on those websites or applications.</span></p>
    
    <div class="form-title"><b><span style='font-size:11.0pt;font-family:"Arial",sans-serif;color:#100B0C'>Additional information</span></b></div>
    <a class="anchor" id="whatcollect"></a>
    <p class="main-text"><span style='font-size:11.0pt;font-family:"Arial",sans-serif;'>If you have privacy-related questions or wish to exercise your personal information protection rights, please contact the Deloitte member firm or organization that decided to grant you access to use a Deloitte-managed application or refer to its privacy notice for more information.</span></p>
    
    
<!-- FOOTER -->
<div class="footer" style="padding-bottom: 0px">
<div class="info-terms">

<div class="info-text">
    <div class="main-text info-text-footer">
        Deloitte refers to one or more of Deloitte Touche Tohmatsu Limited (“DTTL”), its global network of member firms, and their releated entities. DTTL (also referred to as “Deloitte Global”) and each of its member firms are legally separate and independent entities. DTTL does not provide services to clients. Please see",
        <a class="link-description link" href="https://www.deloitte.com/about">www.deloitte.com/about</a>
        to learn more.
    </div>
    <div class="main-text info-text-footer">© 2023. For information, contact Deloitte Touche Tohmatsu Limited.</div>
</div>
</div>
</div>

</div>
<div class="img-container self-service-img-container">
</div>
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OutputFileType } from 'typescript';

@Component({
  selector: 'app-confirmsuspend',
  templateUrl: './confirmsuspend.component.html',
  styleUrls: ['./confirmsuspend.component.css']
})
export class ConfirmsuspendComponent implements OnInit {
  isSuspending = false;
  @Output() onconfirm = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }

  startSuspend()
  {
    this.isSuspending = true;
  }
  confirm()
  {
    this.onconfirm.emit();
  }
  cancel()
  {
    this.isSuspending = false;
  }
}

<div id="fedrated">

    <ol class="breadcrumb breadcrumb-dis">
        <li><a routerLink="/home">{{"views.contract-detail.Home"| translate}}</a></li>
        <li class="active">{{"views.home.Federated Client"| translate}} / {{"views.home.Update Client" | translate}}</li>
        <li class="logout"><a routerLink="/oidc/logout">{{"views.home.Log out"| translate}}</a></li>
    </ol>

    <h1>{{"views.home.Update Client"| translate}}</h1>
    <div class="panel panel-default">
        <div class="panel-body"> 
            <form [formGroup]="federationForm" class="form-horizontal" role="form">
                <div class="form-group">
                    <label class="col-sm-3 control-label ">{{"views.federated.Client Name"| translate}}</label>
                    <div class="">
                        <span class="input-group">
                            <input minlength="3" type="text" class="form-control" id="searchclientname" formControlName="searchclientname" 
                                   (keydown)="fetchClient(federationForm.get('searchclientname').value)"(keyup.enter)="showButton = true" >
                            <span *ngIf="clientData.length==0" style="color:#00a3e0;text-align: center;font-weight: 700;">{{emptymessage}}</span>
                        </span>
                    </div>
                    
                </div>
                <div class="form-group" *ngIf="clientData.length>0">
                    <label class="col-sm-3 control-label ">Available Clients</label>
                    
                    <table  style="height: 84px;width:205px;border : 1px solid #ccc" *ngIf="clientData.length>0">
                        <tbody>
                            <tr  *ngFor="let o of clientData">
                                <td><span style="margin:0 0 0 7px;color:#00a3e0;font-size: large;cursor: pointer;" (click)="showClientDetails(o)">{{o.federatedclientname}}</span></td>
                                
                            </tr>
                            </tbody>
                            </table>
                </div>
                <div *ngIf="clientFormFlag">
                        <!-- Client Name -->
                        <div *ngFor = "let alert of alerts">
                            <p class="alert " [ngClass]="{'alert-danger' : alert.type=='danger','alert-info' : alert.type=='info'}">
                                {{alert.msg}}
                                <i  (click)="closeAlert(i)" aria-hidden="true" class="fa fa-times" style="
                                float:right;
                       font-size: 22px; "></i>
                            </p>
                        </div>
                        <hr>
                        <h3 style="line-height: 2;">Federated Client Details</h3>
                        <div class="form-group" show-errors>
                            <label for="federationForm.federatedclientname" class="col-sm-3 control-label ">{{"views.federated.Federated or B2B client name" | translate}}</label>
                            <div class="col-sm-7">
                                <span class="input-group">
                                    <span class="input-group-addon"><span class="glyphicon glyphicon-user"></span></span>
                                    <input  [attr.disabled]="true" type="text" formControlName="federatedclientname" class="form-control" id="federationForm.federatedclientname" name="federatedclientname" required ng-pattern="/^[^>+\:\;|\/*^$%@\(\)\{\}\[\]!\\~=#*]+$/"/>
                                </span>
                            </div>
                          
                        </div>
                        <!--clientdomains-->
                        <div class="form-group" show-errors>
                            <label for="federationForm.clientdomains" class="col-sm-3 control-label ">{{"views.federated.Client federated domains" | translate}}</label>
                            <div class="col-sm-7">
                                <span class="input-group">
                                    <span class="input-group-addon"><span class="glyphicon glyphicon-user"></span></span>
                                    <input type="text" (change)="formChangeVal('clientdomains')" formControlName="clientdomains" class="form-control" id="federationForm.clientdomains" name="clientdomains" required
                                           />
                                </span>
                            </div>
                            <div class="col-sm-1">
                                <p class="help-block" *ngIf="federationForm.get('clientdomains').errors?.required && federationForm.get('clientdomains').touched">{{"views.userrequest.Required" | translate}}</p>
                                <p class="help-block" *ngIf="!(federationForm.get('clientdomains').errors?.required) && federationForm.get('clientdomains').invalid && federationForm.get('clientdomains').touched">{{"views.service.Invalid Input" | translate}}</p>
                            </div>
                        </div>
                         <!-- Email -->
                         <div class="form-group" show-errors >
                            <label  for="federationForm.clienthelpdeskemail" class="col-sm-3 control-label ">{{"views.federated.Client HelpDesk email" | translate}}</label>
                            <div class="col-sm-7">
                                <span class="input-group">
                                    <span class="input-group-addon">@</span>
                                    <input type="text" class="form-control" 
                                           name="clienthelpdeskemail" (change)="formChangeVal('clienthelpdeskemail')" id="federationForm.clienthelpdeskemail" 
                                           formControlName="clienthelpdeskemail" 
                                           />
                                </span>
                            </div>
                            
                            <div class="col-sm-3">
                                <p class="help-block" *ngIf=" federationForm.controls.clienthelpdeskemail.status == 'INVALID' &&federationForm.controls.clienthelpdeskemail.touched">{{"views.userrequest.The email address is invalid" | translate}}</p>
        
                               
                            </div>
                            
                        </div>
        <!--Helpdesk mobile -->
         <!-- Mobile Phone -->
         <div class="form-group" show-errors >
            <label for="federationForm.clienthelpdeskphone" class="col-sm-3 control-label ">{{"views.federated.Client HelpDesk phone number" | translate}}</label>
            <div class="col-sm-7">
                <span class="input-group">
                 <span class="input-group-addon"><span class="glyphicon glyphicon-phone"></span></span>
                    <input class="form-control" name="clienthelpdeskphone"
                     type="text" name="clienthelpdeskphone"  
                     formControlName="clienthelpdeskphone" 
                     (change)="formChangeVal('clienthelpdeskphone')" 
                    style="position: inherit"/>
                </span>
            </div>
            <div class="col-sm-3">
                <p class="help-block" *ngIf="federationForm.controls.clienthelpdeskphone.status == 'INVALID' &&federationForm.controls.clienthelpdeskphone.touched">{{'views.userrequest.The correct format is: +11234567890' | translate}}</p>
        
               
            </div>
            <!-- 
            <div class="col-sm-2">
                <p class="help-block" *ngIf="helpdeskMobile.errors?.required">{{"views.userrequest.Required" | translate}}</p>
                <p class="help-block" *ngIf="helpdeskMobile.errors?.pattern">{{"views.userrequest.The correct format is: +11234567890" | translate}}</p>
            </div>
            -->
        </div>
                        <!-- Iam team contact Email -->
                        <div class="form-group" show-errors >
                            <label  for="federationForm.clientIAMemail" class="col-sm-3 control-label ">{{"views.federated.Client IAM team contact email" | translate}}</label>
                            <div class="col-sm-7">
                                <span class="input-group">
                                    <span class="input-group-addon">@</span>
                                    <input type="text" class="form-control" 
                                           name="clientIAMemail" id="federationForm.clientIAMemail" 
                                           (change)="formChangeVal('clientIAMemail')" 
                                           formControlName="clientIAMemail" 
        
                                         
                                           />
                                </span>
                            </div>
                            <div class="col-sm-3">
                                <p class="help-block" *ngIf="federationForm.controls.clientIAMemail.status == 'INVALID' &&federationForm.controls.clientIAMemail.touched">{{"views.userrequest.The email address is invalid" | translate}}</p>
                        
                               
                            </div>
                            <!--
                            <div class="col-sm-2">
                                <p class="help-block" *ngIf="emailIAM.errors?.required">{{"views.userrequest.Required" | translate}}</p>
                                <p class="help-block" *ngIf="emailIAM.errors?.pattern">{{"views.userrequest.The email address is invalid" | translate}}</p>
                               
                            </div>
                            -->
                        </div>
                        <!-- IAM Mobile Phone -->
         <div class="form-group" show-errors >
            <label for="federationForm.clientIAMphone" class="col-sm-3 control-label ">{{"views.federated.Client IAM team contact phone" | translate}}</label>
            <div class="col-sm-7">
                <span class="input-group">
                 <span class="input-group-addon"><span class="glyphicon glyphicon-phone"></span></span>
                    <input class="form-control" name="clientIAMphone" type="text" name="tel" 
                    formControlName="clientIAMphone" 
                    (change)="formChangeVal('clientIAMphone')" 
                    style="position: inherit"/>
                </span>
            </div>
            <div class="col-sm-3">
                <p class="help-block" *ngIf="federationForm.controls.clientIAMphone.status == 'INVALID' &&federationForm.controls.clientIAMphone.touched">{{'views.userrequest.The correct format is: +11234567890' | translate}}</p>
        
               
            </div>
            <!--
            <div class="col-sm-2">
                <p class="help-block" *ngIf="mobileIAM.errors?.required">{{"views.userrequest.Required" | translate}}</p>
                <p class="help-block" *ngIf="mobileIAM.errors?.pattern">{{"views.userrequest.The correct format is: +11234567890" | translate}}</p>
            </div>
            -->
        </div>
                        <!-- Save Button -->
                        <div class="col-sm-2"></div>
                        <div class="col-sm-7">
                            <div class="btn-group savebutton">
                                <button type="button"
                                        class="btn btn-default"
                                        (click)="update(federationForm)"
                                        [attr.disabled]="federationForm.controls?.status==='INVALID' ? '':null ">
                                    <span class="glyphicon glyphicon-send" style="margin-right: 10px"></span>Update</button>
                            </div>
                        </div>
                </div>
              
            </form>
        </div>
    </div>
    <div class="btn-group btn-group-justified">
        <div class="btn-group">
            <button type="button" class="btn btn-default" (click)="gotoHomePage()">
                <span class="glyphicon glyphicon-home" style="margin-right: 10px"></span>
                {{'views.user.Home' | translate}}
            </button>
        </div>
        <div class="btn-group">
            <button type="button" class="btn btn-default" (click)="gotoNewFedratedPage()">
                <span class="glyphicon glyphicon-plus" style="margin-right: 10px"></span>
                {{"views.home.New Client"| translate}}
            </button>
        </div>
    </div>
    <div style="display: flex;margin-top: 50%;">
        <app-terms-conditions></app-terms-conditions>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/common.service';
import { PermissionService } from 'src/app/permission.service';
import { ContractService } from '../contract.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
@Component({
  selector: 'app-contract-new',
  templateUrl: './contract-new.component.html',
  styleUrls: ['./contract-new.component.css']
})
export class ContractNewComponent implements OnInit {
  contractrequest: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.pattern(/^[\w\d\s-']+$/)]),
    startDate: new FormControl('', [Validators.required]),
    endDate: new FormControl('', [Validators.required]),
    wbs: new FormControl(''),
    description: new FormControl(''),
    contactEmail: new FormControl('', [Validators.email]),
    roleNotifications: new FormControl('')
  });
  alerts = [];
  uniqueValue = false;
  constructor(private contractService: ContractService, private translateService: TranslateService, private commonService: CommonService, private permission: PermissionService, private router: Router) { }

  ngOnInit(): void {
  }
  checkName(name) {
     ;
    const contractname = this.contractrequest.get('name').value;
    this.contractService.checkExistingContractName(contractname).subscribe(
      data => {
         ;
        this.uniqueValue = data.body;
      }
    );
  }
  closeAlert(index) {
    this.alerts.splice(index, 1);
  }
  submit() {
    this.alerts = [];
     ;
    const CurrentDate = new Date().setHours(0, 0, 0, 0);
    const startDate = new Date(this.contractrequest.get('startDate').value).setHours(0, 0, 0, 0);
    const endDate = new Date(this.contractrequest.get('endDate').value).setHours(0, 0, 0, 0);
    if (startDate < CurrentDate) {
      this.translateService.get('views.js.alerts.contracts.Start date must be greater than or equal to today date.').subscribe(res => {
        this.alerts.push({ type: 'danger', msg: res });
      });
      return;
    }
    if (startDate > endDate) {
      this.translateService.get('views.js.alerts.contracts.Start date must be smaller than end date.').subscribe(res => {
        this.alerts.push({ type: 'danger', msg: res });
      });
      return;
    }
    else {
       ;
      this.contractService.saveContract(this.contractrequest.value).subscribe(data => {
         ;
        this.router.navigate(['/contract/' + data.id ])
        .then(nav => {
        }, err => {
           // when there's an error
        });
      });
    }
  }

  authorized() {
    return this.permission.isAdmin || this.permission.isUserAdmin;
  }

}

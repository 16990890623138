import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { interval, merge, Observable, of as observableOf, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, takeUntil, takeWhile, timeout } from 'rxjs/operators';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { CommonService } from '../../../common.service';
import { OrganizationService } from '../../organization-service.service';
import { Router,ActivatedRoute, ParamMap} from '@angular/router';
import { PermissionService } from '../../../permission.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { RolesService } from 'src/app/roles-service.service';
@Component({
  selector: 'app-organization-details',
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.css']
})
export class OrganizationDetailsComponent  implements OnInit, AfterViewInit{

  private _baseHref: string;
  private _token;
  organizationDetailRequest: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.pattern(/^[^>+\:\;|\/*^$%@\(\)\{\}\[\]!\\~=#*]+$/), ]),
    technicalId: new FormControl(''),
    description: new FormControl(''),
    emailLinkValidityDurationHours: new FormControl('', [Validators.pattern('^[0-9]*$'),Validators.minLength(1)]),
    contactEmail: new FormControl('', [Validators.pattern(/^[a-zA-Z0-9._%-\'. \-_!#^~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/), ]),
    emailOtp: new FormControl(null),
    tenantName: new FormControl(null,Validators.required),
    id: new FormControl(null),
    status: new FormControl(null),
    featureflag1: new FormControl(null),
    featureflag2: new FormControl(null),
    featureflag3: new FormControl(null),
    featureflag4: new FormControl(null),
    featureflag5: new FormControl(null),
    featureflag6: new FormControl(null),
    sendActivationEmailToRequester: new FormControl()
  });
  alerts = [];
  selectedOrganizations: any;
  alert = false;
  filteredOptions: Observable<[]>;
  id;
  organization: any;
  roledisplayedColumns: string[] = ['status', 'user', 'action'];
  displayedColumns: string[] = ['status', 'lastname', 'firstname', 'email','action'];
    serviceDisplayedColumns: string[] =['status','name','action'];
  serviceformControl = new FormControl();
  //contractcount = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  roleassignmentcount = 0;
 // contractfilteredtabledata: MatTableDataSource<any>;
  rolefilteredtabledata:  MatTableDataSource<any>;
  userfilteredtabledata: MatTableDataSource<any>;
  servicedataSource: MatTableDataSource<any>
  filteredAndPagedIssues: Observable<[]>;

  filter = {};
  userscount = 0;
  servicecount= 0;
  @ViewChild('paginatorcontract') paginatorContract: MatPaginator;
  @ViewChild('paginatorroles') paginatorroles: MatPaginator;
  @ViewChild('paginatorusers') paginator: MatPaginator;
  @ViewChild('paginatorservices') paginatorservice: MatPaginator;
  // @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  resultsLength: any =0;
  originalData: any;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild ('sortorgrolesassign', { read: MatSort, static: true}) sortorgrolesassign: MatSort;
  @ViewChild ('sortorgusers', { read: MatSort, static: true}) sortorgusers: MatSort;  //originalData: any;

  @ViewChild ('sortservices', { read: MatSort, static: true}) sortservice: MatSort;  //originalData: any;
  roleOrginalData: any;
  userOrginalData: any;
  serviceOrginalData:any;
  userformControl = new FormControl();
  userfilteredOptions: Observable<[]>;
  usertoadd: {"organizationId": {"id": any}, "userId": string};
  availableservices: any;
  userData: any;
  routes: any;
  initialFormValues: any;
  updatedFormFields={};
  servicesarr =[];
  serviceData: any;
  searchLastNameControl=new FormControl();
  private stopPolling = new Subject<void>();
  searchFirstNameControl=new FormControl();
  searchEmailControl=new FormControl();
  constructor(private roleService:RolesService,private organizationService: OrganizationService,private commonService: CommonService,private translateService: TranslateService, private router: Router, private route: ActivatedRoute, private permission: PermissionService) {
    this.searchFirstNameControl.valueChanges
    .pipe(
      debounceTime(300), // Delay of 300 ms
      distinctUntilChanged() // Only emit when the current value is different from the last
    )
    .subscribe(newValue => {
      
      this.filter['givenName'] = newValue!=""?newValue.toLowerCase():"";
      this.apiTrigger(); // Trigger your method here
    });
    this.searchLastNameControl.valueChanges
    .pipe(
      debounceTime(300), // Delay of 300 ms
      distinctUntilChanged() // Only emit when the current value is different from the last
    )
    .subscribe(newValue => {
      this.filter['sn'] = newValue!=""?newValue.toLowerCase():"";
      this.apiTrigger(); // Trigger your method here
    });
    this.searchEmailControl.valueChanges
    .pipe(
      debounceTime(300), // Delay of 300 ms
      distinctUntilChanged() // Only emit when the current value is different from the last
    )
    .subscribe(newValue => {
      this.filter['mail'] =  newValue!=""?newValue.toLowerCase():"";
      this.apiTrigger(); // Trigger your method here
    });
  }
  ngAfterViewInit() {
    this.routes = this.route.params.subscribe(params => {
      this.id = params['id'];
      })
    this.getorganizationuserroles();
    // this.getorganizationusers();
    this.apiTrigger();
  }
  closeAlert(index) {
    this.alerts.splice(index, 1);
  }
  getorganizationuserroles()
  {
    this.organizationService.getorganizationuserroles(this.id).subscribe((data: any) => {
      this.rolefilteredtabledata = new MatTableDataSource(data.message);
      if(data!=null)
      this.roleassignmentcount = data.message.length;
      this.roleOrginalData = data.message;
      this.rolefilteredtabledata.paginator = this.paginatorroles;
      this.rolefilteredtabledata.sort = this.sortorgrolesassign;
      const sortState: Sort = { active: 'mail', direction: 'desc' };
      // this.sortorgrolesassign.active = sortState.active;
      // this.sortorgrolesassign.direction = sortState.direction;
     // this.sortorgrolesassign.sortChange.emit(sortState);
     });
  }
  getPageDetails(event) {
    this.apiTrigger();
  }
  servicefilterData(key, value) {
    this.servicedataSource = this.serviceOrginalData.filter((item: any) => {
      let rowData;
      if (key) {
        if(item[key]!=undefined)
        rowData = item[key].toLowerCase();
      }
      return rowData.includes(value.toLowerCase());
    });
  }
  checkStatus(data,orgId)
  {
    return data.map(user => {
      const status = this.getStatusForOrg(user.userRelationship, orgId);
      if(status!=null)
      {
      return {
        ...user,
        accountStatus: status
      };
    }
    });
  }
  apiTrigger()
  {
    this.filteredAndPagedIssues = merge(this.paginator.page)
    .pipe(
      startWith({}),
      switchMap(() => {
        this.isLoadingResults = true;
        let request;
        if(Object.keys(this.filter).length>0)
          request = { 'page': this.paginator.pageIndex + 1, 'order': this.sort.direction, 'orderBy': this.sort.active, 'filters': this.filter, 'size': this.paginator.pageSize};
         else
          request = { 'page': this.paginator.pageIndex + 1, 'order': this.sort.direction, 'orderBy': this.sort.active, 'size': this.paginator.pageSize};
         return this.organizationService.getorganizationusers(this.id,request);
      }),
      map(data => {
        // Flip flag to show that loading has finished.

        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        const users = this.checkStatus(data[`users`],this.commonService.selectedOrg[`id`]);
        this.resultsLength = data[`attr`].total;
        this.originalData = users;
        return users;
      }),
      catchError(() => {
        this.isLoadingResults = false;
        // Catch if the GitHub API has reached its rate limit. Return empty data.
        this.isRateLimitReached = true;
        return observableOf([]);
      })
    );
  }
  getStatusForOrg(userRelationship: any, orgId: string): string {
    // Check if the orgId exists at the top level
    if (userRelationship[orgId] && userRelationship[orgId].status) {
      return userRelationship[orgId].status;
    }

    // Traverse nested objects
    for (const key in userRelationship) {
      if (userRelationship.hasOwnProperty(key)) {
        const value = userRelationship[key];
        if (typeof value === 'object' && value!=null && value.status!=null) {
          return value.status;
        }
      }
    }

    // If orgId is not found
    return null;
  }
  resetPaging(): void {
    this.apiTrigger();
  }
  getorganizationServices(data)
  {
      this.servicedataSource = new MatTableDataSource(data);
      this.servicecount = data.length;
      this.serviceOrginalData = data;
      this.getavailableServices();
      this.servicedataSource.paginator = this.paginatorservice;
      this.servicedataSource.sort = this.sortservice;
      const sortState: Sort = { active: 'name', direction: 'desc' };
      //his.sortservice.active = sortState.active;
     // this.sortservice.direction = sortState.direction;
      //this.sortservice.sortChange.emit(sortState);    
  }
  sortroleData(sort: Sort) {
    const data = this.roleOrginalData.slice();
    if (!sort.active || sort.direction === '') {
      this.userfilteredtabledata = data;
      return;
    }
    this.rolefilteredtabledata = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
       
        case 'mail':
          return this.compare(a.mail, b.mail, isAsc);
        default:
          return 0;
      }
    });
  }
  sortData(sort: Sort) {
    const data = this.userOrginalData.slice();
    if (!sort.active || sort.direction === '') {
      this.userfilteredtabledata = data;
      return;
    }

    this.userfilteredtabledata = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'firstname':
          return this.compare(a.givenName, b.givenName, isAsc);
        case 'lastname':
          return this.compare(a.familyName, b.familyName, isAsc);
        default:
          return 0;
      }
    });
  }

 compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
navigateServiceDetail(row)
   {
    this.router.navigate(['/service/' + row.id])
    .then(nav => {
       // true if navigation is successful
    }, err => {
    });
   }
   updateInputService(event)
   {
     if (event.option.value !== undefined) {
       this.serviceData = event.option.value;
     }
   }
   addServices()
  {
      const request ={
        "orgId":"",
        "serviceId":"",
        "startDate":"",
        "endDate":""
      }
    request.serviceId = this.serviceData.id;
    request.orgId=this.id;
    request.startDate =  new Date().toLocaleDateString();
    this.organizationService.orgService(request).subscribe(data =>
      {
          this.ServiceListAPIcall(this.organization,this.serviceData.id);
          this.serviceformControl.setValue('');
      });
  }
   getavailableServices()
  {
    this.organizationService.getavailableServices().subscribe(
      data => {
        const serviceID = this.organization.serviceCollection;
        if(serviceID!=null&&serviceID.length>=0)
        this.availableservices = data.filter(item => !serviceID.includes(item.id));
        else
        this.availableservices = data;
        this.filteredOptions = this.serviceformControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filter(name) : this.availableservices.slice())
        );
      });
  }
  displayFn(item: any): string {
    return item && item.name ? item.name : '';
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.availableservices.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  ServiceListAPIcall(data,serviceID)
  {
    let serviceIDs=data?.serviceCollection;
    if(serviceID!=null)
      {
        serviceIDs.push(serviceID)
      }
    this.organizationService.getserviceDetails(data?.serviceCollection).subscribe(data =>{
      this.getorganizationServices(data);
    })
  }
  ngOnInit(): void {
    this.routes = this.route.params.subscribe(params => {
      this.id = params['id'];
      })
    this.organizationService.getOrganizationDetails(this.id).subscribe(data =>
      {
        this.organization = data;
        if(data?.serviceCollection.length >0)
          {
            this.ServiceListAPIcall(data,null);
          }
          else
          {
            this.getavailableServices();
          }
        this.organizationDetailRequest.get('id').setValue(this.organization.id);
        this.organizationDetailRequest.get('name').setValue(this.organization.name);
        this.organizationDetailRequest.get('technicalId').setValue(this.organization.technicalId);
        this.organizationDetailRequest.get('description').setValue(this.organization.description);
        this.organizationDetailRequest.get('emailLinkValidityDurationHours').setValue(this.organization.emailLinkValidityDurationHours);
        this.organizationDetailRequest.get('sendActivationEmailToRequester').setValue(this.organization.sendActivationEmailToRequester);
        this.organizationDetailRequest.get('emailOtp').setValue(this.organization.emailOtp);
        this.organizationDetailRequest.get('featureflag1').setValue(this.organization.featureflag1);
        this.organizationDetailRequest.get('featureflag2').setValue(this.organization.featureflag2);
        this.organizationDetailRequest.get('featureflag3').setValue(this.organization.featureflag3);
        this.organizationDetailRequest.get('featureflag4').setValue(this.organization.featureflag4);
        this.organizationDetailRequest.get('featureflag5').setValue(this.organization.featureflag5);
        this.organizationDetailRequest.get('featureflag6').setValue(this.organization.featureflag6);
        this.organizationDetailRequest.get('contactEmail').setValue(this.organization.contactEmail);
        this.organizationDetailRequest.get('tenantName').setValue(this.organization.tenantName);
        this.organizationDetailRequest.get('status').setValue(this.organization.status);
        this.initialFormValues = this.organizationDetailRequest.value;

        
      });
      this.onChanges();
  }
  
  onChanges(): void {
    this.organizationDetailRequest.valueChanges
    .pipe(debounceTime(200))
    .subscribe(values => {
      // Compare current values to initial values
      for (const field in values) {
        if (values[field] !== this.initialFormValues[field]) {
          this.updatedFormFields[field] = values[field]; // Update stored initial value on change
        }
      }
});  }
  gotoOrganizationListPage()
  {
    this.router.navigate(['organization/list']);
  }
  authorized() {
    return this.permission.isAdmin ;
  }
  authorizedActivationParam()
  {
    return this.permission.isAdmin;
  }
  authorizedEmailOtpParam()
  {
    return this.permission.isAdmin && this.organization.tenantName.toLowerCase() != 'swiss';
  }
  /**Contract code */
  // authorizedContracts()
  // {
  //   return this.permission.isAdmin || this.permission.isContractAdmin;
  // }
  authorizedRoleAssignments()
  {
    return this.permission.isAdmin || this.permission.isRoleAssignmentAdmin;

  }
  
  submit()
  {
    const changes = [];

    //const currentValues = this.organizationDetailRequest.value;
    // Iterate over fields and check if there are changes
    for (const field in this.updatedFormFields) {
      
      if (this.updatedFormFields[field]!=null&&this.updatedFormFields[field] !== this.initialFormValues[field]) {
       if(field==='sendActivationEmailToRequester')
       {
        if(field==='sendActivationEmailToRequester' && this.updatedFormFields[field]==null)
        {
          this.updatedFormFields[field]=false;
        }
        changes.push({
          operation: "replace",
          field: "sendActivationEmail",
          value: this.updatedFormFields[field]
        });
      }
      else
      {
        changes.push({
          operation: "replace",
          field: field,
          value: this.updatedFormFields[field]
        });
      }
      }
    }

    if (changes.length > 0) {
      this.organizationService.updateOrganization(this.id,changes).subscribe(data => {
        this.router.navigate(['organization/list']);
      },err =>{
       
          
            this.alerts.push({ type: 'danger', msg:"Unable to update org" });
          
      }
    );
    } else {
    }
  }
  onSubmit(organizationDetailRequest)
  {

  }
  
  // true if navigation is successful
  
  deleteadminRole(row)
  {
    const request= {
      "orgId" : this.id,
      "email" : row.mail
  }
  this.roleService.assignorgAdminRole('revoke',request).subscribe((response: any) => {
    if(response.code!=200)
    {
      const msg = response.message
      this.alerts.push({ type: 'danger', msg });
    }
    else
    {
      this.getorganizationuserroles();
    }

  },
  err=>{
    if(err.status==0)
    {
      this.getorganizationuserroles();

    }
    else
    {
      const msg = err.error.message
      this.alerts.push({ type: 'danger', msg });
    }
  }
);
  }
  suspendOrganizationUser(row)
  {
    
    let request ={
      "email":"",
    "orgId":"",
    "status":""
      }
      request.email=row.mail;
      request.orgId =this.commonService.selectedOrg[`id`];
      request.status = "suspended";
    this.organizationService.updatestatusUserbyOrg(request).subscribe(data =>{   
      this.apiTrigger();
    });
  
  }
  deleteOrganizationUser(row)
  {
    this.stopPolling = new Subject<void>();
   
    let request ={
      "orgID":"",
      "userID":""
      }
      this.commonService.usersbymail(row.mail).subscribe(data =>{
        if(data!=null)
        {
          request.userID=data._id;
      request.orgID =this.commonService.selectedOrg[`id`];
      this.isLoadingResults = true;
  
      this.organizationService.deletestatusUserbyOrg(request).subscribe(data =>{   
        const startTime = Date.now();
        interval(5000).pipe(
          switchMap(() => this.commonService.checkEmailfromorg(row.mail, request.orgID)),
          takeUntil(this.stopPolling),
  
          filter(searchResponse => searchResponse !== null), // Ensure we only process valid responses
          catchError(error => {
            console.error('Error during search user polling:', error);
            return of(null);
          }),
          timeout(60000)
        ).subscribe(searchResponse => {
          const elapsedTime = Date.now() - startTime;
          if (searchResponse && searchResponse==="false") {
            this.apiTrigger();
            this.stopPolling.next();
            this.stopPolling.complete();
          } else if (elapsedTime >= 60000){
            this.isLoadingResults = false;
            this.stopPolling.next();
            this.stopPolling.complete();
            console.error('User deletion process exceeded the threshold limit.');
          }
        });
    },
    err=>{
      this.isLoadingResults = false;
  
    }
  );
        }
      });
      
  }
  deleteService(row)
  {

    this.organizationService.deleteServiceFromOrg(row[`id`],this.id).subscribe(data =>
    {
      let filteredArray = this.organization.serviceCollection.filter(item => item !== row[`id`]);
      this.organization.serviceCollection =filteredArray;
      this.ServiceListAPIcall(this.organization,null);
    },
    err =>
    {
    }
    );
  }
  activateOrganizationUser(row)
  {
    
    let request ={
      "email":"",
    "orgId":"",
    "status":""
      }
      request.email=row.mail;
      request.orgId =this.commonService.selectedOrg['id'];
      request.status = "active";
    this.organizationService.updatestatusUserbyOrg(request).subscribe(data =>{   
      this.apiTrigger();
    });
  }
  applyroleFilter(key, value: string) {
    this.rolefilteredtabledata.data = this.roleOrginalData.filter((item: any) => {
    let rowData;
    if (key) {
      rowData = item[key].toLowerCase();
    }
    return rowData.includes(value.toLowerCase());
    });
  }
  // applyFilter(key, value: string) {
  //   this.contractfilteredtabledata.data = this.originalData.filter((item: any) => {
  //   let rowData;
  //   if (key) {
  //     rowData = item[key].toLowerCase();
  //   }
  //   return rowData.includes(value.toLowerCase());
  //   });
  // }
  applyuserFilter(key, value: string){
    this.userfilteredtabledata.data = this.userOrginalData.filter((item: any) => {
      let rowData;
      if (key) {
        rowData = item[key].toLowerCase();
      }
      return rowData.includes(value.toLowerCase());
      });
  }
  numericOnly(event): boolean {
    // Allow key codes for special events. Backspace = 8, Delete = 46, Arrow keys = 37-40, and others as needed
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'];
    if (allowedKeys.includes(event.key)) return; // Allow navigation and correction keys
    const charCode = event.key.charCodeAt(0);
    if (charCode < 48 || charCode > 57) { // Check if the key is not a digit
      event.preventDefault(); // Prevent the key input
    } else {
      // Check if the resulting value will be greater than 1
      const nextValue = this.organizationDetailRequest.value.emailLinkValidityDurationHours + event.key;
      if (parseInt(nextValue, 10) < 1) {
        event.preventDefault(); // Prevent the input if the value is not greater than 1
      }
    }
  }
  navigateUserDetail(row)
  {
   
        this.router.navigate(['/user/' + row._id])
    .then(nav => {
       // true if navigation is successful
    }, err => {
       // when there's an error
    });
      
    
  }
  updateInputorg(event)
  {
    
    if (event.option.value !== undefined) {
      this.userData = event.option.value;
      this.userformControl.setValue( this.userData.lastname +' '+ this.userData.firstname +' ('+ this.userData.email +')');
    }
  }
}

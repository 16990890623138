<div class="main-container form-container-reset-password">
    <div class="form-container" [hidden]="mailalert !== null && mailalert !== '' && mailalert.length > 0">
        <div class="form">
            <div class="header">
                <div class="logo">
                </div>
            </div>
            <div class="form-title title">
                {{"views.public.pwdmail.Reset password"| translate}}
            </div>
            <div class="form-second-description main-text">{{"views.public.pwdmail.Enter your email to receive instructions on how to reset your password."| translate}}</div>
            <form [formGroup]="emailForm" name="emailForm" class="form-horizontal" role="form">
                <div class="form-controls">
                    <input class="input input-password"
                           name="email"
                           type="email"
                           placeholder="{{'views.public.pwdmail.Email' | translate}}"
                           formControlName="email"     
                           required>
                </div>
              
                <div class="col-sm-1" *ngIf="emailForm.controls.email.errors!=null ">
                        
                    <p class="error-block" *ngIf="emailForm.controls.email.errors.required== true &&emailForm.controls.email.touched">
                        {{'views.userrequest.Required' | translate}} </p>
                       
                    <p class="error-block" *ngIf="emailForm.controls.email.errors.required==null && emailForm.controls.email.status == 'INVALID' &&emailForm.controls.email.touched">{{"views.userrequest.The email address is invalid" | translate}}</p>
                  
                  </div>

            </form>


            <button class="btn-form btn-black login-btn"
            style="width: 100% ;" 
                    [attr.disabled] ="emailForm.controls.email.status == 'INVALID' ? '' : null"
                    (click)="sendLink()">
                {{"views.public.pwdmail.Submit"| translate}}
            </button>
        </div>
    </div>

    <div *ngIf="mailalert !== null && mailalert !== '' && mailalert.length > 0">
        <div class="form-container self-service-form-container">
            <div class="form">
                <div class="logo-successful">
                    <img src="../../dis/assets/img/self-service/activation-successful.svg">                </div>
                <div class="form-title title title-successful">
                    {{successtitle}}
                </div>
                <div class="form-second-description main-text main-text-activation-successful">{{mailalert}}</div>

            </div>
        </div>
    </div>
    <app-terms-conditions></app-terms-conditions>
</div>
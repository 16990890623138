import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from '../common.service';
import { OrganizationService } from '../Organization_Management/organization-service.service';

@Component({
  selector: 'app-organization-list-dialog-component',
  templateUrl: './organization-list-dialog-component.component.html',
  styleUrls: ['./organization-list-dialog-component.component.css']
})
export class OrganizationListDialogComponentComponent implements OnInit {

  userDetails;

  constructor(
    public dialogRef: MatDialogRef<OrganizationListDialogComponentComponent>,private organizationService:OrganizationService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      
    }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {
  }

  deleteOrganization(userID,organization: any): void {
    // Assuming 'organizations' is an array available in the data passed to the dialog
    const request = {
      "orgID":organization._refResourceId,
      "userID":userID
    }
    this.organizationService.deletestatusUserbyOrg( request).subscribe(response =>
      {
        this.dialogRef.close({deleted: true, organizations: response});

      },
      err =>
      {
      }
      );
  
    // Optionally, call a service to update the backend
    // this.organizationService.deleteOrganization(organization.id).subscribe();
  }
}

<div id="userDetail" *ngIf="authorized()">
    
    <ol class="breadcrumb breadcrumb-dis">
       
        <li><a [routerLink]="['']">{{'views.user.Home'| translate}}</a></li>
        <li><a (click)="gotoUserListPage()">{{'views.user.User list' | translate}}</a></li>
        <li><a routerLink="/user/{{user?._id}}">{{user?.givenName}} {{user?.sn}}</a></li>
        <li class="active">{{'views.user.History of' | translate}} {{user?.givenName}} {{user?.sn}}</li>
        <li class="logout"><a href="./oidc/logout">{{'views.user.Log out' | translate}}</a></li>
    </ol>
    <h1>{{'views.user.History of' | translate}} {{user?.givenName}} {{user?.sn}}</h1>
    <div class="panel panel-default">
        <div class="panel-heading">
            <span class="">{{'views.user.User details' | translate}}</span>
        </div>
        
        <div class="panel-body">
            <p class="alert alert-info" *ngIf="infoalert">{{infomsg}}</p>
            <p class="alert alert-danger" *ngIf="dangeralert">{{dangermsg}}</p>

            <form [formGroup]="userrequestForm" (ngSubmit)="onSubmit(userrequestForm)" class="form-horizontal" role="form">
                <div class="row ">
                    <div class="col-sm-10">
                        <div class="form-group" show-errors>
                            <label for="user.status" class="col-sm-2 control-label ">{{'views.user.Status' | translate}}</label>
                            <div class="col-sm-5">
                                <img style="width: 25px" *ngIf="user?.accountStatus == 'suspended'" src="assets/images/icons/bullet_red.png" alt="{{'views.user.suspended' | translate}}" title="{{'views.user.suspended' | translate}}"/>
                                <img style="width: 25px" *ngIf="user?.accountStatus == 'active'" src="assets/images/icons/bullet_green.png" alt="{{'views.user.active' | translate}}" title="{{'views.user.active' | translate}}"/>
                            </div>
                        </div>
                       
                           
                        <!-- First Name -->
                        <div class="form-group" show-errors>
                            <label for="user.firstname" class="col-sm-2 control-label ">{{'views.user.First name' | translate}}</label>
                            <div class="col-sm-7">
                                <span class="input-group">
                                    <span class="input-group-addon"><span class="glyphicon glyphicon-user"></span></span>
                                    <input type="text"  class="form-control" id="user.firstname" [(ngModel)]="user.givenName" name="firstname" formControlName="givenName" 
                                    [attr.disabled]="true" />
                                    
                                </span>
                            </div>
                         
                        </div>
                             <!-- Last Name -->
                             <div class="form-group" show-errors>
                                <label for="user.lastname" class="col-sm-2 control-label ">{{'views.user.Last name' | translate}}</label>
                                <div class="col-sm-7">
                                    <span class="input-group">
                                        <span class="input-group-addon"><span class="glyphicon glyphicon-user"></span></span>
                                        <input type="text" class="form-control" id="user.lastname" name="lastname" 
                                        formControlName="sn" 
                                        [(ngModel)]="user.sn"
                                        [attr.disabled]="true"/>
                                    </span>
                                </div>
                                
                            </div>
                             <!-- Email -->
                        <div class="form-group" show-errors>
                            <label  for="user.email" class="col-sm-2 control-label ">{{'views.user.Email' | translate}}</label>
                            <div class="col-sm-7">
                                <span class="input-group">
                                    <span class="input-group-addon">@</span>
                                    <input type="text" class="form-control"
                                           name="email" id="user.email"
                                           formControlName="mail" 
                                           [(ngModel)]="user.mail"
                                           
                                           formControlName="mail"
                                           [attr.disabled]="true"/>
                                </span>
                            </div>
                            <!-- <div class="col-sm-1" *ngIf="userrequestForm.controls.email.errors!=null ">
                        
                                <p class="error-block" *ngIf="userrequestForm.controls.email.errors.required== true &&userrequestForm.controls.email.touched">
                                    {{'views.user.Required' | translate}} </p>
                                   
                                <p class="error-block" *ngIf="userrequestForm.controls.email.errors.required==null && userrequestForm.controls.email.status == 'INVALID' &&userrequestForm.controls.email.touched">{{'views.user.The email address is invalid' | translate}}</p>
                              
                              </div>
                              <div class="col-sm-3">
                                <p class="error-block"
                               
                                *ngIf="uniqueValue">
                                {{'views.user.This email address already exists.' | translate}}
                               
                            
                            </p>
                            </div>                -->
                        </div>
                         <!-- Main Language -->
                         <div class="form-group" show-errors>
                            <label for="user.mainLanguage" class="col-sm-2 control-label ">{{'views.user.Main language' | translate}}</label>
                            <div class="col-sm-7">
                                <span class="input-group">
                                    <span class="input-group-addon"><span class="glyphicon glyphicon-globe"></span></span>
                                   
                                    <select formControlName="mainLanguage"
                                            required
                                            placeholder="Set main language"
                                            disabled
                                            class="ng-touched caim-select-field ng-valid ng-not-empty ng-dirty ng-valid-parse input form-control">
                                        <option value="" [attr.disabled]="true" selected hidden >&#xf1ab; Language</option>     
                                        <option label="En - English (Global)" value="EN" >En - English (Global)</option>
                                        <option label="Nl - Dutch" value="NL" >Nl - Dutch</option>
                                        <option label="Fr - French (France)" value="FR" >Fr - French (France)</option>
                                        <option label="Es - Spanish (Spain)" value="ES" >Es - Spanish (Spain)</option>
                                        <option label="Es-419 - Spanish (Latin-America)" value="ES-419" >Es -  Spanish (Latin-America)</option>
                                        <option label="Pt - Portuguese" value="PT" >Pt - Portuguese </option>
                                        <option label="Zh - Chinese (Simplified)" value="ZH" >Zh - Chinese (Simplified)</option> 
                                        <option label="De - German" value="DE" >De - German</option> 
                                        <option label="Da - Danish" value="DA" >Da - Danish</option> 
                                        <option label="Sv - Swedish" value="SV" >Sv - Swedish</option> 
                                        <option label="Fi - Finnish" value="FI" >Fi - Finnish</option> 
                                        <option label="No - Norwegian" value="NO" >No - Norwegian</option> 
                                        <option label="Pl - Polish" value="PL" >Pl - Polish</option> 
                                        <option label="Ja - Japanese" value="JA" >Ja - Japanese</option>
                                        <option label="Cs - Czech" value="CS" >Cs - Czech</option> 
                                        <option label="Ru - Russian" value="RU" >Ru - Russian</option> 
                                        <option label="It - Italian" value="IT" >It - Italian</option> 
                                        <option label="Fr-Ca - French (Canada)" value="FR-CA" >Fr-Ca - French (Canada)</option> 
                                        <option label="Hu - Hungarian" value="HU" >Hu - Hungarian</option>       
                                    </select>
                                </span>
                            </div>
                        </div>

                      
                       
                       
                      <!-- Mobile number -->
                      <div class="form-group" show-errors>
                        <label for="user.mobile" class="col-sm-2 control-label ">{{'views.user.Mobile' | translate}}</label>                       
                            <div class="col-sm-7">
                                <span class="input-group">
                                    <span class="input-group-addon"><span class="glyphicon glyphicon-phone"></span></span>
                                    <input placeholder="{{'views.userrequest.Format: +11234567890' | translate}}" [(ngModel)]="user.telephoneNumber" [attr.disabled]="true" class="form-control" name="mobile" type="text" name="tel" formControlName="telephoneNumber"
                                    style="position: inherit"/>
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-7">
                            <div class="btn-group savebutton">
                                <button type="button"
                                        class="btn btn-default"
                                        
                                       >
                                    <span class="glyphicon glyphicon-floppy-disk" style="margin-right: 10px"></span>{{'views.user.Save' | translate}}
                                </button>
                            </div>
                        </div> 
                </div>
                </div>
            </form>
            
            </div>
            </div>
            <accordion>
                    <accordion-group is-open="false" is-disabled="false">
    
                            <div accordion-heading  style="width: 100%;">  
                                <div style="width: 50px; float: left">                    
                                    <img src="assets/images/homepage/history.png" alt="{{'views.user.User history' | translate}}" width="20px"/>
                                </div>{{'views.user.User history' | translate}}

                            </div>
                       
                            <span class="" *ngIf="historycount < 1">
                                {{'views.user.The user has no history.' | translate}} <br />
                            </span>
                            <div *ngIf="historycount >= 1" class="scrollable-table">
                              <table mat-table [dataSource]="userHistorydataSource" matSort>
                                      <ng-container matColumnDef="email">
                                        <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> {{'views.user.Email'|translate}}</th>
                                        <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.email}} </td>
                                      </ng-container>
                                      <ng-container matColumnDef="createdOn">
                                        <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header>{{'views.user.created_on' | translate}}</th>
                                        <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.createdOn | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
                                      </ng-container>
                                      <ng-container matColumnDef="modifiedOn">
                                        <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> {{'views.user.modified_on' | translate}}</th>
                                        <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.modifiedOn | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
                                      </ng-container>
                                      <ng-container matColumnDef="modifiedBy">
                                        <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> {{'views.user.modified_by' | translate}}</th>
                                        <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.modifiedBy}} </td>
                                      </ng-container>
                                      <ng-container matColumnDef="changedField">
                                        <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> Changed Field</th>
                                        <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.changedField}} </td>
                                      </ng-container>
                                      <ng-container matColumnDef="initialValue">
                                        <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> Initial Value</th>
                                        <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.initialValue}} </td>
                                      </ng-container>
                                      <ng-container matColumnDef="changedValue">
                                        <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> Changed Value</th>
                                        <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.changedValue}} </td>
                                      </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="historydisplayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: historydisplayedColumns;"></tr>  
                                  </table>
                                
                <mat-paginator  #paginatoruser="matPaginator"  [pageSizeOptions]="[5, 10, 25, 100]" [length]="historycount" [pageSize]="10"></mat-paginator>
               
                       
                            </div>
                        </accordion-group>
                        <accordion-group is-open="false" is-disabled="false">
    
                            <div accordion-heading  style="width: 100%;">  
                                <div style="width: 50px; float: left">                    
                                    <img src="assets/images/homepage/history.png" alt="{{'views.user.User history' | translate}}" width="20px"/>
                                </div>                   
                                 {{'views.user.Organization history' | translate}}


                            </div>
                       
                            <span class="" *ngIf="organizationhistorycount < 1">
                                {{'views.user.The user has no organization history.' | translate}} <br />
                            </span>
                            <div *ngIf="organizationhistorycount >= 1" class="scrollable-table">
                              <table mat-table [dataSource]="orgHistorydataSource" matSort>

                                     
                                <ng-container matColumnDef="organization">
                                  <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> {{'views.user.Organization'|translate}}</th>
                                  <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.organization}} </td>
                                </ng-container>
                                <ng-container matColumnDef="createdOn">
                                  <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header>{{'views.user.created_on' | translate}}</th>
                                  <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.createdOn | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
                                </ng-container>
                                <ng-container matColumnDef="modifiedOn">
                                  <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> {{'views.user.modified_on' | translate}}</th>
                                  <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.modifiedOn | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
                                </ng-container>
                                <ng-container matColumnDef="modifiedBy">
                                  <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> {{'views.user.modified_by' | translate}}</th>
                                  <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.modifiedBy}} </td>
                                </ng-container>
                                <ng-container matColumnDef="changedField">
                                  <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> Changed Field</th>
                                  <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.changedField}} </td>
                                </ng-container>
                                <ng-container matColumnDef="initialValue">
                                  <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> Initial Value</th>
                                  <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.initialValue}} </td>
                                </ng-container>
                                <ng-container matColumnDef="changedValue">
                                  <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> Changed Value</th>
                                  <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.changedValue}} </td>
                                </ng-container>
                              
                           
                      
              
                          
                              <tr mat-header-row *matHeaderRowDef="orgdisplayedColumns"></tr>
                              <tr mat-row *matRowDef="let row; columns: orgdisplayedColumns;"></tr>
                          
                              
                            </table>
                                
                <mat-paginator   #paginatororg="matPaginator"  [pageSizeOptions]="[5, 10, 25, 100]" [length]="organizationhistorycount" [pageSize]="10"></mat-paginator>
               
                       
                            </div>
                        </accordion-group>
                        <accordion-group is-open="false" is-disabled="false">
    
                            <div accordion-heading  style="width: 100%;">  
                                <div style="width: 50px; float: left">                    
                                    <img src="assets/images/homepage/history.png" alt="{{'views.user.User history' | translate}}" width="20px"/>
                                </div>                   
                                {{'views.user.Role assignment history' | translate}}


                            </div>
                       
                            <span class="" *ngIf="userroleshistorycount < 1">
                                {{'views.user.The user has no role assignment history.' | translate}} <br />

                            </span>
                            <div *ngIf="userroleshistorycount >= 1" class="scrollable-table">
                              <table mat-table [dataSource]="roleuserHistorydataSource" matSort>

                                     
                                <ng-container matColumnDef="role">
                                  <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> {{'views.user.Role'|translate}}</th>
                                  <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.role}} </td>
                                </ng-container>
                                <ng-container matColumnDef="createdOn">
                                  <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header>{{'views.user.created_on' | translate}}</th>
                                  <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.createdOn | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
                                </ng-container>
                                <ng-container matColumnDef="modifiedOn">
                                  <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> {{'views.user.modified_on' | translate}}</th>
                                  <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.modifiedOn | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
                                </ng-container>
                                <ng-container matColumnDef="modifiedBy">
                                  <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> {{'views.user.modified_by' | translate}}</th>
                                  <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.modifiedBy}} </td>
                                </ng-container>
                                <ng-container matColumnDef="changedField">
                                  <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> Changed Field</th>
                                  <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.changedField}} </td>
                                </ng-container>
                                <ng-container matColumnDef="initialValue">
                                  <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> Initial Value</th>
                                  <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.initialValue}} </td>
                                </ng-container>
                                <ng-container matColumnDef="changedValue">
                                  <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> Changed Value</th>
                                  <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.changedValue}} </td>
                                </ng-container>
                              
                           
                      
              
                          
                              <tr mat-header-row *matHeaderRowDef="roledisplayedColumns"></tr>
                              <tr mat-row *matRowDef="let row; columns: roledisplayedColumns;"></tr>
                          
                              
                            </table>
                                
                <mat-paginator  #paginatorroles="matPaginator"  [pageSizeOptions]="[5, 10, 25, 100]" [length]="userroleshistorycount" [pageSize]="10"></mat-paginator>
               
                       
                            </div>
                        </accordion-group>
                        </accordion>
                        <app-terms-conditions></app-terms-conditions>
</div>
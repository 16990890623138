import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tou',
  templateUrl: './tou.component.html',
  styleUrls: ['./tou.component.css']
})
export class TouComponent implements OnInit {
  touLanguages = ['Global-EN', 'Japanese', 'Belgium-EN'];
  touLang:string
  currentTouLanguage:string;
  constructor() { }
  touchangeLanguage(language)
  {
    this.currentTouLanguage=language;
  }
  ngOnInit(): void {
    this.touLang=this.touLanguages[0];
    this.currentTouLanguage=this.touLanguages[0];
  }

}

import { PermissionService } from 'src/app/permission.service';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';
import { TranslateService } from '@ngx-translate/core';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ContractService } from '../contract.service';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.css']
})
export class ContractListComponent implements OnInit , AfterViewInit{

  displayedColumns: string[] = ['status', 'name', 'wbs', 'startDate', 'endDate', 'action'];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  filteredAndPagedIssues: MatTableDataSource<any>;
  filter = {};
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  originalData: any;

constructor(private contractService: ContractService , private translateService: TranslateService , private commonService: CommonService , private permission: PermissionService , private router: Router) { }

  ngAfterViewInit() {
    this.apiTrigger();
    this.filteredAndPagedIssues.paginator = this.paginator;
    this.filteredAndPagedIssues.sort = this.sort;
  }
  downloadCSV()
  {
    const options = { 
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: false
      , 
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ['Status',  'Name', 'WBS', 'Start Date','End Date'],
      nullToEmptyString: true,
    };
    let arr =[];
      this.originalData.forEach(function (myans) {
        let obj ={};
        obj['status'] = myans.status;
        obj['name'] = myans.name;
        obj['wbs'] = myans.wbs;
        var todate=new Date(myans.startDate).getDate();
    var tomonth=new Date(myans.startDate).getMonth()+1;
    var toyear=new Date(myans.startDate).getFullYear();
    var todateend=new Date(myans.endDate).getDate();
    var tomonthend=new Date(myans.endDate).getMonth()+1;
    var toyearend=new Date(myans.endDate).getFullYear();
    var start_date=tomonth+'/'+toyear+'/'+toyear;
    var endDate=tomonthend+'/'+todateend+'/'+toyearend;

        obj['startdate'] = start_date;
        obj['enddate'] = endDate;

        arr.push(obj);
      });
    new Angular5Csv(arr, 'contracts',options);

  }
  applyFilter(key, value: string) {
    this.filteredAndPagedIssues.data = this.originalData.filter((item: any) => {
    let rowData;
    if (key) {
      rowData = item[key].toLowerCase();
    }
    return rowData.includes(value.toLowerCase());
    });
  }
  deleteOrganizationUser(row)
  {
    const request = row;
    request[`status`] = 'inactive';
    this.contractService.deleteContract(row[`id`] , request).subscribe(data =>
      {
        this.apiTrigger();
      },
      err =>
      {
      }
      );
  }
  gotoHomePage()
  {
    this.router.navigate([''])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }
  gotoContractNewPage()
  {
    this.router.navigate(['contract/new'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }
  navigatecontractDetail(row)
  {
    this.router.navigate(['/contract/' + row.id])
    .then(nav => {
       // true if navigation is successful
    }, err => {
       // when there's an error
    });
  }
  apiTrigger()
  {
    this.contractService.getContractDetails().subscribe((data: any) => {
      this.filteredAndPagedIssues = new MatTableDataSource(data.body);
      this.resultsLength = data.body.length;
      this.originalData = data.body;
      this.filteredAndPagedIssues.paginator = this.paginator;
      this.filteredAndPagedIssues.sort = this.sort;
   });
  }
  resetPaging(): void {
    this.apiTrigger();
  }
  ngOnInit(): void {
  }
  authorized() {
    return this.permission.isAdmin || this.permission.isUserAdmin;
  }
  showAllContracts()
  {
    this.paginator.pageSize = this.resultsLength;
    this.apiTrigger();
    if (this.paginator) {
      this.paginator.firstPage();
    }
  }

}

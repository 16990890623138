import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';
@Injectable({
  providedIn: 'root'
})
export class ServiceManagementService {
  private baseHref;
  token: any;
  constructor(private commonService: CommonService , private http: HttpClient)
  {
    this.baseHref = commonService.baseHref;
    this.token = commonService.token;
   }
  getServiceDetails() {
    return this.http.get<any[]>(this.baseHref + '/dis/rest/service', { observe: 'response' });
  }
  getAllServices(request) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      });
    const requestOptions = { headers };
    return this.http.post<any>(this.baseHref + '/dis/rest/service', request, requestOptions);
  }
  getServiceDetailswithId(id) {
    return this.http.get<any[]>(this.baseHref + '/dis/rest/service/'+id, { observe: 'response' });
  }
  deleteService(id, request) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers };
    return this.http.delete<any>(this.baseHref + '/dis/rest/service/' + id, requestOptions);
  }
  UpdateService(id, request) {   
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      const requestOptions = { headers };
      return this.http.patch(this.baseHref + '/dis/rest/service/' + id, request, requestOptions);
  }
  uniqueValue(value, id) {
    return this.http.get(this.baseHref + '/dis/rest/service/checkTechnicalId?value=' + value + '&id=' + id);
  }
  uniqueUUIDValue(value, id) { 
    return this.http.get(this.baseHref + '/dis/rest/service/checkUUID?value=' + value + '&id=' + id);
  }
  uniqueappidValue(value, id) {
    return this.http.get(this.baseHref + '/dis/rest/service/checkAppID?value=' + value + '&id=' + id);
  }
  saveService(request)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers };
    return this.http.put<any>(this.baseHref + '/dis/rest/service', request, requestOptions);
  }
  // getContractService(id)
  // {
  //   return this.http.get(this.baseHref + '/dis/rest/contractservice/service/'+id);
  // }
  getRoleService(id)
  {
    return this.http.get(this.baseHref + '/dis/rest/role/service/'+id);

  }
  // deleteServiceContract(id, request)
  // {
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'X-CSRF-TOKEN': `${this.token}`
  //   });
  //   const requestOptions = { headers };
  //   return this.http.put(this.baseHref + '/dis/rest/contractservice/' + id, request, requestOptions);
  // }
  
}

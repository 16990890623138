<div id="batchUpload" *ngIf="authorized()">

    <ol class="breadcrumb breadcrumb-dis">
         <li><a  [routerLink]="['']">{{'views.organization.Home' | translate }}</a></li>
         <li class="active">{{'views.organization.Batch upload' | translate }}</li>
         <li class="logout"><a href="./oidc/logout">{{'views.organization.Log out' | translate }}</a></li>
     </ol>
     
     
     <div class="titleicon"><img src="assets/images/homepage/batch.png" alt="new organi"/></div>
     <h1>{{'views.organization.Batch upload for new organizations' | translate }}</h1>
     <!--The alerts come here -->
     <!--<alert ng-repeat="alert in alerts" type="{{alert.type}}" close="closeAlert($index)" class="">{{alert.msg}}</alert>--> 
 
     <div *ngFor = "let alert of alerts">
      <p class="alert " [ngClass]="{'alert-danger' : alert.type=='danger','alert-info' : alert.type=='info'}">
          {{alert.msg}}
          <i  (click)="closeAlert(i)" aria-hidden="true" class="fa fa-times" style="
          float:right;
 font-size: 22px; "></i>
      </p>
  </div>
     
     <div class="panel panel-default" >
         <div class="panel-heading"></div>
         <div class="panel-body">
             <div style="float:right">
             <button type="button" class="btn btn-default btn-xs" (click)="showHelp()" title="Show help">
                             <span class="glyphicon glyphicon-question-sign" style="margin-right: 10px"></span>
             </button></div>
             
             <ng-container *ngIf="visible" ><p><span class="">{{'views.organization.The batch upload module lets you upload a CSV file containing instructions for the creation of new organzations. The operations will result in flows that are exactly the same as using the organization interface.' | translate }}</span></p>
                     
                   <p><span class="">{{'views.organization.The CSV file should contain lines of the following format (bold fields are required):' | translate }}</span></p>
                   
                   <p><span style="width: fit-content;" class="batchcmd"><b>CREATE</b>;<b>ORGNAME</b>;CONTACTMAIL;DESCRIPTION;TENANTNAME;SERVICEIDs</span></p>
                   
                   <div style="margin-left: 50px">
                     <ul class="" style="list-style-type: disc">
                         <li><b>CREATE</b>:{{'views.organization.The action that should be performed. Currently, only' | translate }} 'CREATE' {{'views.organization.is allowed.' | translate }}</li>
                         <li><b>ORGNAME</b>: {{'views.organization.Organization name.' | translate }}</li>
                         <li>CONTACTEMAIL {{'views.organization.(optional): Contact email for an organization.' | translate }}</li>
                         <li>DESCRIPTION {{'views.organization.(optional): Description of the organization. Please do not use semicolons in the description.' | translate }}</li>
                         <li><b>TENANTNAME</b>: {{'views.organization.Organization tenant name (global/swiss).' | translate }}</li>
                         <li>ORGANIZATIONSERVICEIDs: {{'views.organization.(optional): Add services to the organization by service ids (1234, 3456).' | translate }}</li>                         
                     </ul>
                   </div>
                     
                    <p><span class="">{{'views.organization.The CSV file should not contain headers. The first line is data.' | translate }}</span></p>
                    <p><span class="">{{'views.organization.Examples of a correct lines:' | translate }}</span></p> 
                    
                    <p><span class="batchcmd">CREATE;TheOrganization;ORG412;ORGEX412;;;;;</span></p>
                    <p><span class="batchcmd">CREATE;TheOrganization;ORG412;ORGEX412;;;;;ContractA</span></p>
                    <p><span class="batchcmd">CREATE;TheOrganization;ORG412;ORGEX412;admin&#64;theorganization.com;true;false;This is the description for TheOrganization;ContractA</span></p>
                    
                    <p><span class="">{{'views.organization.You will be notified by mail with the results of the batch changes.' | translate }}</span></p> 
                </ng-container>
                <div *ngIf="selected"><p><span class="">{{'views.user.Selected file:' | translate}} {{selectedfile.name}} ({{selectedfile.size / 1000 | number:0}} kb)</span></p>
                </div>
                <div *ngIf="showprogress" class="progress" style="height: 20px">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    [ngStyle]="{'width': progresswidth}"
                    
                    aria-valuenow="(progressvalue / progressmax)*100 | number:0"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                  
                  {{(progressvalue / progressmax)*100 | number:0 }}%
                  </div>
                </div>  
             <!--<div ng-show="selectedfile"><p><span class="">{{'views.organization.Selected file:' | translate }} {{selectedfile.name}} ({{selectedfile.size / 1000 | number:0}} kb)</span></p></div>
            -->  <!--<progressbar ng-show="showprogress" max="100" value="progressvalue * 100 / progressmax | number:0"><span style="color:black; white-space:nowrap;">{{(progressvalue / progressmax)*100 | number:0 }}%</span></progressbar>
            --> 
            <div class="btn-group btn-group-justified">
                <div class="btn-group">
                  <div class="choose_file" >
                    <button type="button"   class="btn btn-default btn-group" title="Select file">
                      
                      <span class="glyphicon glyphicon-paperclip" style="margin-right: 10px"></span>
                        {{'views.user.Select your CSV file' | translate}}
                    </button>
                    <input type="file" (change)="selectfile($event)"  accept=".csv,.CSV">  
                  </div>
                </div>
                <div class="btn-group">
                    <button [attr.disabled]="selected==false ? '':null" type="button" class="btn btn-default" (click)="upload()" title="Upload file">
                      <span class="glyphicon glyphicon-open" style="margin-right: 10px"></span>
                      {{'views.user.Upload file' | translate}}
                    </button>
                </div>
            </div>
 
         </div>
     </div>
     
 </div>
 <div style="display: flex;margin-left: 7%;
 margin-top: 19%;">
   <app-terms-conditions></app-terms-conditions>
</div>
 
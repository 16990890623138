import { isForOfStatement } from "typescript";

export class CONFIG {
    public static LOCALES = {
        'locales': [{
            'langCode': 'EN',
            'langValue': 'En - English (Global)'
        },
        {
            'langCode': 'FR',
            'langValue': 'Fr - French (France)'
        },
        {
            'langCode': 'NL',
            'langValue': 'Nl - Dutch'
        },
        {
            'langCode': 'ES',
            'langValue': 'Es - Spanish (Spain)'
        },
        {
            'langCode': 'ES-419',
            'langValue': 'Es-419 - Spanish (Latin-America)'
        },
        {
            'langCode': 'ZH',
            'langValue': 'Zh - Chinese (Simplified)'
        },
        {
            'langCode': 'PT',
            'langValue': 'Pt - Portuguese'
        },
        {
            'langCode': 'DE',
            'langValue': 'De - German'
        },
        {
            'langCode': 'DA',
            'langValue': 'Da - Danish'
        },
        {
            'langCode': 'SV',
            'langValue': 'Sv - Swedish'
        },
        {
            'langCode': 'FI',
            'langValue': 'Fi - Finnish'
        },
        {
            'langCode': 'NO',
            'langValue': 'No - Norwegian'
        },
        {
            'langCode': 'PL',
            'langValue': 'Pl - Polish'
        },
        {
            'langCode': 'JA',
            'langValue': 'Ja - Japanese'
        },
        {
            'langCode': 'IT',
            'langValue': 'It - Italian'
        },
        {
            'langCode': 'CS',
            'langValue': 'Cs - Czech'
        },
        {
            'langCode': 'RU',
            'langValue': 'Ru - Russian'
        },
        {
            'langCode': 'HU',
            'langValue': 'Hu - Hungarian'
        },
        {
            'langCode': 'FR-CA',
            'langValue': 'Fr-Ca - French (Canada)'
        }
        ],
        'preferredLocale': 'EN'
    };

    public static getSSOConfig = () =>  {
        if (window.location.host.startsWith('accountqa')) {
            return {
                auth_endpoint: 'https://logonqa.deloitte.com/am/oauth2/realms/root/realms/O365',
                end_session_endpoint:"https://logonqa.deloitte.com/am/oauth2/realms/root/realms/O365/connect/endSession",
                client_id: 'end-user-ui',
                redirect_uri: 'https://accountqa.deloitte.com/dis/ssologin',
                post_logout_redirect_uri: 'https://accountqa.deloitte.com/dis/oidc/logout',
                scope: 'fr:idm:* openid',
            };
        } else if(window.location.host.startsWith('accountstage')) {
            return {
                auth_endpoint: 'https://logonstage.deloitte.com/am/oauth2/realms/root/realms/O365',
                end_session_endpoint:"https://logonstage.deloitte.com/am/oauth2/realms/root/realms/O365/connect/endSession",
                client_id: 'end-user-ui',
                redirect_uri: 'https://accountstage.deloitte.com/dis/ssologin',
                post_logout_redirect_uri: 'https://accountstage.deloitte.com/dis/oidc/logout',
                scope: 'fr:idm:* openid',
            };
        }
        else if(window.location.host.startsWith('logonlocal')) {
            return {
                auth_endpoint: 'https://logondev.deloitte.com/am/oauth2/realms/root/realms/O365',
                end_session_endpoint:"https://logonlocal.deloitte.com/am/oauth2/realms/root/realms/O365/connect/endSession",
                client_id: 'end-user-ui',
                redirect_uri: 'https://logonlocal.deloitte.com/dis/ssologin',
                post_logout_redirect_uri: 'https://logondev.deloitte.com/dis/oidc/logout',
                scope: 'fr:idm:* openid',
            };
        }
        else {
            return {
                auth_endpoint: 'https://logon.deloitte.com/am/oauth2/realms/root/realms/O365',
                end_session_endpoint:"https://logon.deloitte.com/am/oauth2/realms/root/realms/O365/connect/endSession",
                client_id: 'end-user-ui',
                redirect_uri: 'https://account.deloitte.com/dis/ssologin',
                post_logout_redirect_uri: 'https://account.deloitte.com/dis/oidc/logout',
                scope: 'fr:idm:* openid',
            };

        }
    };

}


<div id="userDetail" [hidden]="!(authorized())">
    
    <ol class="breadcrumb breadcrumb-dis">
        <li><a [routerLink]="['']">{{'views.user.Home'| translate}}</a></li>
        <li><a (click)="gotoUserListPage()">{{'views.user.User list' | translate}}</a></li>
        <li class="active">{{user?.givenName}} {{user?.sn}}</li>
        <li class="logout"><a href="./oidc/logout">{{'views.user.Log out' | translate}}</a></li>
    </ol>
    <h1>{{user?.givenName}} {{user?.sn}}</h1>
    <div class="panel panel-default">
        <div class="panel-heading">
            <span class="">{{'views.user.User details' | translate}}</span>
        </div>
        
        <div class="panel-body">
          <div *ngFor = "let alert of alerts">
            <p class="alert " [ngClass]="{'alert-danger' : alert.type=='danger','alert-info' : alert.type=='info'}">
                {{alert.msg}}
                <i  (click)="closeAlert(i)" aria-hidden="true" class="fa fa-times" style="
                    float: right;
        font-size: 22px;
       
            "></i>
            </p>
        </div>
            <form [formGroup]="userrequestForm" (ngSubmit)="onSubmit(userrequestForm)" class="form-horizontal" role="form">
                <div class="row ">
                    <div class="col-sm-10">
                        <div class="form-group" show-errors>
                            <label for="user.status" class="col-sm-4 control-label ">{{'views.user.Status' | translate}}</label>
                            <div class="col-sm-5">
                                <img style="width: 25px" *ngIf="user?.accountStatus == 'suspended'" src="assets/images/icons/bullet_red.png" alt="{{'views.user.suspended' | translate}}" title="{{'views.user.suspended' | translate}}"/>
                                <img style="width: 25px" *ngIf="user?.accountStatus == 'active'" src="assets/images/icons/bullet_green.png" alt="{{'views.user.active' | translate}}" title="{{'views.user.active' | translate}}"/>
                            </div>
                        </div>
                        <div class="form-group" show-errors>
                            <label for="user.userType" class="col-sm-4 control-label ">{{'views.usertype.User type' | translate}}</label>
                            <div class="col-sm-5">
                                <input type="text" class="form-control" id="user.userType" name="userType" *ngIf="user?.userType === 'external' " disabled value="External"/>
                                <input type="text" class="form-control" id="user.userType" name="userType" *ngIf="user?.userType === 'internal' " disabled value="Internal"/>
                                <input type="text" class="form-control" id="user.userType" name="userType" *ngIf="user?.userType === 'clientidp' " disabled value="Client IDP"/>
                                <input type="text" class="form-control" id="user.userType" name="userType" *ngIf="user?.userType === 'azureb2b' " disabled value="Azure B2B"/>
                            </div>
                        </div>
                           
                        <!-- First Name -->
                        <div class="form-group" show-errors>
                            <label for="user.givenName" class="col-sm-4 control-label ">{{'views.user.First name' | translate}}</label>
                            <div class="col-sm-7">
                                <span class="input-group">
                                    <span class="input-group-addon"><span class="glyphicon glyphicon-user"></span></span>
                                    <input type="text" class="form-control" id="user.givenName"  name="givenName" formControlName="givenName" required
                                    [attr.disabled]="(user?.userType === 'internal' )? '' : null"/>
                                    
                                </span>
                            </div>
                            <div class="col-sm-1" *ngIf="userrequestForm.controls.givenName.errors!=null">
                                <p class="error-block" *ngIf="userrequestForm.controls.givenName.errors.required== true &&userrequestForm.controls.givenName.touched">
                                    {{'views.user.Required' | translate}}</p>
                                    <p class="error-block" *ngIf="!(userrequestForm.controls.givenName.errors.required) && userrequestForm.controls.givenName.status == 'INVALID' &&userrequestForm.controls.givenName.touched">{{'views.service.Invalid Input'
                                        | translate}}</p>
                            </div>
                        </div>
                             <!-- Last Name -->
                             <div class="form-group" show-errors>
                                <label for="user.sn" class="col-sm-4 control-label ">{{'views.user.Last name' | translate}}</label>
                                <div class="col-sm-7">
                                    <span class="input-group">
                                        <span class="input-group-addon"><span class="glyphicon glyphicon-user"></span></span>
                                        <input type="text" class="form-control" id="user.sn" name="sn" 
                                        formControlName="sn" 
                                        
                                        required 
                                        [ngClass]="{'input-error': userrequestForm.controls.sn.status=='INVALID'}"
                                        [attr.disabled]="(user?.userType === 'internal' )? '' : null"/>
                                    </span>
                                </div>
                                <div class="col-sm-4" *ngIf="userrequestForm.controls.sn.errors!=null">
                            
                                    <p class="error-block" *ngIf="userrequestForm.controls.sn.errors.required ==true">
                                        {{'views.user.Required' | translate}}</p>     
                                        <p class="error-block" *ngIf="!(userrequestForm.controls.sn.errors.required) && userrequestForm.controls.sn.status == 'INVALID' &&userrequestForm.controls.sn.touched">{{'views.service.Invalid Input'
                                            | translate}}</p>
                                    </div>
                            </div>
                             <!-- mail -->
                        <div class="form-group" show-errors>
                            <label  for="user.mail" class="col-sm-4 control-label ">{{'views.user.Email' | translate}}</label>
                            <div class="col-sm-7">
                                <span class="input-group">
                                    <span class="input-group-addon">@</span>
                                    <input type="text" class="form-control"
                                           name="mail" id="user.mail"
                                           formControlName="mail" 
                                           required
                                           
                                            formControlName="mail"
                                           [attr.disabled]="true"/>
                                </span>
                            </div>
                                        
                        </div>
                         <!-- Main Language -->
                         <div class="form-group" show-errors>
                            <label for="user.mainLanguage" class="col-sm-4 control-label ">{{'views.user.Main language' | translate}}</label>
                            <div class="col-sm-7">
                                <span class="input-group">
                                    <span class="input-group-addon"><span class="glyphicon glyphicon-globe"></span></span>
                                    <select formControlName="mainLanguage"
                                            required
                                            placeholder="Set main language"
                                            class="ng-touched caim-select-field ng-valid ng-not-empty ng-dirty ng-valid-parse input form-control">
                                        <option value="" disabled selected hidden >&#xf1ab; Language</option>     
                                        <option label="En - English (Global)" value="EN" >En - English (Global)</option>
                                        <option label="Nl - Dutch" value="NL" >Nl - Dutch</option>
                                        <option label="Fr - French (France)" value="FR" >Fr - French (France)</option>
                                        <option label="Es - Spanish (Spain)" value="ES" >Es - Spanish (Spain)</option>
                                        <option label="Es-419 - Spanish (Latin-America)" value="ES-419" >Es -  Spanish (Latin-America)</option>
                                        <option label="Pt - Portuguese" value="PT" >Pt - Portuguese </option>
                                        <option label="Zh - Chinese (Simplified)" value="ZH" >Zh - Chinese (Simplified)</option> 
                                        <option label="De - German" value="DE" >De - German</option> 
                                        <option label="Da - Danish" value="DA" >Da - Danish</option> 
                                        <option label="Sv - Swedish" value="SV" >Sv - Swedish</option> 
                                        <option label="Fi - Finnish" value="FI" >Fi - Finnish</option> 
                                        <option label="No - Norwegian" value="NO" >No - Norwegian</option> 
                                        <option label="Pl - Polish" value="PL" >Pl - Polish</option> 
                                        <option label="Ja - Japanese" value="JA" >Ja - Japanese</option>
                                        <option label="Cs - Czech" value="CS" >Cs - Czech</option> 
                                        <option label="Ru - Russian" value="RU" >Ru - Russian</option> 
                                        <option label="It - Italian" value="IT" >It - Italian</option> 
                                        <option label="Fr-Ca - French (Canada)" value="FR-CA" >Fr-Ca - French (Canada)</option> 
                                        <option label="Hu - Hungarian" value="HU" >Hu - Hungarian</option>       
                                    </select>
                                </span>
                            </div>
                        </div>

                        <div class="form-group" *ngIf="authorizedall() && user?.userType == 'external' && userrequestForm.controls.emailOtp.value == true && tenantValue!='swiss'">
                            <label for="user.emailOtpApprovalDate" class="col-sm-4 control-label ">{{'views.user.Email Otp Approval Date'| translate}}</label>
                            <div class="col-sm-7">
        
                                <p class="input-group">
                                  <mat-form-field appearance="outline" style="width: 280px; height: 45px;">
                                    <input style="margin-top: -18px; padding-left: 20px;"  
                                        [required]="(user?.userType == 'external' && userrequestForm.controls.emailOtp.value== true && tenantValue!='swiss')"
                                         formControlName="emailOtpApprovalDate" placeholder="dd/MM/yyyy" id="user.emailOtpApprovalDate" name="emailOtpApprovalDate" type="text" matInput [matDatepicker]="picker">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker>
                                        </mat-datepicker>
                                      </mat-form-field>
                                    <!-- <input id="user.emailOtpApprovalDate" name="emailOtpApprovalDate" type="text" class="form-control" 
                                           datepicker-popup="{{format}}" [(ngModel)]="user.emailOtpApprovalDate" formControlName="emailOtpApprovalDate" is-open="emailOtpApprovalDateopened" 
                                           min-date="minDate" datepicker-options="dateOptions" ng-required="user.emailOtp == true"
                                           
                                           close-text="Close" style="text-indent: 0px" 
                                        placeholder="dd/MM/yyyy"/>
                                    <span class="input-group-btn">
                                        <button type="button" class="btn btn-default" ng-click="emailOtpApprovalDateopen($event)"><i class="glyphicon glyphicon-calendar"></i></button>
                                    </span> -->
                                </p>
                            </div>
                            <div class="col-sm-1" *ngIf="userrequestForm.controls.emailOtpApprovalDate.errors!=null">
                                <p class="error-block" *ngIf="userrequestForm.controls.emailOtpApprovalDate.errors.required">{{'views.public.otpmail.Required'| translate}}</p>
                             </div>
                        </div>
                        <div class="form-group" *ngIf="authorizedall() && user?.userType == 'external'&& userrequestForm.controls.emailOtp.value == true && tenantValue!='swiss'">
                          <div class="col-sm-offset-4 col-sm-10">
                            <div class="checkbox">
                                    <label>
                                       
                                        <input type="checkbox" id="emailOtp"  (change)="valueChange($event,user,userrequestForm)" name="emailOtp" formControlName="emailOtp" />
                                        <span class="">{{"views.public.pwdset.ApprovedEmailOTP" | translate }}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                      <!-- Preferred MFA -->
                      <div class="form-group" show-errors *ngIf="mfaFlag">
                        <label for="user.preferredMFA" class="col-sm-4 control-label ">{{'views.user.Preferred MFA:' | translate}}</label>
                        <div class="col-sm-7">
                            <span class="input-group">
                                <span class="input-group-addon"><span class="glyphicon glyphicon-globe"></span></span>
                                <select 
                                        formControlName="preferredMFA"
                                        placeholder="Set Preferred MFA"
                                        class="ng-touched caim-select-field ng-valid ng-not-empty ng-dirty ng-valid-parse input form-control">
                                    <option value="" disabled selected hidden >&#xf1ab; MFA</option>
                                    <option [hidden]="!(authMethods.authenticatorApp)" value="AuthenticatorApp" >{{'views.selfservice.Authenticator app'| translate}}</option>
                                  
                                    <option [hidden]="!(authMethods.mobile)" value="voice" >{{'views.public.pwdset.Mobile phone (Phone call)' | translate}}</option>
                                    <option [hidden]="!(authMethods.mobile)"  value="sms" >{{'views.selfservice.Mobile phone (Text/SMS)' | translate}}</option>

                                    <option [hidden]="!(authMethods.emailOtp)" value="email" >{{'views.public.otpmail.Email' | translate}}</option>
                                </select>
                            </span>
                        </div>
                    </div>  
                      <!-- Mobile number -->
                      <div class="form-group" show-errors *ngIf="!(user?.userType === 'internal' || user?.userType === 'clientidp' || user?.userType === 'azureb2b' || (tenantValue =='swiss'))">
                        <label for="user.telephoneNumber" class="col-sm-4 control-label ">{{'views.user.Mobile' | translate}}</label>                       
                            <div class="col-sm-7">
 
                                    <ngx-intl-tel-input 
                                [preferredCountries]="preferredCountries"
                              [enableAutoCountrySelect]="true"
                               [enablePlaceholder]="true"
                                [searchCountryFlag]="true"
                              [searchCountryField]=
                              "[SearchCountryField.Iso2, 
                              SearchCountryField.Name]" 
                              [selectFirstCountry]="false"
                              [selectedCountryISO]="CountryISO.UnitedStates"
                              [maxLength]="15"
                              [phoneValidation]="true" 
                              [separateDialCode]="true"
                              (ngModelChange)="checkNumber()"
                              name="mobile" 
                              formControlName="telephoneNumber">
</ngx-intl-tel-input>
                                
                            </div>
                            <p class="error-block" *ngIf="existiingphonechangeFlag">User has already phone number you can't set telephone number to null </p>

                            <div class="col-sm-2" *ngIf="userrequestForm.controls.telephoneNumber.errors!=null">
                              <p class="error-block" *ngIf="mobileFlag&& userrequestForm.controls.telephoneNumber.touched">{{'views.userrequest.Format' | translate}}</p>
                          </div>
                        </div>
                        <div [hidden]="userrequestForm.get('federatedClientName').value == ''" *ngIf="authorized() && user?.userType!='internal' && user?.userType!='external'">

                            <!-- Federated Client Name -->          
                            <div class="form-group">
                              <label for="federated_it_info.federation_client_name" class="col-sm-4 control-label ">{{'views.federated.Federated or B2B client name' | translate}}</label>
                              <div class="col-sm-7">
                                <span class="input-group">
                                  <span class="input-group-addon"><span class="glyphicon glyphicon-user"></span></span>
                                  <input type="text" class="form-control" id="federated_it_info.federation_client_name" name="federateditname" formControlName="federatedClientName" 
                                  />
                                </span>
                              </div>
                            </div>
                          
                            <!-- Federated Client IT Email -->
                            <div class="form-group">
                              <label  for="federated_it_info.federated_client_helpdesk_email" class="col-sm-4 control-label ">{{'views.federated.Client HelpDesk email' | translate}}</label>
                              <div class="col-sm-7">
                                <span class="input-group">
                                  <span class="input-group-addon">@</span>
                                  <input type="text" class="form-control"
                                         name="federateditemail" id="federated_it_info.federated_client_helpdesk_email"
                                         formControlName="federatedClientEmail" 
                                         />
                                </span>
                              </div>
                            </div>
                          
                            <!-- Federated Client IT Phone Number -->
                            <div class="form-group">
                              <label for="federated_it_info.federated_client_helpdesk_phonenumber" class="col-sm-4 control-label ">{{'views.federated.Client HelpDesk phone number' | translate}}</label>
                              <div class="col-sm-7">
                                <span class="input-group">
                                  <span class="input-group-addon"><span class="glyphicon glyphicon-phone"></span></span>
                                  <input type="text" class="form-control" id="federated_it_info.federated_client_helpdesk_phonenumber" name="federatedphonenumber" formControlName="federatedClientPhone"  
                                  />
                                </span>
                              </div>
                            </div>
                          </div>


                        <div class="col-sm-7">
                            <div class="btn-group savebutton">
                                <button type="button"
                                        class="btn btn-default"
                                        (click)="onSubmit(userrequestForm)"
                                        [attr.disabled]="((isInValid()&& mobileFlag)||existiingphonechangeFlag)?'' :null">
                                    <span class="glyphicon glyphicon-floppy-disk" style="margin-right: 10px"></span>{{'views.user.Save' | translate}}
                                </button>
                            </div>
                        </div>
                        
                </div>
                </div>
            </form>
            
            </div>
            </div>

            <accordion>
                <div id="orgmembership" *ngIf="authorizedOrganizations()">
                    <accordion-group is-open="false" is-disabled="false">
    
                            <div accordion-heading  style="width: 100%;">  
                                <div style="width: 50px; float: left">                    
                                <img src="assets/images/homepage/organizations.png" alt="{{'views.user.Organization membership' | translate}}" width="30px"/>
                               </div> {{'views.user.Organization membership' | translate}} ({{organizationcount}})
                            </div>
                       
                        <span class="" *ngIf="organizationcount < 1">
                            {{'views.user.The user is not a member of any organization.' | translate}} <br />
                        </span>
                        <div *ngIf="organizationcount >= 1">
                            <table mat-table [dataSource]="dataSource" matSort>

                                <!-- ID Column -->
                                <ng-container matColumnDef="status">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                                  <td mat-cell *matCellDef="let row"> 
                                    <img style="width: 25px" *ngIf="row.status == 'suspended'" src="assets/images/icons/bullet_red.png" alt="{{'views.user.suspended' | translate}}" title="{{'views.user.suspended' | translate}}"/>
                                    <img style="width: 25px" *ngIf="row.status == 'active'" src="assets/images/icons/bullet_green.png" alt="{{'views.user.active' | translate}}" title="{{'views.user.active' | translate}}"/>
                                  </td>
                                </ng-container>
                            
                                <!-- Progress Column -->
                                <ng-container matColumnDef="organization">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Organization</th>
                                  <td mat-cell *matCellDef="let row" (click)="navigateorgDetail(row)"> {{row.name}} </td>
                                </ng-container>
                            
                                <ng-container matColumnDef="filter-status">
                                    <th mat-header-cell *matHeaderCellDef> 
                                     
                                       </th>
                                  </ng-container>
                                
                                 
                                  <ng-container matColumnDef="filter-organization">
                                    <th mat-header-cell *matHeaderCellDef> 
                                       <mat-form-field appearance="outline">
                                      <input
                                        matInput
                                        (keyup)="applyFilter($event)"
                                        
                                      />
                                    </mat-form-field> </th>
                                  </ng-container>
                            
                
                            
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-header-row *matHeaderRowDef="['filter-status', 'filter-organization']"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            
                                
                              </table>
                            </div>
            <mat-paginator #paginatororgs="matPaginator" [pageSizeOptions]="[5, 10, 25, 100]" [length]="organizationcount" [pageSize]="10"></mat-paginator>
           
                   
                    
                    </accordion-group>
                </div>

                <div id="roleassignments" >
                    <accordion-group is-open="false" is-disabled="false">
    
                        <div accordion-heading  style="width: 100%;">  
                            <div style="width: 50px; float: left">                          
                                <img src="assets/images/homepage/roles.png" alt="{{'views.user.Role assignments' | translate}}" width="30px"/>
                            </div> {{'views.user.Role assignments' | translate}} ({{roleassignmentcount}})
                        </div>
                        <span class="" *ngIf="roleassignmentcount < 1">
                            {{'views.user.The user has no role assignments.' | translate}} <br />
                        </span>
                        <div *ngIf="roleassignmentcount >= 1">
                          <table mat-table [dataSource]="filteredAndPagedIssues" class="example-table" matSort matSortActive="email"
                          matSortDisableClear matSortDirection="asc" (matSortChange)="resetPaging()">
                          <!-- (matSortChange)="resetPaging()" -->
          
                          <ng-container matColumnDef="status">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear
                                  style="font-weight: bold; color: black;">{{'views.user.Status'|translate}}
                              </th>
                              <td mat-cell *matCellDef="let row">
                                  <img style="width: 25px" *ngIf="row.status === 'suspended'"
                                      src="assets/images/icons/bullet_red.png" alt="{{'views.user.suspended' | translate}}"
                                      title="{{'views.user.suspended' | translate}}" />
                                  <img style="width: 25px" *ngIf="row.status === 'active'"
                                      src="assets/images/icons/bullet_green.png" alt="{{'views.user.active' | translate}}"
                                      title="{{'views.user.active' | translate}}" />
                              </td>
                          </ng-container>
                          <!-- {
                              "userId": {
                                  "id": 10,
                                  "lastname": "Savitha",
                                  "firstname": "B",
                                  "email": "bsavitha@deloitte.com",
                                  "status": "active",
                                  "userType": "external"
                              }
                          } -->
          
                          <ng-container matColumnDef="role">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear
                                  style="width: fit-content; font-weight: bold; color: black;">{{'views.user.Role' | translate}}
                              </th>
                              <td mat-cell *matCellDef="let row">{{row?.roleName}}</td>
                          </ng-container>
          
                          <ng-container matColumnDef="organization">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear
                                  style="font-weight: bold; color: black;">
                                  Organization</th>
                              <td mat-cell *matCellDef="let row">{{row?.orgName}}</td>
                          </ng-container>
                          
                          <ng-container matColumnDef="action">
                              <th mat-header-cell *matHeaderCellDef>
                              </th>
                              <td mat-cell *matCellDef="let row">
                                  <div *ngIf="row.status  !== 'suspended'" style="display: flex;">
                                      <app-confirmsuspend (onconfirm)="suspendRole(row)"></app-confirmsuspend>
                                      <app-confirm-delete (onconfirm)="deleteRole(row)"></app-confirm-delete>
          
                                  </div>
                                  <div *ngIf="row.status  === 'suspended'" style="display: flex;">
                                      <app-confirm-activate (onconfirm)="activateRole(row)"></app-confirm-activate>
                                      <app-confirm-delete (onconfirm)="deleteRole(row)"></app-confirm-delete>
                                  </div>
                              </td>
                          </ng-container>
                         
                          <ng-container matColumnDef="filter-status">
                              <th mat-header-cell *matHeaderCellDef>
          
                              </th>
                          </ng-container>
          
                         
                          <ng-container matColumnDef="filter-role">
                              <th mat-header-cell *matHeaderCellDef>
                                  <mat-form-field appearance="outline">
                                      <input matInput [formControl]="searchRoleControl" (keyup)="filterData('roleName',$event.target.value)"/>
                                  </mat-form-field>
                              </th>
                          </ng-container>
                          <ng-container matColumnDef="filter-organization">
                              <th mat-header-cell *matHeaderCellDef>
                                  <mat-form-field appearance="outline">
                                      <input matInput [formControl]="searchOrglControl"  (keyup)="filterData('orgName',$event.target.value)" />
                                  </mat-form-field>
                              </th>
                          </ng-container>
          
                      
                          <ng-container matColumnDef="filter-action">
                              <th mat-header-cell *matHeaderCellDef>
          
                              </th>
                          </ng-container>
          
                          <!-- <ng-container matColumnDef="filter-email">
                              <th mat-header-cell *matHeaderCellDef> <mat-form-field appearance="outline">
                                      <input matInput (keyup)="applyFilterbyEmail($event.target.value)" />
                                  </mat-form-field> </th>
                          </ng-container> -->
          
                          <tr mat-header-row *matHeaderRowDef="roledisplayedColumns"></tr>
                          <tr mat-header-row
                              *matHeaderRowDef="['filter-status', 'filter-role', 'filter-organization','filter-action']"
                              class="example-second-header-row">
                          </tr>
          
                          <tr mat-row *matRowDef="let row; columns: roledisplayedColumns;"></tr>
                      </table>           
                        </div>
                        <mat-paginator #paginatorroles="matPaginator" [pageSizeOptions]="[5, 10, 25, 100]" [length]="roleassignmentcount" [pageSize]="10"></mat-paginator>

                    </accordion-group>
                </div>
                <div id="adminroleassignments" *ngIf="authorizedRoleAssignments()">
                  <accordion-group is-open="false" is-disabled="false">
  
                      <div accordion-heading  style="width: 100%;">  
                          <div style="width: 50px; float: left">                          
                              <img src="assets/images/homepage/roles.png" alt="{{'views.user.Role assignments' | translate}}" width="30px"/>
                          </div> {{'views.admin.roles.list' | translate}} ({{adminroleassignmentcount}})
                      </div>
                      <span class="" *ngIf="adminroleassignmentcount < 1">
                          {{'views.user.The user has no role assignments.' | translate}} <br />
                      </span>
                      <div *ngIf="adminroleassignmentcount >= 1">
                          <table mat-table [dataSource]="adminroledataSource" matSort>

                              
                             
                       
                           <ng-container matColumnDef="role">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'views.user.Role' | translate}}</th>
                              <td mat-cell *matCellDef="let row" > {{row.role}} </td>
                            </ng-container>
                  
                              <ng-container matColumnDef="organization">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'views.user.Organization' | translate}}</th>
                                <td mat-cell *matCellDef="let row"  style="padding :25px !important"> 
                                   <ng-container *ngIf="row.organizationName !=undefined">
                                  {{row.organizationName}}
                                </ng-container> </td>
                              </ng-container>
                             
                               
                                <!-- <ng-container matColumnDef="filter-role">
                                  <th mat-header-cell *matHeaderCellDef> 
                                     <mat-form-field appearance="outline">
                                    <input
                                      matInput
                                      (keyup)="applyFilter($event)"
                                      
                                    />
                                  </mat-form-field> </th>
                                </ng-container>
                                <ng-container matColumnDef="filter-organization">
                                  <th mat-header-cell *matHeaderCellDef> 
                                     <mat-form-field appearance="outline">
                                    <input
                                      matInput
                                      (keyup)="applyFilter($event)"
                                      
                                    />
                                  </mat-form-field> </th>
                                </ng-container> -->
              
                          
                              <tr mat-header-row *matHeaderRowDef="adminroledisplayedColumns"></tr>
                              <!-- <tr mat-header-row *matHeaderRowDef="['filter-role', 'filter-organization']"></tr> -->
                              <tr mat-row *matRowDef="let row; columns: adminroledisplayedColumns;"></tr>
                          
                              
                            </table>
                          
          <mat-paginator #paginatoradminroles="matPaginator" [pageSizeOptions]="[5, 10, 25, 100]" [length]="adminroleassignmentcount" [pageSize]="10"></mat-paginator>
         
                 
                      </div>
                      
                  </accordion-group>
              </div>
                <div id="history" *ngIf="authorized()" (click)="navigateUserDetail(user)">
                    <accordion-group is-open="false" is-disabled="false">
                        <div accordion-heading  style="width: 100%;">  
                            <div style="width: 50px; float: left">                          
                                <img src="assets/images/homepage/history.png" alt="{{'views.user.User history' | translate}}" width="30px"/>
                            </div>
                            {{'views.user.User history' | translate}}
                        </div>
                    </accordion-group>
                </div>
                <div id="passwordreset" *ngIf="authorized() && user?.userType !== 'internal' && user?.userType !== 'azureb2b' && user?.userType !== 'clientidp'"  >
                    <accordion-group is-open="false" is-disabled="false">
                        <div accordion-heading  style="width: 100%;">  
                            <div style="width: 50px; float: left">                          
                                <img src="assets/images/homepage/key.png" alt="{{'views.user.Manage credentials' | translate}}" width="30px"/>
                            </div>{{'views.user.Manage credentials' | translate}}
                        </div>
                       
                         
                        <p *ngIf="!(user?.preferences?.preferredMFA === undefined)">{{'views.user.Preferred MFA:' | translate}} {{user?.preferences.preferredMFA}}</p>
                        <p *ngIf="!(manageCred?.pwdChangedTime === undefined)">{{'views.user.Password last set:' | translate}} {{manageCred.pwdChangedTime}}</p>
                        <p *ngIf="!(manageCred?.pwdExpirationTime === undefined)">{{'views.user.Password exp date:' | translate}} {{manageCred.pwdExpirationTime}}</p>
                        <p *ngIf="manageCred?.successTimestamp != undefined">{{'views.user.Last success login date:' | translate}} {{manageCred.successTimestamp}}</p>
                        <p *ngIf="manageCred?.failedTimestamp != undefined">{{'views.user.Last failed login date:' | translate}} {{manageCred.failedTimestamp}}</p>
                        <p *ngIf="manageCred?.loginCount === undefined">{{'views.user.Login Count:' | translate}} {{manageCred.loginCount}}</p>
                        <p *ngIf="manageCred?.registerApp === false && (user?.userType!='clientidp')" >{{'views.user.The user did not register register an Authenticator app yet.' | translate}}</p>
                        <p *ngIf="manageCred?.registerApp === false && (user?.userType!='clientidp')">{{'views.user.The Authenticator app was registered on:' | translate}} {{otpapplastchangeddate}}</p>
        
                        <div class="btn-group btn-group-justified" *ngIf = "!(user?.userType === 'clientidp' || user?.userType === 'azureb2b')">
                            
                            <div class="btn-group savebutton" >
                                    <button type="button"
                                            class="btn btn-default"
                                            (click)="resetPassword(user.email)"
                                          
                                            [attr.disabled]="resetPasswordButtonClicked ? '' : null"

                                            >
                                        <span class="glyphicon glyphicon-lock" style="margin-right: 10px">
                                        </span>{{'views.user.Send reset password link' | translate}}
                                    </button>
                                </div>
                                <div class="btn-group savebutton" *ngIf="tenantValue !='swiss'">
                                    <button type="button"
                                            class="btn btn-default"
                                            (click)="registerOtp()"
                                            [attr.disabled]="registerOtpButtonClicked ? '' : null"
                                            >
                                        <span class="glyphicon glyphicon-phone" style="margin-right: 10px">
                                        </span>{{'views.user.Send register OTP app link' | translate}}
                                    </button>
                                </div>
                            </div>
                            <br />
                            <div class="btn-group btn-group-justified">
                            
                                <div class="btn-group savebutton" *ngIf="tenantValue !='swiss' && user?.preferences?.preferredMFA?.toLowerCase() !== 'authenticatorapp' && manageCred?.registerApp">
                                    <button type="button"
                                            class="btn btn-default"
                                            (click)="revokeOtp()"
                                            [attr.disabled]="revokeOtpButtonClicked ? '' : null"
                                            *ngIf="user?.preferences?.preferredMFA?.toLowerCase() !== 'authenticatorapp'">                                                        
                                                   <span class="glyphicon glyphicon-remove" style="margin-right: 10px">
                                        </span>{{'views.user.Revoke OTP application registration' | translate}}
                                    </button>
                                </div>
                            </div>
                    </accordion-group>
                </div>
                <div class="btn-group btn-group-justified" *ngIf="authorized() && user?.userType === 'azureb2b'">
                  <div class="btn-group savebutton">
                      <button type="button"
                              class="btn btn-default"
                              (click)="requireReRegister()"
                              [attr.disabled]="requireReRegisterButtonClicked==true ? '':null">
                          <span class="glyphicon glyphicon-lock" style="margin-right: 10px">
                          </span>{{'views.user.Reset MFA preference for Azure B2B user' | translate}}
                      </button>
                  </div>
              </div>   
                </accordion>
                <app-terms-conditions></app-terms-conditions>
            </div>
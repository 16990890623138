import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-federation-new',
  templateUrl: './federation-new.component.html',
  styleUrls: ['./federation-new.component.css']
})
export class FederationNewComponent implements OnInit {
  private _baseHref: string;
  private _token;
  public get baseHref(): string {
    return this._baseHref;
  }
  public set baseHref(value: string) {
    this._baseHref = value;
  }
  public get token() {
    return this._token;
  }
  public set token(value) {
    this._token = value;
  }

  // federationForm: FormGroup = new FormGroup({
  //   federatedclientname: new FormControl('', [Validators.required, Validators.pattern(/^[^>+\:\;|\/*^$%@\(\)\{\}\[\]!\\~=#*]+$/)]),
  //   domains: new FormControl('', [Validators.required]),
  //   helpdeskEmail: new FormControl('', [Validators.pattern(/^[a-zA-Z0-9._%-\'. \-_!#^~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]),
  //   helpdeskMobile: new FormControl('', [Validators.pattern(/^\+[1-9]\d{7,14}$/)]),
  //   emailIAM: new FormControl('', [Validators.pattern(/^[a-zA-Z0-9._%-\'. \-_!#^~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]),
  //   mobileIAM: new FormControl('', [Validators.pattern(/^\+[1-9]\d{7,14}$/)])
  // });
  federationRequest: FormGroup = new FormGroup({
    federatedclientname: new FormControl('', [Validators.required, Validators.pattern(/^[^>+\:\;|\/*^$%@\(\)\{\}\[\]!\\~=#*]+$/)]),
    clientdomains: new FormControl('', [Validators.required]),
    clienthelpdeskemail: new FormControl('', [Validators.pattern(/^[a-zA-Z0-9._%-\'. \-_!#^~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]),
    clienthelpdeskphone: new FormControl('', [Validators.pattern(/^\+[1-9]\d{7,14}$/)]),
    clientIAMemail: new FormControl('', [Validators.pattern(/^[a-zA-Z0-9._%-\'. \-_!#^~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]),
    clientIAMphone: new FormControl('', [Validators.pattern(/^\+[1-9]\d{7,14}$/)])
  });
  
  alerts = [];

  constructor(private translateService: TranslateService, private formBuilder: FormBuilder, private commonService: CommonService, private router: Router, private http: HttpClient) { }

  ngOnInit(): void {
  }
  closeAlert(index) {
    this.alerts.splice(index, 1);
  }
  submit(request: FormGroup) {
    
    if (this.federationRequest.valid) {
      const federationRequest = this.federationRequest.value;
      this.commonService.saveFederation(federationRequest).subscribe( data => {
        if(data.status==1)
        {
          this.translateService.get('views.federated.Client Name  already exists').subscribe(res => {
            this.alerts.push({type: 'danger', msg: res});
          });
        }
        else if(data.status ==2)
        {
          this.translateService.get('views.federated.Domains already exists').subscribe(res => {
            this.alerts.push({type: 'danger', msg: res});
          });
          
        }
        else if(data.status ==-1)
          {
            this.alerts.push({type: 'danger', msg: data.response});

           
            
          }
        else
        {
        this.router.navigate(['/federation/details'])
          .then(nav => {
             // true if navigation is successful
          }, err => {
             // when there's an error
          
          });
        }
      }
      );
      
    } else {
      Object.keys(this.federationRequest.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.federationRequest.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
          });
        }
      });
    }
  }
  onsubmit() {
    if (this.federationRequest.valid) {
      const federationRequest = this.federationRequest.value;
    }
  }



  get federatedclientname() {
    return this.federationRequest.get('federatedclientname');
  }
  get domains() {
    return this.federationRequest.get('domains');
  }
  get helpdeskEmail() {
    return this.federationRequest.get('helpdeskEmail');
  }
  get helpdeskMobile() {
    return this.federationRequest.get('helpdeskMobile');
  }
  get emailIAM() {
    return this.federationRequest.get('emailIAM');
  }
  get mobileIAM() {
    return this.federationRequest.get('mobileIAM');
  }

  gotoHomePage() {
    this.commonService.titleSub = 'a new';
    this.router.navigate([''])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }
}
<div id="serviceNew" *ngIf="authorized()">

    <ol class="breadcrumb breadcrumb-dis">
        <li><a [routerLink]="['']">{{"views.contract-detail.Home"| translate}}</a></li>
        <li><a [routerLink]="['/services/list']">{{'views.service.Service list' | translate }}</a></li>
        <li class="active">{{serviceData.name}}</li>
          <li class="logout"><a href="./oidc/logout">{{'views.service.Log out' | translate }}</a></li>
    </ol>
    <h1>{{serviceData.name}}</h1>
    <div *ngFor = "let alert of alerts">
      <p class="alert " [ngClass]="{'alert-danger' : alert.type=='danger','alert-info' : alert.type=='info'}">
          {{alert.msg}}
          <i  (click)="closeAlert(i)" aria-hidden="true" class="fa fa-times" style="
          float:right;
 font-size: 22px; "></i>
      </p>
  </div>
      <div class="panel panel-default">
        <div class="panel-heading">
          <div style="width: 50px; float: left">                          
            <img src="assets/images/homepage/services.png" alt="service management" width="30px">
          </div><span class="ng-binding">{{'views.service.Service details' | translate }}</span>
    
        </div>
        <div class="panel-body">
            <form [formGroup]="servicedetailrequestForm" name="serviceForm" class="form-horizontal" role="form">
                <div class="form-group" show-errors>
                    <label for="service.name" class="col-sm-2 control-label " style="width:25% !important">{{'views.service.Name' | translate }}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="service.name" name="name" formControlName="name" required/>
                    </div>
                    <div class="col-sm-1" *ngIf="servicedetailrequestForm.controls.name.errors!=null">
                        <p class="error-block" *ngIf="servicedetailrequestForm.controls.name.errors?.required== true &&servicedetailrequestForm.controls.name?.touched">
                            {{'views.userrequest.Required' | translate}}</p>
                        <p class="error-block" *ngIf="!(servicedetailrequestForm.controls.name.errors?.required) && servicedetailrequestForm.controls.name.status == 'INVALID' &&servicedetailrequestForm.controls.name?.touched">{{'views.service.Invalid Input'
                            | translate}}</p>

                    </div>
                </div>
                <div class="form-group" show-errors>
                    <label for="service.technicalId" class="col-sm-2 control-label " style="width:25% !important">{{'views.service.Technical ID' | translate }}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control"
                               id="service.technicalId" name="technicalId" 
                               formControlName="technicalId"
                               style="text-transform:uppercase;"
                               [attr.disabled]="true"
                               />
                    </div>
                   
                </div>
                <div class="form-group" show-errors>

                    <label for="service.description" class="col-sm-2 control-label " style="width:25% !important">{{'views.service.Description' | translate }}</label>

                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="service.description" name="description" formControlName="description"/>
                    </div>
                </div>
                <div class="form-group" show-errors>

                    <label for="service.url" class="col-sm-2 control-label " style="width:25% !important">{{'views.service.URL' | translate }}</label>
                    <div class="col-sm-7">
                       <!-- <input type="text" class="form-control" id="service.url" name="url" formControlName="service.url" required
                        ng-pattngPatternern="/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?|^((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/.*)?$/"/> -->
                        <input type="text" class="form-control" id="service.url" name="url" formControlName="url" required
                       />
                    </div>
                    <div class="col-sm-2">
                        <p class="error-block" *ngIf="servicedetailrequestForm.controls.url.errors?.required== true &&servicedetailrequestForm.controls.url?.touched">
                            {{'views.service.Required' | translate}}</p>
                    </div>
                    <div class="col-sm-2">
                        <p class="error-block" *ngIf="servicedetailrequestForm.controls['url'].hasError('pattern') && servicedetailrequestForm.controls.url?.touched">{{'views.service.The URL is invalid' | translate }}</p>

                    </div>
                </div>
                <div class="form-group" show-errors>
                    <label for="service.uuid" class="col-sm-2 control-label " style="width:25% !important">{{'views.service.UUID' | translate }}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control"
                               id="service.uuid" name="uuid"
                               formControlName="uuid"
                               [attr.disabled]="true"
                               />
                    </div>
                  
                </div>
                <!--App ID start -->
                <div class="form-group" show-errors>
                    <label for="service.appid" class="col-sm-2 control-label " style="width:25% !important">{{'views.service.AppId' | translate }}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control"
                               id="service.appid" name="appid"
                               formControlName="appid"
                               [attr.disabled]="true"

                               />
                    </div>
                </div>
                <!--App ID end -->
                <div class="form-group" show-errors>

                    <label for="service.appOwner" class="col-sm-2 control-label " style="width:25% !important">{{'views.service.App Owner' | translate }}</label>

                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="service.appOwner" name="appOwner" formControlName="appOwner" />
                    </div>
                    <div class="col-sm-2">
                        <p class="error-block" *ngIf="servicedetailrequestForm.controls['appOwner'].hasError('pattern') && servicedetailrequestForm.controls.appOwner?.touched">{{'views.service.The email address is invalid' | translate }}</p>

                    </div>
                </div>
                <div class="form-group" show-errors>

                    <label for="service.fss" class="col-sm-2 control-label " style="width:25% !important">{{'views.service.FSS' | translate }}</label>

                    <div class="col-sm-7">
                    
                        <select formControlName="fss"
                        required
                        class="caim-select-field ng-valid ng-not-empty ng-dirty ng-valid-parse input">
                        <option [ngValue]="null" selected>-Select-</option>
                        <option  *ngFor="let sta of fsss" [ngValue]="sta">{{sta}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group" show-errors>

                    <label for="service.memberFirmHA" class="col-sm-2 control-label " style="width:25% !important">{{'views.service.Member firm hosting the app' | translate }}</label>

                    <div class="col-sm-7">
                        <select formControlName="memberFirmHA"
                        required
                        class=" caim-select-field ng-valid ng-not-empty ng-dirty ng-valid-parse input">
                        <option [ngValue]="null" selected>-Select-</option>
                        <option *ngFor='let memberFirmHAValue of memberFirmHAs' [value]="memberFirmHAValue">{{memberFirmHAValue}}

                        </option>
                        </select>
                    </div>
                </div>
                <div class="form-group" show-errors>

                    <label for="service.onboardedDate" class="col-sm-2 control-label " style="width:25% !important">{{'views.service.Onboarded Date' | translate }}</label>

                    <div class="col-sm-7">
                        <p class="input-group" style="width: 100%;">
                           
                            <input id="service.onboardedDate" name="onboardedDate" type="date" class="form-control" 
                                formControlName="onboardedDate" 
                                    style="text-indent: 0px" 
                                placeholder="dd/MM/yyyy"/>
                           
                        </p>
                    </div>
                </div>
                <div class="form-group" show-errors>
                    <label for="service.contactEmail" class="col-sm-2 control-label " style="width:25% !important">{{'views.service.Contact email' | translate }}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="service.contactEmail" name="contactEmail" formControlName="contactEmail" />
                    </div>
                    <div class="col-sm-2">
                        <p class="error-block" *ngIf="servicedetailrequestForm.controls['contactEmail'].hasError('pattern') && servicedetailrequestForm.controls.contactEmail?.touched">{{'views.service.The email address is invalid' | translate }}</p>
                    </div>
                </div>

                <div class="col-sm-2"></div>
                <div class="col-sm-7" style="width:106% !important; margin :14px -393px;">
                    <div class="btn-group savebutton">
                        <button type="button"
                                class="btn btn-default"
                                (click)="submit()"
                                [attr.disabled]="servicedetailrequestForm.status === 'INVALID'? '':null">
                            <span class="glyphicon glyphicon-floppy-disk" style="margin-right: 10px"></span>
                            {{'views.service.Save' | translate }}
                        </button>
                    </div>
                </div>
            </form>
            </div>
            </div>
            <accordion>
                        <div id="roleassignments" *ngIf="authorizedRoles()" >
                            <accordion-group is-open="false" is-disabled="false">
                                <div accordion-heading  style="width: 100%;">  
                                    <div style="width: 50px; float: left">                    
                                    <img src="assets/images/homepage/organizations.png" alt="{{'views.user.Organization membership' | translate}}" width="30px"/>
                                   </div> {{'views.service.Roles' | translate }} ({{rolecount}})
                                </div>
                           
                            <span class="" *ngIf="rolecount < 1">
                                {{'views.service.The service has no roles.' | translate }}<br />
                                <br />
                            </span>
                            <div class="example-table-container">
                          
                                <table mat-table  style="width:100%" [dataSource]="rolefilteredtabledata" class="example-table"  matSort matSortActive="name"
                                matSortDisableClear matSortDirection="asc" (matSortChange)="resetPaging()">
                                
                            
                                  <!-- Title Column -->
                                  <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef>{{'views.user.Status'|translate}}</th>
                                    <td mat-cell *matCellDef="let row">
                                      <img style="width: 25px" *ngIf="row.status === 'suspended'"
                                      src="assets/images/icons/bullet_red.png" alt="{{'views.user.suspended' | translate}}"
                                      title="{{'views.user.suspended' | translate}}" />
                                  <img style="width: 25px" *ngIf="row.status === 'active'"
                                      src="assets/images/icons/bullet_green.png" alt="{{'views.user.active' | translate}}"
                                      title="{{'views.user.active' | translate}}" />
                                    </td>
                                  </ng-container>
                            
                                
                                  <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header >Role</th>
                                    <td mat-cell *matCellDef="let row" (click)="navigateRoleDetail(row)" >{{row.name}}</td>
                                  </ng-container>
                            
                                        <ng-container matColumnDef="action">
                                      <th mat-header-cell *matHeaderCellDef >
                                       
                                      <!-- <div *ngIf="row.userId.status  === 'suspended'">
                                          <div confirm-activate onconfirm="activateOrganizationUser(organizationuser)"></div>
                                          <div confirm-delete onconfirm="deleteOrganizationUser(organizationuser)"></div>
                                      </div> -->
                                      </th>
                                      <td mat-cell *matCellDef="let row">
                                        <div *ngIf="row.status  !== 'suspended'">
                                          <app-confirmsuspend (onconfirm)="suspendRole(row)"></app-confirmsuspend>
                                         <app-confirm-delete (onconfirm)="deleteRole(row)"></app-confirm-delete>
                                
                                       </div> 
                                        <div *ngIf="row.status  === 'suspended'">
                                          <app-confirm-activate (onconfirm)="activateRole(row)"></app-confirm-activate>
                                          <app-confirm-delete (onconfirm)="deleteRole(row)"></app-confirm-delete>
                                        </div>
                                      </td>
                                    </ng-container>
                                     <!-- Item Description Column -->
                    <ng-container matColumnDef="filter-status">
                      <th mat-header-cell *matHeaderCellDef> 
                       
                         </th>
                    </ng-container>
                  
                    <!-- Cost Description Column -->
                    <ng-container matColumnDef="filter-name">
                      <th mat-header-cell *matHeaderCellDef> 
                         <mat-form-field  class="namest" appearance="outline">
                        <input
                          matInput
                          (keyup)="applyRoleFilter('name',$event.target.value)"
                          
                        />
                      </mat-form-field> </th>
                    </ng-container>
                     <!-- Item Description Column -->                    
                                  <tr mat-header-row *matHeaderRowDef="roledisplayedColumns"></tr>
                                  <tr mat-header-row *matHeaderRowDef="['filter-status', 'filter-name']"
                        class="example-second-header-row">
                    </tr>
                  
                                  <tr mat-row *matRowDef="let row; columns: roledisplayedColumns;"></tr>
                                </table>
                              </div>
                            
                              <mat-paginator  #paginatorroles="matPaginator" [pageSizeOptions]="[5, 10, 25, 100]" [length]="rolecount" [pageSize]="10"></mat-paginator>
                                </accordion-group>
                                </div>
                        </accordion>
        
    </div>
    <div style="display: flex;margin-left: 7%;
margin-top: 19%;">
  <app-terms-conditions></app-terms-conditions>
</div>

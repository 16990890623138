<h1 mat-dialog-title> {{ data.organizations.givenName}}  {{ data.organizations.sn}} belongs to the following organizations</h1>
<hr>
<div mat-dialog-content>
  <mat-list>
    <div mat-subheader class="list-header">
      <div class="header-item">Organization Name</div>
      <div class="header-item">Action</div>
    </div>
    <mat-list-item *ngFor="let organization of data.organizations.memberOfOrg">
      <div class="list-content">
        <div class="content-item">
          {{ organization.name }}
        </div>
        <div class="content-item">
          <button mat-icon-button (click)="deleteOrganization(data.organizations._id,organization)" aria-label="Delete organization">
            <mat-icon>delete</mat-icon>
          </button>        </div>
</div>      
    </mat-list-item>
  </mat-list>
</div>
<div mat-dialog-actions  class="centered-dialog-actions">
  <button mat-button (click)="onNoClick()">Cancel</button>
</div>
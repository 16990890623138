import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable, of as observableOf, merge } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { CommonService } from '../../../common.service';
import { PermissionService } from '../../../permission.service';
import { Router } from '@angular/router';
import { RolesService } from '../../../roles-service.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { OrganizationService } from '../../organization-service.service';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.css']
})
export class OrganizationListComponent implements AfterViewInit {
  organizations: any;
  organization: any;
  info;
  user: any;
  userData = [];
  myControl = new FormControl();
  originalData: any;
  filteredOptions: Observable<[]>;
  resultsLength = 0;
  isRateLimitReached = false;
  filteredAndPagedIssues: Observable<[]>;
  searchNameControl=new FormControl();
  searchtechnicalIdControl=new FormControl();
  displayedColumns: string[] = ['status', 'orgname', 'technicalid', 'action'];
  isLoadingResults: any;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  filter: any ={};
  authorized() {
    return this.permission.isAdmin ;
  }
  // filter(name: string) {
  //   const filterValue = name.toLowerCase();
  //   return this.organizations.filter(option => option.name && option.name.toLowerCase().includes(filterValue));
  // }
  resetPaging() {
    throw new Error('Method not implemented.');
    }
  public constructor(private commonService: CommonService , private organizationService: OrganizationService , private translateService: TranslateService , private roleService: RolesService , private permission: PermissionService, private router: Router) {
    this.searchNameControl.valueChanges
    .pipe(
      debounceTime(300), // Delay of 300 ms
      distinctUntilChanged() // Only emit when the current value is different from the last
    )
    .subscribe(newValue => {
      this.filter['name'] = newValue!=""?newValue.toLowerCase():"";

      this.apiTrigger(); // Trigger your method here
    });
    this.searchtechnicalIdControl.valueChanges
    .pipe(
      debounceTime(300), // Delay of 300 ms
      distinctUntilChanged() // Only emit when the current value is different from the last
    )
    .subscribe(newValue => {
      this.filter['technicalId'] = newValue!=""?newValue.toLowerCase():"";
      this.apiTrigger(); // Trigger your method here
    });
  }
   navigateorgDetail(row)
   {
    this.router.navigate(['/organization/' + row.id])
    .then(nav => {
       // true if navigation is successful
    }, err => {
       // when there's an error
    });
   }
   deleteOrganizationUser(row)
  {
    
    const request = row;
    request[`status`] = 'inactive';
    this.organizationService.deleteOrganization(row.id, row).subscribe(data =>
    {
      this.apiTrigger();
    });
  }
  ngAfterViewInit(): void {
    // this.filteredAndPagedIssues.paginator = this.paginator;
    // this.filteredAndPagedIssues.sort = this.sort;
    this.apiTrigger();
    // this.filteredAndPagedIssues.paginator = this.paginator;
    // this.filteredAndPagedIssues.sort = this.sort;
  }
  apiTrigger()
  {
    
    this.filteredAndPagedIssues = merge(this.paginator.page)
    .pipe(
      startWith({}),
      switchMap(() => {
        let request={}
        if(Object.keys(this.filter).length>0)
         request = { 'page': this.paginator.pageIndex + 1, 'order': this.sort.direction, 'orderBy': this.sort.active, 'filters': this.filter, 'size': this.paginator.pageSize};
        else
         request = { 'page': this.paginator.pageIndex + 1, 'order': this.sort.direction, 'orderBy': this.sort.active, 'size': this.paginator.pageSize};

        return this.organizationService.getAllOrganizations(request);
      }),
      map(data => {
        // Flip flag to show that loading has finished.
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        this.resultsLength = data.attr.total;
       // this.orginalData = data.organizations;
        return data.organizations;
      }),
      catchError((e) => {
        this.isLoadingResults = false;
        // Catch if the GitHub API has reached its rate limit. Return empty data.
        this.isRateLimitReached = true;
        return observableOf([]);
      })
    );
  }
  applyFilter(key, value: string) {
  
    this.filter[key] = value;
    this.apiTrigger();
    this.paginator.firstPage();
    // this.filteredAndPagedIssues.data = this.originalData.filter((item: any) => {
    // let rowData;
    // if (key) {
    //   if(item[key] !=undefined)
    //   rowData = item[key].toLowerCase();
    // }
    // if(value != undefined && rowData !=undefined)
    // return rowData && rowData.includes(value.toLowerCase());
    // });
  }
  navigateOrganizationDetail(row)
  {
    this.router.navigate(['/organization/' + row.id ])
    .then(nav => {
      this.commonService.previoususerlistlocation = '/user/list/all';
       // true if navigation is successful
    }, err => {
       // when there's an error
    });
  }
  gotoOrganizationNewPage() {
    this.router.navigate(['organization/new'])
    .then(nav => {
       // true if navigation is successful
    }, err => {
       // when there's an error
    });
    }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PasswordService } from '../password.service';
import zxcvbn from 'zxcvbn';
import { filter } from 'rxjs/operators';
import { CONFIG } from '../config';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { CommonService } from '../common.service';
import dictionaryWords from './dictionary-words';
import { param } from 'jquery';
import { parsePhoneNumber, parsePhoneNumberWithError } from 'libphonenumber-js';
@Component({
  selector: 'app-pwdset',
  templateUrl: './pwdset.component.html',
  styleUrls: ['./pwdset.component.css']
})
export class PwdsetComponent implements OnInit {
  @ViewChild('mainLan') teams!: ElementRef;
  code: any;
  lang: any;
  error: any;
  itype = 'password';
  password2type = 'password';
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  passwordForm: FormGroup = new FormGroup({
    tmpkey: new FormControl('', [Validators.pattern(/^(?=.{10,})((?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z ])|(?=.*\d)(?=.*[^a-zA-Z0-9 ])(?=.*[a-zA-Z])).*$/), Validators.required]),
    tmpkey2: new FormControl('', [Validators.required]),
    currentLocaleDisplayName: new FormControl('', [Validators.required]),
    authenticatorchecked: new FormControl(''),
    mobilesmscheckbox: new FormControl(''),
    emailcheckbox: new FormControl('')
  });
  validateOtpForm: FormGroup = new FormGroup({
    'otp': new FormControl('', Validators.required)
  });
  telephoneOtpForm: FormGroup = new FormGroup({
    'otp': new FormControl('', Validators.required)
  });
  passwordContainsTen: boolean = false;
  passwordContainsUppercase: boolean = false;
  passwordContainsLowercase: boolean = false;
  passwordContainsNumeral: boolean = false;
  passwordNonAlphaNumeric: boolean = false;
  passwordHasIdenticalPattern: boolean = false;
  passwordHasConsecutivePattern: boolean = false;
  passwordHasDictionaryWord: boolean = false;
  mobile : any;
  preferredMFA : any;
  azureTenant : any;


  matching: boolean;
  email: any;
  firstname: any;
  lastname: any;
  mobileNumber: any;
  pwddoesnotcontainuserattributes = true;
  strongpassword: boolean;
  title: any;
  text: any;
  userrequest: any;
  validateOTP = false;
  submitClicked = false;
  path: any;
  emailOtp: boolean;
  user: any;
  mainlandChina: string;
  showfingerprintimage: boolean;
  otpvalidated: boolean;
  validateMobilOTP: boolean;
  organization: any;
  qrcodeurl: string;
  codes: any;
  successType: any;
  successtitle: any;
  successtext: any;
  tmpkeyFlag = false;
  localesDisplayNames = CONFIG.LOCALES.locales;
  codevalidation: string;
  otpalert: string;
  alertMessage: string;
  showAlert: boolean;
  mobileNumberSelected: boolean;
  otpErrorMsg: string;
  mfaSelected: boolean;
  emailSelected: boolean;
  showOTPSection: boolean;
  mobilephonecallchecked: boolean;
  messageMedium: string;
  otpValidatedLocal: boolean;
  
  passWordCallBackResponse: any;
  mfaChoiceCallBack: any;
  resumeCode: any;
  preferredMFAFlag:any;
  passWordSetFlag:boolean;
  mfaMobilePreferenceCallBack:any;
  mfaAuthAppPreferenceCallBack:any;
  otpSetCallBack:any;
  preferrencesCallBack:any;
  userPreferredMFA:any;
  userMobile:any;
  qrURL : any;
  authAppCallBack: any;
  qrText: any;
  qrcodeValue: any;
  identicalPattern = /(.)\1\1\1/;
  consecutivePattern = /(0123|1234|2345|3456|4567|5678|6789|7890|abcd|bcde|cdef|defg|efgh|fghi|ghij|hijk|ijkl|jklm|klmn|lmno|mnop|nopq|opqr|pqrs|qrst|rstu|stuv|tuvw|uvwx|vwxy|wxyz)/;
  
  


  hide = true;
  hide1 = true;
  phoneForm: FormGroup = new FormGroup({
    'mobile': new FormControl('', [Validators.required, Validators.pattern(/^\+[1-9]\d{7,14}$/)])
  });
  privacyStatementForm: FormGroup = new FormGroup({
    'privacy': new FormControl('')
  });
  orgTenantName: any;
  twiliootpalert: any;
  
 gotolink =null;
  userRelationship: any;
  constructor(private router: Router, private route: ActivatedRoute, public translateService: TranslateService, private passwordService: PasswordService, private commonService: CommonService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => {
     
        this.code = params.code;
        this.gotolink=params.goto;
        if(params.lang!=null)
        this.lang = (params.lang).toUpperCase();
        else
        this.lang="EN";
        this.translateService.use(this.lang);
        this.commonService.setComponentLanguage(this.lang);
        this.passwordForm.get('authenticatorchecked').disable();
        this.passwordForm.get('mobilesmscheckbox').disable();
        this.passwordForm.get('emailcheckbox').disable();
       
        //Defaulting this variables for simplicity
        this.mobileNumberSelected = false;
        this.otpvalidated = false;
        this.validateMobilOTP = false;
        this.mfaSelected = false;
        this.emailSelected = false;
        this.userPreferredMFA = ''

      }
    );
    this.route.url.subscribe((event) => {
      this.path = '/' + event[1].path;
    });

    this.mainlandChina = 'no';
    this.translateService.get('views.js.alerts.emailprocessing.Email Processing').subscribe(res => {
      this.title = res;
    });
    this.translateService.get('views.js.alerts.emailprocessing.Your action is being performed').subscribe(res => {
      this.text = res;
    });
    this.passWordCallBackResponse = this.passwordService.getPassWordCallBack(this.code).subscribe(data => {
       ;
      this.passWordCallBackResponse = data.body;
      if((this.path !== '/success') && (this.passWordCallBackResponse === null ||  this.passWordCallBackResponse.callbacks === null || this.passWordCallBackResponse.callbacks === 'undefined'))
        {
          this.translateService.get('views.js.alerts.emailprocessing.Unknown Request').subscribe(res => {
            this.title = res;
          });
          this.translateService.get('views.js.alerts.emailprocessing.YouSomething went wrong').subscribe(res => {
            this.text = res;
          });
          this.router.navigate(['public/error']);
        }
      else {
        if (this.path === '/set') {
          this.matching = false;
          this.pwddoesnotcontainuserattributes = true;
          this.successType = this.passwordService.successtype;

          this.extractUserProfile(this.passWordCallBackResponse.callbacks.find(callback => callback.type === 'TextOutputCallback'));

          if (this.successType === 'activation') {
            this.translateService.get('views.js.alerts.emailprocessing.Activation successful head').subscribe(res => {
              this.successtitle = res;
            });
            this.translateService.get('views.js.alerts.emailprocessing.Activation successful body').subscribe(res => {
              this.successtext = res;
            });
          }
        }
      }
    },err=>{
      this.router.navigate(['public/error']);

    });
    this.commonService.getCompLanguage().subscribe(language => {
      if (language) {
        this.passwordForm.get('currentLocaleDisplayName').setValue(language);
      }
    });
  }
  testPattern() {
    if (this.passwordForm.controls.tmpkey.errors?.pattern == null) {
      this.tmpkeyFlag = false;
    }
    else {
      this.tmpkeyFlag = true;
    }
  }
  optionsChanged() {
    this.preferredMFAFlag=null;
  }
  changeType() {
    if (this.itype === 'password') {
      this.itype = 'text';
    }
    else {
      this.itype = 'password';
    }
  }

  
  changePasswordtype2() {
    if (this.password2type === 'password') {
      this.password2type = 'text';
    }
    else {
      this.password2type = 'password';
    }
  }
  checksuccesslog()
  {
    return this.strongpassword && this.matching && this.pwddoesnotcontainuserattributes && !(this.isFormInvalid());
  }
  isFormInvalid(): boolean {
    return !this.passwordContainsTen || !this.passwordContainsUppercase || !this.passwordContainsLowercase || !this.passwordContainsNumeral || !this.passwordNonAlphaNumeric || this.passwordHasIdenticalPattern || this.passwordHasConsecutivePattern || this.passwordHasDictionaryWord;
 
  }
  checkdictionaruWord(value)
  {
    const password = value.toLowerCase();
    const hasDictionaryWord = dictionaryWords.some(word => password.includes(word.toLowerCase()));
    return hasDictionaryWord ;
  }
  check() {
    this.passwordForm.get('tmpkey').markAsTouched();

    const pwd = this.passwordForm.get('tmpkey').value;
    if(pwd!="" && pwd!=null)
      {
        this.tmpkeyFlag = true;
    if (pwd)
       {
      // this.testPattern();
      this.passwordContainsTen = pwd.length > 9;
      this.passwordContainsUppercase = !!pwd.match(/[A-Z]/);
      this.passwordContainsLowercase = !!pwd.match(/[a-z]/);
      this.passwordContainsNumeral = !!pwd.match(/[0-9]/);
      this.passwordNonAlphaNumeric = !!pwd.match(/[\W]/);
      this.passwordHasIdenticalPattern = !!pwd.match(this.identicalPattern);
      this.passwordHasConsecutivePattern = !!pwd.match(this.consecutivePattern);
      this.passwordHasDictionaryWord = this.checkdictionaruWord(pwd);
      

    }
    if (this.passwordForm.get('tmpkey').value === this.passwordForm.get('tmpkey2').value) {
      this.matching = true;
      this.passwordForm.get('tmpkey').disable();
      this.passwordForm.get('tmpkey2').disable();
      document.getElementById('password2').setAttribute('style', '');
    } else {
      this.matching = false;
      this.passwordForm.get('tmpkey').enable();
      this.passwordForm.get('tmpkey2').enable();
      if (this.passwordForm.get('tmpkey2').value !== '') {
        document.getElementById('password2').setAttribute('style', 'border: 1px solid #da291c');
      }
    }

    const textOutputCallback = this.passWordCallBackResponse.callbacks.
    find(callback => callback.type === 'TextOutputCallback');
    this.extractUserProfile(textOutputCallback);

    if (
      (this.passwordForm.get('tmpkey').value.toLowerCase().indexOf(this.email.toLowerCase()) !== -1) ||
      (this.passwordForm.get('tmpkey').value.toLowerCase().indexOf(this.firstname.toLowerCase()) !== -1) ||
      (this.passwordForm.get('tmpkey').value.toLowerCase().indexOf(this.lastname.toLowerCase()) !== -1)
    ) {
      this.pwddoesnotcontainuserattributes = false;
    } else {
      this.pwddoesnotcontainuserattributes = true;
    }
    if (zxcvbn(this.passwordForm.get('tmpkey').value).score > 1) {
      this.strongpassword = true;
    }
    if(this.strongpassword && this.pwddoesnotcontainuserattributes && !(this.isFormInvalid())){
      this.tmpkeyFlag = false;
    }

    if(this.strongpassword 
      && (this.passwordForm.get('currentLocaleDisplayName').value !== null 
      && this.passwordForm.get('currentLocaleDisplayName').value !== '' 
      && this.passwordForm.get('currentLocaleDisplayName').status !== 'undefined') && this.matching && this.pwddoesnotcontainuserattributes && !(this.isFormInvalid()))
      {
    //Setting Password in call back
    this.passWordCallBackResponse.callbacks.
    find(callback => callback.type === 'ValidatedCreatePasswordCallback').input[0].value = 
    this.passwordForm.get('tmpkey').value;

    //Setting Language in call back
    this.passWordCallBackResponse.callbacks.
    find(callback => callback.type === 'StringAttributeInputCallback').input[0].value = this.passwordForm.get('currentLocaleDisplayName').value.toLowerCase();
    if(this.azureTenant!=='swiss'){

    this.mfaChoiceCallBackResponse()
}
      }
      else
      {
        this.passwordForm.get('tmpkey').enable();
        this.passwordForm.get('tmpkey2').enable(); 
      }
    }
    else
      {
        this.tmpkeyFlag=false;
      }
  }
  public changeLanguage(): void {
    this.translateService.use(this.passwordForm.get('currentLocaleDisplayName').value);
    document.getElementById('language')[`value`] = this.passwordForm.get('currentLocaleDisplayName').value;
    this.commonService.setLanguage(this.passwordForm.get('currentLocaleDisplayName').value);

    if(this.strongpassword 
      && (this.passwordForm.get('currentLocaleDisplayName').value !== null 
      && this.passwordForm.get('currentLocaleDisplayName').value !== '' 
      && this.passwordForm.get('currentLocaleDisplayName').status !== 'undefined') && this.matching && this.pwddoesnotcontainuserattributes && !(this.isFormInvalid())){
        this.tmpkeyFlag=false;
    

    //Setting Password in call back
    this.passWordCallBackResponse.callbacks.
    find(callback => callback.type === 'ValidatedCreatePasswordCallback').input[0].value = 
    this.passwordForm.get('tmpkey').value;

    //Setting Language in call back
    this.passWordCallBackResponse.callbacks.
    find(callback => callback.type === 'StringAttributeInputCallback').input[0].value = 
    this.passwordForm.get('currentLocaleDisplayName').value.toLowerCase();
    if(this.azureTenant!=='swiss'){
    this.mfaChoiceCallBackResponse()
    }
    }
  }
removeCallbackByType(callbackArray, targetType: string) {
    return callbackArray.filter(callback => callback.type !== targetType);
}
  //From This we get the response of MFA Choice Call Back. this.mfaChoiceCallBack which is further used in Mobile Option, authenticator and OTP Set.
  mfaChoiceCallBackResponse() {
    this.mfaChoiceCallBack = this.passwordService.postPassWordCallBack(this.code,this.passWordCallBackResponse).
    subscribe((response:any) => {
      this.mfaChoiceCallBack = response;
      this.passWordSetFlag = true;
      this.passwordForm.get('tmpkey').disable();
      this.passwordForm.get('tmpkey2').disable();
      this.passwordForm.get('currentLocaleDisplayName').disable();
      this.passwordForm.get('authenticatorchecked').enable();
      this.passwordForm.get('mobilesmscheckbox').enable();
      this.passwordForm.get('emailcheckbox').enable();
      return response.body;
    });
    this.passWordSetFlag = true;
  }

  //This method is used to set the otp for MFa choice
  verifycode(otp) {
    
    this.mfaAuthAppPreferenceCallBack.callbacks.
    find(callback => callback.type === 'NameCallback').input[0].value = otp;
  

    this.passwordService.postAuthAPPOTPSet(this.code,this.mfaAuthAppPreferenceCallBack).subscribe(data => {
       ;
      this.showAlert = true;

      this.preferrencesCallBack = data;


      this.preferrencesCallBack = data;
      //HiddenValueCallback
      
      if (this.preferrencesCallBack !== 'undefined' && this.preferrencesCallBack !== null && (this.preferrencesCallBack.callbacks.find(callback => callback.type === 'StringAttributeInputCallback')!=='undefined'  &&  this.preferrencesCallBack.callbacks.find(callback => callback.type === 'StringAttributeInputCallback'))) {
        this.otpvalidated = true;
     
        this.translateService.get('views.js.alerts.emailprocessing.OTP application registration successful head').subscribe(res => {
          this.otpalert = res;
        });
      } else {
        this.mfaAuthAppPreferenceCallBack = data;
        this.otpvalidated = false;
        this.translateService.get('views.js.alerts.emailprocessing.verification of one time password failed').subscribe(res => {
          this.otpalert = res;
        });
      }
    });
  }

  //This method after enterining mobile number and clicking on send OTP
  sendOTP(messageMedium, mobileNumber) {
    this.messageMedium = messageMedium;
    const codes = '{"mobileNumber": "' + mobileNumber.internationalNumber + '", "messageMedium": "' + messageMedium + '", "locale": "' + this.passwordForm.get('currentLocaleDisplayName').value + '"}';
    const choices = this.mfaChoiceCallBack.callbacks.find(item => item.type === "ChoiceCallback").output.find(item => item.name === "choices").value;
    const smsVoiceIndex = choices.indexOf(messageMedium);

    this.mfaChoiceCallBack.callbacks.find(item => item.type === "ChoiceCallback").input[0].value = smsVoiceIndex
    this.passwordService.postMobileOption(this.code,this.mfaChoiceCallBack).subscribe(data => {
      ;
     this.showOTPSection = true;
     this.mfaMobilePreferenceCallBack = data;
     this.mfaMobilePreferenceCallBack.callbacks.find(item => item.type === "StringAttributeInputCallback").input[0].value = mobileNumber.internationalNumber;
     this.passwordService.postMobileOption(this.code,this.mfaMobilePreferenceCallBack).subscribe(data => {
     ;
     this.otpSetCallBack = data;
     });
   });
     
  }

  //This method is used to verify OTP for mobile number and return the preferrenceCallBack response which is final step in mobile MFA
  verifyOTP(mobileNumber, otpCode) {

    this.otpValidatedLocal = false;

    this.otpSetCallBack.callbacks[1].input[0].value = otpCode
    this.otpSetCallBack.callbacks.find(item => item.type === "NameCallback").input[0].value = otpCode;

      this.passwordService.postOTPSet(this.code, this.otpSetCallBack).subscribe(data => {
        ;
        this.otpValidatedLocal = true;
       if (data != null && data.callbacks.find((callback: any) => callback.type === 'BooleanAttributeInputCallback') != undefined) {
          (this.messageMedium === 'sms' || this.messageMedium === 'voice') ? this.validateMobilOTP = true : this.mobilephonecallchecked = true;
          this.showOTPSection = false;
          this.translateService.get('views.js.alerts.emailprocessing.otpsuccess').subscribe(res => {
            this.twiliootpalert = res;
            this.preferrencesCallBack = data;
          });
        }
        else {
          this.translateService.get('views.js.alerts.emailprocessing.otpfail').subscribe(res => {
          this.otpErrorMsg = res;
          });
          this.otpSetCallBack = data;
        }
      });
  }


//When authenticator is clicked
mfaChecked(event){


const choices = this.mfaChoiceCallBack.callbacks.find(item => item.type === "ChoiceCallback").output.find(item => item.name === "choices").value;
  
const authIndex = choices.indexOf("authenticatorapp");

this.mfaChoiceCallBack.callbacks.find(item => item.type === "ChoiceCallback").input[0].value = authIndex;

this.passwordService.postAuthDeviceRegistration(this.code,this.mfaChoiceCallBack).subscribe(data => {
  ;
  this.mfaAuthAppPreferenceCallBack = data;

  const input = this.mfaAuthAppPreferenceCallBack.callbacks.find(item => item.type === "HiddenValueCallback").output.find(o => o.name === "value").value;

  this.passwordService.confirmDeviceRegistration(this.code,this.mfaAuthAppPreferenceCallBack).subscribe(data => {
    this.mfaAuthAppPreferenceCallBack = data;
    this.passwordService.generateCode(input).subscribe(data => {
    
    this.qrcodeurl = '../dis/qrcode';
    const otpUrl = input;
    const secretRegex = /secret=([^&]+)/;
    const secretMatch = otpUrl.match(secretRegex);
if (secretMatch) {
    const secret = secretMatch[1];
    this.qrcodeValue=secret;
} else {
}
    });



});

//Phone number radio button should be disabled
this.passwordForm.get('mobilesmscheckbox').disable();
this.passwordForm.get('emailcheckbox').disable();

});

//Changing MFA selection option
if (event.target.checked){
      this.mfaSelected = true;
    } else {
      this.mfaSelected = false;
    }
  }
  //When mobile check box is clicked
  mobileCheckboxClick(event){

    this.passwordForm.get('authenticatorchecked').disable();
    this.passwordForm.get('emailcheckbox').disable();

    if (event.target.checked) {
      this.mobileNumberSelected = true;
      this.phoneForm.get('mobile').setValue(this.mobileNumber);
    } else {
      this.mobileNumberSelected = false;
    }
  }

  //When email check box is clicked
  emailClicked(event){

    if (event.target.checked) {
      const choices = this.mfaChoiceCallBack.callbacks.find(item => item.type === "ChoiceCallback").output.find(item => item.name === "choices").value;
      const emailIndex = choices.indexOf("email");
      this.mfaChoiceCallBack.callbacks.find(item => item.type === "ChoiceCallback").input[0].value = emailIndex;
      //This for emailotp selection, used mobile call back, No need of writing new code
      this.passwordService.postOTPSet(this.code,this.mfaChoiceCallBack).subscribe(data => {
        ;
        this.preferrencesCallBack = data;
      });
      this.passwordForm.get('authenticatorchecked').disable();
      this.passwordForm.get('mobilesmscheckbox').disable();
      this.emailSelected = true;
    } else {
      this.emailSelected = false;
    }
  }

  //when mobile number is entered
  checkMobile(phoneForm , mobile)
  {    
    if (this.phoneForm.controls.mobile.value !='' && this.phoneForm.controls.mobile.value !=null)
    {
      const mob = this.phoneForm.controls.mobile.value.internationalNumber === null ? this.mobileNumber :  this.phoneForm.controls.mobile.value.internationalNumber ;
      // const regex = new RegExp('^([0|\+[0-9]{1,5})?([0-9]{10})$');
      // const valid = regex.test(mob.split(' ').join(''));
      const phoneNumber = parsePhoneNumberWithError(mob);
      if (phoneNumber) {
        if(phoneNumber.isValid())
        {
          return false;
        }
        else
        {
          return true;
        }
      } else {
        return false;
      }
    }
    else
    {

      return true;
    }

}
  pwdSet(preferredMFA){

    if(this.azureTenant === 'swiss'){
      this.passwordService.postMFAPreference(this.code,this.passWordCallBackResponse).subscribe(data => {
        ;
        this.passwordService.successtype = 'activation';
        let decodedUrl = decodeURIComponent(this.gotolink);

        if (this.gotolink || this.gotolink != null) {
          window.location.href = decodedUrl;
      } else {
        this.router.navigate(['public/success']);
      }
     });
    }

    else {
    this.submitClicked = true; 
    this.preferrencesCallBack.callbacks.
    find(callback => callback.type === 'StringAttributeInputCallback').input[0].value = preferredMFA;
    if(this.mainlandChina === 'yes'){

    this.preferrencesCallBack.callbacks.
    find(callback => callback.type === 'BooleanAttributeInputCallback').input[0].value = true;
    }
    else{
    this.preferrencesCallBack.callbacks.
    find(callback => callback.type === 'BooleanAttributeInputCallback').input[0].value = false;
    }
    this.passwordService.postMFAPreference(this.code,this.preferrencesCallBack).subscribe(data => {
      ;
      this.passwordService.successtype = 'activation';
      let decodedUrl = decodeURIComponent(this.gotolink);

      if (this.gotolink || this.gotolink != null) {
        window.location.href = decodedUrl;
    } else {
      this.router.navigate(['public/success']);
    }  });
  }
}

  checkDisabledComplete(mainlandChina) {

    if (((mainlandChina === 'no'
      || (mainlandChina === 'yes'
        && this.privacyStatementForm.get('privacy').value)))
      && ((this.validateMobilOTP || this.otpvalidated || this.emailSelected) && !(this.userPreferredMFA === ''))) {
      return false;
    }
    else if (this.azureTenant === 'swiss' && (this.strongpassword
      && (this.passwordForm.get('currentLocaleDisplayName').value !== null
        && this.passwordForm.get('currentLocaleDisplayName').value !== ''
        && this.passwordForm.get('currentLocaleDisplayName').status !== 'undefined') && this.matching &&this.pwddoesnotcontainuserattributes &&!(this.isFormInvalid()))) {

      this.passwordForm.get('tmpkey').disable();
      this.passwordForm.get('tmpkey2').disable();
      this.passwordForm.get('currentLocaleDisplayName').disable();
      return false;

    }
    else {
      return true;

    }
  }

  extractUserProfile(textOutputCallback: any) {
    const userprofile = JSON.parse(textOutputCallback.output[0].value);
    this.email = userprofile.email;
    this.firstname = userprofile.firstName;
    this.lastname = userprofile.lastName;
    this.mobile = userprofile.mobile;
    this.preferredMFA = userprofile.preferredMFA;
    this.azureTenant = userprofile.azureTenant;
    this.emailOtp = userprofile.emailOtp;
    this.userRelationship = userprofile.userRelationship;
    for (const key in this.userRelationship) {
      console.log(this.userRelationship)
      if (this.userRelationship.hasOwnProperty(key)) {
          const tenant = this.userRelationship[key];
          console.log("tenant name", tenant.tenantName)
          if (tenant.tenantName && tenant.tenantName === 'global') {
              this.azureTenant = tenant.tenantName;
              break;
          }
      }
  }
}
}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../common.service';
import { TranslateService } from '@ngx-translate/core';
import { OtpappjourneyService } from '../otp-set/otpappjourney.service';

@Component({
  selector: 'app-pwdmail',
  templateUrl: './pwdmail.component.html',
  styleUrls: ['./pwdmail.component.css']
})
export class PwdmailComponent implements OnInit {
  alerts =[];
  emailForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%-\'. \-_!#^~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/), ]),
  });
  constructor(private otpjourneyservice: OtpappjourneyService,private translateService:TranslateService) { }
  mailalert="";
  usernamecallbackresponse;
  ngOnInit(): void {
    this.restartJourney();
  }
  restartJourney()
  {
    this.otpjourneyservice.getusernameCallback().subscribe(data => {
      this.usernamecallbackresponse = data;
    })
  }
  sendLink()
  {
    // const email= this.emailForm.get('email').value;
    const email = this.emailForm.get('email').value!=""?(this.emailForm.get('email').value).toLowerCase():"";
    this.usernamecallbackresponse.callbacks[0].input[0].value=email;
   this.otpjourneyservice.sendPasswordemail(this.usernamecallbackresponse).subscribe(data=>{
    if(data.callbacks[0].type=="TextOutputCallback" && data.callbacks[0].output[0].value==="Password reset email sent successfully")
    {
      this.translateService.get('views.js.alerts.pwdreset.sendLink.msg').subscribe(res => {
        //our alert message 
        this.alerts.push({type: 'info', msg: res + email});
        this.mailalert = res + email; 
      });
    }
   },error=>{
    this.emailForm.get('email').setValue("");
    this.restartJourney();
   })
  }
}

<div class="login-page activate-page">
    <div class="main-container self-service-main-container">
        <div class="form-container self-service-form-container ">
            <div class="form">
                <div class="form-title title">
                    {{"views.public.pwdset.Activate your account"| translate}}
                </div>
                <div class="form-title title">
                    {{error}}
                </div>
                <div class="form-second-description main-text">{{"views.public.pwdset.Get connected in three steps"|
                    translate}}</div>
                <!-- Step 1: Set password -->
                <div class="form-controls controls-mb">
                    <div class="form-controls-container">

                      
                        <form [formGroup]="passwordForm" name="passwordForm" class="form-horizontal">
                              <!-- Step 2: Set up language -->

                              <div class="form-controls form-container-method">
                                <label *ngIf="passwordForm.controls.currentLocaleDisplayName.status=='VALID'"
                                    class="input-form-label label-success">{{"views.public.pwdset.SelectLanguage"|
                                    translate}}</label>
                                    <label *ngIf="passwordForm.controls.currentLocaleDisplayName.status=='DISABLED'"
                                    class="input-form-label label-success">{{"views.public.pwdset.SelectLanguage"|
                                    translate}}</label>
                                    
                                <label *ngIf="passwordForm.controls.currentLocaleDisplayName.status=='INVALID'"
                                    class="input-form-label">{{"views.public.pwdset.SelectLanguage"| translate}}</label>
                                <div class="main-text">
                                    <p>{{"views.public.pwdset.This will be the default option when you log in to your
                                        account going forward."| translate}}</p>
                                </div>
                                <select formControlName="currentLocaleDisplayName" required (change)="changeLanguage()"
                                    class="ng-touched caim-select-field ng-valid ng-not-empty ng-dirty ng-valid-parse input">
                                    <option *ngFor="let language of localesDisplayNames" [ngValue]="language.langCode">
                                        {{language.langValue}}</option>

                                </select>
                            </div>
                            <!-- Password Field-->
                            <div>
                            <label *ngIf="checksuccesslog()"
                            class="input-form-label label-success">{{"views.public.pwdset.Set up your password"|
                            translate}}</label>
                        <label *ngIf="!(checksuccesslog())"
                            class="input-form-label">{{"views.public.pwdset.Set up your password"| translate}}</label>

                            <div class="form-group forms-controls-container" show-errors>
                                <input formControlName="tmpkey" id="password" [type]="hide ? 'password' : 'text'" name="tmpkey"
                                    class="input input-password"
                                    placeholder="{{'views.public.pwdset.Password' | translate}}" (keyup)='check()'>
                                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    
                                    <div class="main-text-error" *ngIf="tmpkeyFlag">
                                        <br>
                                    <span class="">
                                        {{"views.public.pwdset.Make sure your password contains:"| translate}}
                                        <br>
                                        <!-- <ul>
                                            <li ng-if="!passwordContainsTen"> • {{"views.public.pwdset.At least 10 characters"| translate}} </li>
                                            <li style="color: green;" ng-if="passwordContainsTen"> • {{"views.public.pwdset.At least 10 characters"| translate}} </li>
                                        </ul> -->


                                        <!-- {{"views.public.pwdset.and contain characters from at least three of the following four classes:"| translate}} -->
                                        <ul style="margin: 8px!important;">
                                            <!-- <li ng-if="!passwordContainsTen"> •  {{"views.public.pwdset.At least 10 characters"| translate}} </li>
                                            <li ng-if="passwordContainsTen" style="color: green;"> • {{"views.public.pwdset.At least 10 characters"| translate}}</li> -->
                                            <li *ngIf="!passwordContainsTen"> {{"views.public.pwdset.At least 10 characters"| translate}} </li>
                                            <li *ngIf="passwordContainsTen" style="color: green;"> 
                                                {{"views.public.pwdset.At least 10 characters"| translate}}  </li>
                                            <li *ngIf="!passwordContainsUppercase">  {{"views.public.pwdset.Uppercase
                                                letters"| translate}} (A, B, C) </li>
                                            <li *ngIf="passwordContainsUppercase" style="color: green;"> 
                                                {{"views.public.pwdset.Uppercase letters"| translate}} (A, B, C) </li>
                                            <li *ngIf="!passwordContainsLowercase">  {{"views.public.pwdset.Lowercase
                                                letters"| translate}} (a, b, c) </li>
                                            <li *ngIf="passwordContainsLowercase" style="color: green;"> 
                                                {{"views.public.pwdset.Lowercase letters"| translate}} (a, b, c) </li>
                                            <li *ngIf="!passwordContainsNumeral"> {{"views.public.pwdset.Numerals"|
                                                translate}} (0, 1, 2) </li>
                                            <li *ngIf="passwordContainsNumeral" style="color: green;"> 
                                                {{"views.public.pwdset.Numerals"| translate}} (0, 1, 2) </li>
                                            <li *ngIf="!passwordNonAlphaNumeric"> 
                                                {{"views.public.pwdset.Non-alphanumeric"| translate}} (#, &, !, %, @, ?,
                                                -, *) </li>
                                            <li *ngIf="passwordNonAlphaNumeric" style="color: green;"> 
                                                {{"views.public.pwdset.Non-alphanumeric"| translate}} (#, &, !, %, @, ?,
                                                -, *) </li>
                                                <li *ngIf="passwordHasIdenticalPattern"> 
                                                    {{"views.public.pwdset.passwordHasIdenticalPattern"| translate}} (#, &, !, %, @, ?,
                                                    -, *) </li>
                                            <li *ngIf="passwordHasConsecutivePattern"> 
                                                    {{"views.public.pwdset.passwordHasConsecutivePattern"| translate}} (#, &, !, %, @, ?,
                                                    -, *) </li>
                                            <li *ngIf="passwordHasDictionaryWord"> 
                                                    {{"views.public.pwdset.passwordHasDictionaryWord"| translate}} (#, &, !, %, @, ?,
                                                    -, *) </li>
                                        </ul>
                                    </span>
                                </div>
                                <div class="main-text-info">

                                    <p class="" *ngIf="(!pwddoesnotcontainuserattributes)">{{"views.public.pwdset.The
                                        password cannot contain your first name, last name or email address!"|
                                        translate}}</p>

                                    <!-- <p class="help-block" ng-if="(!strongpassword)">{{"views.public.pwdset.The password cannot contain dictionary words"| translate}}</p> -->
                                </div>
                            </div>
                            <div class="form-group forms-controls-container" show-errors>
                                <input formControlName="tmpkey2" id="password2" [type]="hide1 ? 'password' : 'text'" name="tmpkey2"
                                    class="input input-password" required 
                                    placeholder="{{'views.public.pwdset.Repeat password' | translate}}"
                                    (keyup)="check()">
                                    <mat-icon matSuffix (click)="hide1 = !hide1">{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>

                                <div class="main-text-error">
                                    <p class="" *ngIf="(!matching && !passwordForm.controls.tmpkey2.errors?.required)">
                                        {{"views.public.pwdset.The passwords are not equal"| translate}}</p>
                                </div>
                            </div>

                        </div>

                      
                            <!-- Step 3: Set up MFA -->

                            <div class="form-controls configure-method" *ngIf = "azureTenant != 'swiss'">
                                <label
                                    *ngIf="((otpvalidated || validateOTP) && (passwordForm.controls.authenticatorchecked.errors != null))"
                                    class="input-form-label checkbox-form-label label-success">{{"views.public.pwdset.Configure
                                    your MFA method"| translate}}</label>
                                <label
                                    *ngIf="!((otpvalidated || validateOTP) && (passwordForm.controls.authenticatorchecked.errors != null))"
                                    class="input-form-label checkbox-form-label">{{"views.public.pwdset.Configure your
                                    MFA method"| translate}}</label>
                                <div class="main-text">
                                    <p>{{"views.public.pwdset.You can set up one or more MFA methods."| translate}}</p>
                                </div>
                                <!-- Authenticator -->

                                <div class="form-controls">
                                <input type="radio" formControlName = "authenticatorchecked" 
                                name="authenticatorchecked"
                                checked = "mfaSelected" 
                                (click)="optionsChanged();mfaChecked($event)"><span><b>{{"views.public.pwdset.Authenticator app"| translate}}</b></span>
                                </div>

                                <!-- MFA Authenticator -->
                                <div class="authenticator-container" *ngIf="mfaSelected">
                                    <div class="form-group">
                                        <!-- Show the authenticator container if the OTP was not validated yet-->
                                        <div class="authenticator-container" *ngIf="!(otpvalidated)">
                                            <p style="color: red;">{{"views.public.pwdset.Configure your Authenticator
                                                app in three quick steps."| translate}}</p>
                                            <!--Step 1 -->
                                            <div class="authenticator-text-step">
                                                <b><span class="authenticator-text-step-label">{{"views.public.pwdset.Step
                                                        1"| translate}}</span></b>
                                                <p class="authenticator-text-step-description">
                                                    {{"views.public.pwdset.Download and Install"| translate}}</p>
                                            </div>
                                            <!--Step 2-->
                                            <div class="authenticator-text-step">
                                                <b><span class="authenticator-text-step-label">{{"views.public.pwdset.Step
                                                        2"| translate}}</span></b>
                                                <p class="authenticator-text-step-description">
                                                    {{"views.public.pwdset.ScanQR Code"| translate}}</p>
                                                <div class="qr-container" *ngIf="qrcodeurl">
                                                    <img style="filter: grayscale(100%); height:100px"
                                                        src="{{qrcodeurl}}" alt="qrcode" />
                                                    <span class="qr-code">{{qrcodeValue}}</span>
                                                </div>
                                            </div>
                                            <!--Step 3-->
                                            <div class="authenticator-text-step">
                                                <b><span class="authenticator-text-step-label">{{"views.public.pwdset.Step
                                                        3"| translate}}</span></b>
                                                <p class="authenticator-text-step-description">
                                                    {{"views.public.pwdset.Enter the one time password"| translate}}</p>
                                                <form [formGroup]="validateOtpForm">
                                                    <div class="confirm-form">
                                                        <input formControlName="otp" type="password" autocomplete="off"
                                                            name="codevalidation" class="input"
                                                            placeholder="{{'views.public.pwdset.Code'| translate}}"
                                                            required>
                                                        <button class="confirm-btn"
                                                            (click)="verifycode(validateOtpForm.value.otp)">{{"views.public.pwdset.Validate
                                                            OTP"| translate}}</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <p class="alert" [ngClass]="[(otpvalidated)? 'alert-success':'alert-danger']"
                                            *ngIf="showAlert">{{otpalert}}</p>
                                        <!-- Show if OTP was valid- -->
                                        <div *ngIf="otpvalidated" class="mobile-method-container">
                                            <div class="confirm-form confirm-form-send">
                                                <span class="send-password-text">{{"views.public.pwdset.OTPValid"|
                                                    translate}}</span>
                                            </div>
                                        </div>
                                        <!-- <br /> -->
                                        <!-- Show if OTP was not valid  -->
                                        <!-- <div *ngIf="otpalert !== null && otpalert !== '' && otpalert.length > 0 && !otpvalidated" class="mobile-method-container">
                                                        <div class="confirm-form confirm-form-send">
                                                            <span class="main-text-error">{{otpalert}}</span>
                                                        </div>
                                                    </div> -->
                                    </div>

                                </div>
                                <br />
                                <!-- Mobile number SMS -->
                                <div class="form-controls">
                                    <input type="radio" formControlName = "mobilesmscheckbox" name="mobilesmscheckbox"
                                     checked = "mobileNumberSelected" 
                                     (click)="optionsChanged();mobileCheckboxClick($event)">
                                     <span><b>{{"views.public.pwdset.Mobile phone SMS/Phone Call"| translate}}</b></span>
                                </div>

                                <div class="mobile-method-container" *ngIf="mobileNumberSelected">
                                  
                                    <form [formGroup]="phoneForm" name="phoneForm" class="form-horizontal" role="form"
                                        autocomplete="off" *ngIf="!validateMobilOTP && !mobilephonecallchecked">
                                        <span class="hint-text call-send-text">{{"views.public.pwdset.Please enter your
                                            phone number to be registered."| translate}}</span>
                                        <div class="confirm-form phone-call-form" style="display: block;">
                                            <ngx-intl-tel-input [preferredCountries]="preferredCountries"
                                                [cssClass]="'tel-input'" [enableAutoCountrySelect]="true"
                                                [enablePlaceholder]="true" [searchCountryFlag]="true"
                                                [searchCountryField]="[SearchCountryField.Iso2, 
                              SearchCountryField.Name]" [selectFirstCountry]="false"
                                                [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="15"
                                                [phoneValidation]="true" [separateDialCode]="true" name="mobile"
                                                formControlName="mobile">

                                            </ngx-intl-tel-input>

                                            <!-- onclick="document.getElementsByName('preferredMFAForm')[0].reset();"> -->
                                        </div>
                                        <span class="hint-text call-send-text">{{"views.public.pwdset.Please select one
                                            option - SMS OR Phone call"| translate}}</span>
                                        <div class="confirm-form phone-call-form">
                                            <button name="mobilebtn"
                                                class="btn-form btn-white login-btn credentials-btn"
                                                (click)="sendOTP('sms', phoneForm.value.mobile)"
                                                [disabled]="checkMobile(phoneForm,mobile)">{{"views.public.pwdset.SMS" |
                                                translate}}</button>
                                            <button name="mobilebtn"
                                                class="btn-form btn-white login-btn credentials-btn"
                                                (click)="sendOTP('voice', phoneForm.value.mobile)"
                                                [disabled]="checkMobile(phoneForm,mobile)">{{"views.public.pwdset.Mobile
                                                phone" | translate}}</button>
                                        </div>
                                        <span class="hint-text main-text-error">{{otpErrorMsg}}</span>
                                        <div class="main-text-error confirm-form send-call-form"  *ngIf="(checkMobile() &&  phoneForm.controls.mobile.touched)">
                                            <span
                                                class="hint-text call-send-text">{{"views.public.pwdset.PhoneValidation"|
                                                translate}}</span>
                                            <div
                                                *ngIf="phoneForm.controls['mobile'].valid && (validateMobilOTP || mobilephonecallchecked)">
                                                <span class="hint-text call-send-text">{{userrequest.mobile}}
                                                    {{"views.public.pwdset.is now set as your MFA method"| translate}}
                                                </span>
                                            </div>
                                        </div>
                                    </form>
                                    <form name="telephoneForm" class="form-horizontal" role="form"
                                        [formGroup]="telephoneOtpForm" *ngIf="showOTPSection">
                                        <div class="form-group">
                                            <!-- Show the authenticator container if the OTP was not validated yet-->
                                            <div class="authenticator-container"
                                                *ngIf="!validateMobilOTP && !mobilephonecallchecked">
                                                <!--Step 3-->
                                                <div class="authenticator-text-step">

                                                    <p class="authenticator-text-step-description">
                                                        {{"views.public.pwdset.Enter the one time password mobile"|
                                                        translate}}</p>
                                                    <div class="confirm-form">
                                                        <input formControlName="otp" type="password" autocomplete="off"
                                                            name="otpvalidation" class="input"
                                                            placeholder="{{'views.public.pwdset.Code'| translate}}"
                                                            required>
                                                        <button class="confirm-btn"
                                                            (click)="verifyOTP(phoneForm.value.mobile,telephoneOtpForm.value.otp)">{{"views.public.pwdset.Validate
                                                            OTP"| translate}}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--Show if OTP was valid-->
                                            <!-- Show if OTP was not valid -->
                                            <div *ngIf="twiliootpalert !== null && twiliootpalert !== ''  && !validateOTP" class="mobile-method-container">
                                                <div class="confirm-form confirm-form-send">
                                                    <span class="main-text-error">{{twiliootpalert}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div *ngIf="validateMobilOTP || mobilephonecallchecked"
                                        class="mobile-method-container">
                                        <div class="confirm-form confirm-form-send">
                                            <span class="send-password-text">{{"views.public.pwdset.OTPValid.mobile"|
                                                translate}}</span>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                                <br />
                                <!-- Email -->
                                <div class="form-controls" *ngIf="emailOtp">
                                    <input type="radio" formControlName = "emailcheckbox" 
                                    name="emailcheckbox"
                                    checked = "emailSelected" 
                                    (click)="optionsChanged();emailClicked($event)"><span><b>{{"views.public.otpmail.Email"| translate}}</b></span>
                                </div>

                                <!-- Step 3.5: Choose preferred MFA method -->
                                <div class="mobile-primary-method">
                                    <span class="mobile-primary-method-label"><b>{{"views.public.pwdset.Select a primary
                                            MFA method"| translate}}</b><br></span>
                                    <div class="main-text">
                                        <p>{{"views.public.pwdset.This will be the primary option when you log in to
                                            your account going forward."| translate}}</p>
                                    </div>
                                    <div class="mobile-primary-method-container">
                                        <form name="preferredMFAForm" class="form-horizontal" role="form">
                                            <label
                                                class="label-container mobile-primary-method-radio mobile-primary-method-radio-first"
                                                *ngIf="otpvalidated">
                                                <input type="radio" name="radio"
                                                    (click)="userPreferredMFA = 'AuthenticatorApp'"
                                                    value="AuthenticatorApp">
                                                <span class="label-text">{{"views.public.pwdset.Authenticator app"|
                                                    translate}}</span>
                                                <span class="checkmark"></span>
                                            </label>
                                            <label class="label-container mobile-primary-method-radio"
                                                *ngIf="validateMobilOTP">
                                                <input type="radio" name="radio" value="sms"
                                                    (click)="userPreferredMFA = 'sms'">
                                                <span class="label-text">{{"views.public.pwdset.Mobile phone (SMS)" |
                                                    translate}}</span>
                                                <span class="checkmark"></span>
                                            </label>
                                            <label class="label-container mobile-primary-method-radio"
                                                *ngIf="validateMobilOTP">
                                                <input type="radio" name="radio" value="voice"
                                                    (click)="userPreferredMFA = 'voice'">
                                                <span class="label-text">{{"views.public.pwdset.Mobile phone (Phone
                                                    call)" | translate}}</span>
                                                <span class="checkmark"></span>
                                            </label>
                                            <label class="label-container mobile-primary-method-radio"
                                                *ngIf="emailOtp && emailSelected">
                                                <input type="radio" name="radio" value="email"
                                                    (click)="userPreferredMFA = 'email'">
                                                <span class="label-text">{{"views.public.otpmail.Email" |
                                                    translate}}</span>
                                                <span class="checkmark"></span>
                                            </label>
                                        </form>
                                    </div>
                                </div>
                                <br />
                                <div  *ngIf = "azureTenant != 'swiss'">
                                    <span><b>{{"views.public.pwdSet.Are you from Mainland China?"|
                                            translate}}</b></span>
                                    <input type="radio" name="chinaSelector" value="yes" (click)="mainlandChina = 'yes'"
                                        [checked]="mainlandChina === 'yes'">Yes
                                    <input type="radio" name="chinaSelector" value="no"
                                        (click)="mainlandChina = 'no';privacyStatementForm.reset()"
                                        [checked]="mainlandChina === 'no'">No
                                </div>

                                <!-- <label class="container-checkbox" ng-if="mainlandChina == 'yes'">
                        <span class="checkbox-label">Accept <a href="/dis/public/#/privacynotice" target="_blank" class="link-info link">Privacy statement</a></span>
                        <input type="checkbox" ng-model="acceptedPrivacyStatement">
                        <span class="checkmark-checkbox"></span>
                    </label> -->
                                <div *ngIf="mainlandChina === 'yes' && azureTenant != 'swiss'">
                                    <form [formGroup]="privacyStatementForm">
                                        <input type="checkbox" formControlName="privacy">
                                        <span>我已阅读、理解并接受<a href="/dis/public/privacynotice?country=China-ZH"
                                                target="_blank" class="link-info link"> Privacy statement</a></span>
                                    </form>
                                </div>

                            </div>
                        </form>
                        <!-- <span class="invalid-text">{{'views.public.pwdset.Password invalid' | translate}}</span>
                        <button class="btn-unmask"></button> -->
                    </div>
                </div>
                <span><b>{{"views.public.pwdset.Please ensure all fields are filled in correctly. Only then the COMPLETE
                        button will be enabled."| translate}}</b></span>
                <button type="button" class="btn-form btn-black login-btn complete-btn"
                    [disabled]="checkDisabledComplete(mainlandChina)"
                    (click)="pwdSet(userPreferredMFA)">
                    {{"views.public.pwdset.Complete registration"| translate}}
                </button>
            </div>
        </div>
        <app-terms-conditions></app-terms-conditions>
    </div>
</div>
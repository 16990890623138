<div id="helpdesk" *ngIf="authorized()">

    <ol class="breadcrumb breadcrumb-dis">
        <li><a href="" (click)="gotoHomePage()">{{'views.contract-detail.Home'| translate}}</a></li>
        <li class="active">{{"views.helpdesk.Help Desk"| translate}}</li>
        <li class="logout"><a href="./oidc/logout">{{"views.home.Log out"| translate}}</a></li>
    </ol>

    <h1>{{'views.helpdesk.Help Desk'| translate}}</h1>
    <!--The alerts come here <alert ng-repeat="alert in alerts" type="{{alert.type}}" close="closeAlert($index)" class="">{{alert.msg}}</alert>-->
    <div *ngFor = "let alert of alerts">
        <p class="alert " [ngClass]="{'alert-danger' : alert.type=='danger','alert-info' : alert.type=='info'}">
            {{alert.msg}}
            <i  (click)="closeAlert(i)" aria-hidden="true" class="fa fa-times" style="
            float:right;
   font-size: 22px; "></i>
        </p>
    </div>
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="subtitleicon">                          
                <img src="assets/images/homepage/helpdesk.png" alt="helpdesk" width="30px"/>
            </div><span class="">{{"views.helpdesk.Help Desk"| translate}}</span>
        </div>
        <div class="panel-body">
            <form [formGroup]="emailForm"  name="emailForm" class="form-horizontal" role="form">
                <div class="form-group" >
                    <label class="col-sm-2 control-label " style="text-align: left;">{{"views.helpdesk.User email"| translate}}</label>
                    <div class="col-sm-4">
                        <span class="input-group">
                            <span class="input-group-addon">&#64;</span>
                            <input type="text" name="email" class="form-control" (change)="test()" formControlName="email" required/>
                        </span>
                    </div>
                    <div class="col-sm-3">
                        <div class="btn-group">
                            <button type="button"
                                    class="btn btn-default"
                                    [attr.disabled]="emailForm.status === 'INVALID'? '':null"
                                    (click)="retrieveLDSAttributes()"
                                    >
                                <span class="glyphicon glyphicon glyphicon-list-alt" style="margin-right: 10px">
                                </span>{{"views.helpdesk.Retrieve User Information"| translate}}
                            </button>
                        </div>
                    </div>
                </div>
				<div class="btn-group btn-group-justified" *ngIf="user?.userType == 'external'">
                    <div class="btn-group savebutton" *ngIf="user.masterSource !== 'AD'">
                            <button type="button"
                                    class="btn btn-default"
                                    [attr.disabled]="emailForm.status === 'INVALID'? '':null"
                                    (click)="resetPassword(email)"
                                    >
                                <span class="glyphicon glyphicon-lock" style="margin-right: 10px">
                                </span>{{"views.user.Send reset password link"| translate}}
                            </button>
                        </div>
                        <div class="btn-group savebutton" *ngIf="tenantValue != 'swiss' ">
                            <button type="button"
                                    class="btn btn-default"
                                    [attr.disabled]="emailForm.status === 'INVALID'? '':null"
                                    (click)="registerOtp(email)"
                                    >
                                <span class="glyphicon glyphicon-phone" style="margin-right: 10px">
                                </span>{{"views.user.Send register OTP app link"| translate}}
                            </button>
                        </div>
                    </div>
            </form>
            <br><br/>
            <accordion>
                <div id="user">
                    <accordion-group [isOpen]="open"  is-disabled="false">
                        <div accordion-heading  style="width: 100%;">  
                            <div style="width: 50px; float: left"> 
                                <img src="assets/images/homepage/history.png" alt="user history" width="30px" />
                            </div>
                            {{'views.user.User details' | translate}}
                        </div>
                        <form name="userrequestForm" [formGroup]="userrequestForm" class="form-horizontal" role="form">
                            <div class="row ">
                                <div class="form-group">
                                    <label for="user.status" class="col-sm-2 control-label" style="text-align: left;">{{'views.user.Status' | translate}}</label>
                                    <div class="col-sm-5">
                                        <img style="width: 25px" *ngIf="user?.status == 'suspended'" src="assets/images/icons/bullet_red.png"
                                            alt="{{'views.user.suspended' | translate}}"
                                            title="{{'views.user.suspended' | translate}}" />
                                        <img style="width: 25px" *ngIf="user?.status == 'active'" src="assets/images/icons/bullet_green.png"
                                            alt="{{'views.user.active' | translate}}" title="{{'views.user.active' | translate}}" />
                                    </div>
                                </div>
                
                                <div class="form-group">
                                    <label for="user.userType"
                                        class="col-sm-2 control-label" style="text-align: left;">{{'views.usertype.User type' | translate}}</label>
                                    <div class="col-sm-5">
                                        <input type="text" class="form-control" id="user.userType" name="userType"
                                            *ngIf="user?.userType === 'external' " disabled value="{{'views.usertype.External' | translate}}" />
                                        <input type="text" class="form-control" id="user.userType" name="userType"
                                            *ngIf="user?.userType === 'internal' " disabled value="{{'views.usertype.Internal' | translate}}" />
                                        <input type="text" class="form-control" id="user.userType" name="userType"
                                            *ngIf="user?.userType === 'clientidp' " disabled value="{{'views.usertype.Client IDP' | translate}}" />
                                    </div>
                                </div>
                
                                <div class="form-group">
                                    <label for="user.firstname"
                                        class="col-sm-2 control-label"style="text-align: left;">{{'views.user.First name' | translate}}</label>
                                    <div class="col-sm-7">
                                        <span class="input-group">
                                            <span class="input-group-addon"><span class="glyphicon glyphicon-user"></span></span>
                                            <input type="text" class="form-control" id="user.firstname" name="firstname"
                                            formControlName="firstname" required disabled />
                                        </span>
                                    </div>
                
                
                                </div>
                
                                <div class="form-group">
                                    <label for="user.lastname"
                                        class="col-sm-2 control-label " style="text-align: left;">{{'views.user.Last name' | translate}}</label>
                                    <div class="col-sm-7">
                                        <span class="input-group">
                                            <span class="input-group-addon"><span class="glyphicon glyphicon-user"></span></span>
                                            <input type="text" class="form-control" id="user.lastname" name="lastname"
                                            formControlName="lastname" required disabled />
                                        </span>
                                    </div>
                
                                </div>
                                <div class="form-group">
                                    <label for="user.email" class="col-sm-2 control-label"  style="text-align: left;">{{'views.user.Email' | translate}}</label>
                                    <div class="col-sm-7">
                                        <span class="input-group">
                                            <span class="input-group-addon">&#64;</span>
                                            <input type="text" class="form-control" name="email" id="user.email" formControlName="email"
                                                required 
                                                disabled/>
                                        </span>
                                    </div>
                
                                </div>
                                <div class="form-group" *ngIf="!(user?.userType === 'internal' || user?.userType === 'clientidp' || user?.userType ==='swiss')">
                                    <label for="user.mobile" class="col-sm-2 control-label " style="text-align: left;">{{'views.user.Mobile' | translate}}</label>
                                    <div class="col-sm-7">
                                        <span class="input-group">
                                            <span class="input-group-addon"><span class="glyphicon glyphicon-phone"></span></span>
                                            <input type="text" class="form-control" id="user.mobile"
                                                name="mobile" formControlName="mobile"
                                                placeholder="{{'views.userrequest.Format: +11234567890' | translate}}" disabled />
                                        </span>
                                    </div>
                
                                </div>
                                <div class="form-group">
                                    <label for="user.mainLanguage"
                                        class="col-sm-2 control-label " style="text-align: left;">{{'views.user.Main language' | translate}}</label>
                                    <div class="col-sm-7">
                                        <span class="input-group">
                                            <span class="input-group-addon"><span class="glyphicon glyphicon-globe"></span></span>
                                            <input type="text" class="form-control" id="user.mainLanguage" name="mainLanguage"
                                            formControlName="mainLanguage" required disabled />
                                            
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </accordion-group>
                </div>

                <div id="user history">
                    <accordion-group  is-disabled="false">

                        <div accordion-heading  style="width: 100%;">  
                            <div style="width: 50px; float: left">                          
                                <img src="assets/images/homepage/history.png" alt="user history" width="30px"/>
                            </div> {{'views.user.User history' | translate}}
                        </div>
                        <span class="" *ngIf="historycount<1">
                            {{'views.user.The user has no history.' | translate}} <br/>
                        </span>
                        <div *ngIf="historycount >= 1" style="    overflow: scroll;
                            height: 204px;">
                        <table  mat-table [dataSource]="userHistorydataSource" matSort>
                            <ng-container matColumnDef="email">
                                <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> {{'views.user.Email'|translate}}</th>
                                <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.email}} </td>
                              </ng-container>
                              <ng-container matColumnDef="createdOn">
                                <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header>{{'views.user.created_on' | translate}}</th>
                                <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.createdOn | date:'MM/dd/yyyy hh:mm:ss a'}} </td>
                              </ng-container>
                              <ng-container matColumnDef="modifiedOn">
                                <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> {{'views.user.modified_on' | translate}}</th>
                                <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.modifiedOn | date:'MM/dd/yyyy hh:mm:ss a'}} </td>
                              </ng-container>
                              <ng-container matColumnDef="modifiedBy">
                                <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> {{'views.user.modified_by' | translate}}</th>
                                <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.modifiedBy}} </td>
                              </ng-container>
                              <ng-container matColumnDef="changedField">
                                <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> Changed Field</th>
                                <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.changedField}} </td>
                              </ng-container>
                              <ng-container matColumnDef="initialValue">
                                <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> Initial Value</th>
                                <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.initialValue}} </td>
                              </ng-container>
                              <ng-container matColumnDef="changedValue">
                                <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> Changed Value</th>
                                <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.changedValue}} </td>
                              </ng-container>
                            <tr mat-header-row *matHeaderRowDef="historydisplayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: historydisplayedColumns;"></tr>

                        </table>
                        
                        <mat-paginator #paginatoruser="matPaginator" [pageSizeOptions]="[5, 10, 25, 100]" [length]="historycount" [pageSize]="10"
        (page)="getPageDetails($event)"></mat-paginator>
                    </div>
                    </accordion-group>
                </div>
                <div id="organization history">
                    <accordion-group is-open="false" is-disabled="false">

                        <div accordion-heading  style="width: 100%;">  
                            <div style="width: 50px; float: left">                          
                                <img src="assets/images/homepage/history.png" alt="email history" width="30px"/>
                            </div> {{'views.user.Organization history' | translate}} 
                        </div>
                        <span class="" *ngIf="organizationhistorycount<1">
                            {{'views.user.The user has no organization history.' | translate}} <br/>
                        </span>
                        <div *ngIf="organizationhistorycount >= 1" style="    overflow: scroll;
                            height: 204px;">
                            <table  mat-table [dataSource]="orgHistorydataSource" matSort>
                                
                                <ng-container matColumnDef="organization">
                                    <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> {{'views.user.Organization'|translate}}</th>
                                    <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.organization}} </td>
                                  </ng-container>
                                  <ng-container matColumnDef="createdOn">
                                    <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header>{{'views.user.created_on' | translate}}</th>
                                    <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.createdOn | date:'MM/dd/yyyy hh:mm:ss a'}} </td>
                                  </ng-container>
                                  <ng-container matColumnDef="modifiedOn">
                                    <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> {{'views.user.modified_on' | translate}}</th>
                                    <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.modifiedOn | date:'MM/dd/yyyy hh:mm:ss a'}} </td>
                                  </ng-container>
                                  <ng-container matColumnDef="modifiedBy">
                                    <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> {{'views.user.modified_by' | translate}}</th>
                                    <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.modifiedBy}} </td>
                                  </ng-container>
                                  <ng-container matColumnDef="changedField">
                                    <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> Changed Field</th>
                                    <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.changedField}} </td>
                                  </ng-container>
                                  <ng-container matColumnDef="initialValue">
                                    <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> Initial Value</th>
                                    <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.initialValue}} </td>
                                  </ng-container>
                                  <ng-container matColumnDef="changedValue">
                                    <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> Changed Value</th>
                                    <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.changedValue}} </td>
                                  </ng-container>
                                <tr mat-header-row *matHeaderRowDef="orgdisplayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: orgdisplayedColumns;"></tr>
    
                            </table>
                            
                            <mat-paginator #paginatororg="matPaginator" [pageSizeOptions]="[5, 10, 25, 100]" [length]="organizationhistorycount" [pageSize]="10"
            (page)="getPageDetails($event)"></mat-paginator>
                        </div>
                    </accordion-group>
                </div>
                <div id="roleassignment history">
                    <accordion-group is-open="false" is-disabled="false">

                        <div accordion-heading  style="width: 100%;">  
                            <div style="width: 50px; float: left">                          
                                <img src="assets/images/homepage/roles.png" alt="{{'views.user.Role assignments' | translate}}" width="30px"/>
                            </div> {{'views.user.Role assignments' | translate}} 
                        </div>
                        <span class="" *ngIf="userroleshistorycount<1">
                            {{'views.user.The user has no role assignments.' | translate}} <br/>
                        </span>
                        <div *ngIf="userroleshistorycount >= 1" style="    overflow: scroll;
                            height: 204px;">
                            <table  mat-table [dataSource]="roleuserHistorydataSource" matSort >
                                
                                <ng-container matColumnDef="role">
                                    <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> {{'views.user.Role'|translate}}</th>
                                    <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.role}} </td>
                                  </ng-container>
                                  <ng-container matColumnDef="createdOn">
                                    <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header>{{'views.user.created_on' | translate}}</th>
                                    <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.createdOn | date:'MM/dd/yyyy hh:mm:ss a'}} </td>
                                  </ng-container>
                                  <ng-container matColumnDef="modifiedOn">
                                    <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> {{'views.user.modified_on' | translate}}</th>
                                    <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.modifiedOn | date:'MM/dd/yyyy hh:mm:ss a'}} </td>
                                  </ng-container>
                                  <ng-container matColumnDef="modifiedBy">
                                    <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> {{'views.user.modified_by' | translate}}</th>
                                    <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.modifiedBy}} </td>
                                  </ng-container>
                                  <ng-container matColumnDef="changedField">
                                    <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> Changed Field</th>
                                    <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.changedField}} </td>
                                  </ng-container>
                                  <ng-container matColumnDef="initialValue">
                                    <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> Initial Value</th>
                                    <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.initialValue}} </td>
                                  </ng-container>
                                  <ng-container matColumnDef="changedValue">
                                    <th class="user-history-accordion accordion-header" mat-header-cell *matHeaderCellDef mat-sort-header> Changed Value</th>
                                    <td class="accordion-cell" mat-cell *matCellDef="let row"> {{row.changedValue}} </td>
                                  </ng-container>
                                <tr mat-header-row *matHeaderRowDef="roledisplayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: roledisplayedColumns;"></tr>
    
                            </table>
                            
                            <mat-paginator #paginatorroles="matPaginator" [pageSizeOptions]="[5, 10, 25, 100]" [length]="userroleshistorycount" [pageSize]="10"
            (page)="getPageDetails($event)"></mat-paginator>
                        </div>
                    </accordion-group>
                </div>
                
                                            <!-- for testing
                            <table mat-table [dataSource]="userRolesHistoryData" class="mat-elevation-z8">
                                <ng-container matColumnDef="historyTs">
                                  <th mat-header-cell *matHeaderCellDef> Timestamp </th>
                                  <td mat-cell *matCellDef="let user">{{ user.historyTs | date:'medium' }}</td>
                                </ng-container>
                                <ng-container matColumnDef="status">
                                  <th mat-header-cell *matHeaderCellDef> Status </th>
                                  <td mat-cell *matCellDef="let user">{{ user.status }}</td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let user; columns: displayedColumns"></tr>
                            </table>
                        -->
            </accordion>
        </div>
    </div>
    <div style="display: flex;margin-top: 50%;">
        <app-terms-conditions></app-terms-conditions>
    </div>
</div>

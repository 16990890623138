<div id="serviceNew" *ngIf="authorized()">

    <ol class="breadcrumb breadcrumb-dis">
        <li><a [routerLink]="['']">{{"views.contract-detail.Home"| translate}}</a></li>
        <li><a [routerLink]="['/services/list']">{{'views.service.Service list' | translate }}</a></li>
        <li class="active">{{'views.service.New service' | translate }}</li>
        <li class="logout"><a href="./oidc/logout">{{'views.service.Log out' | translate }}</a></li>
    </ol>

    <div class="titleicon"><img src="assets/images/homepage/services.png" alt="service management"/></div>
    <h1>{{'views.service.New service' | translate }}</h1>

    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="panel-body">
            <form [formGroup]="newservicerequestForm" name="serviceForm" class="form-horizontal" role="form">
                <div class="form-group" show-errors>
                    <label for="service.name" class="col-sm-2 control-label " style="width:25% !important">{{'views.service.Name' | translate }}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="service.name" name="name" formControlName="name" required/>
                    </div>
                    <div class="col-sm-1" *ngIf="newservicerequestForm.controls.name.errors!=null">
                        <p class="error-block" *ngIf="newservicerequestForm.controls.name.errors?.required== true &&newservicerequestForm.controls.name?.touched">
                            {{'views.userrequest.Required' | translate}}</p>
                        <p class="error-block" *ngIf="!(newservicerequestForm.controls.name.errors?.required) && newservicerequestForm.controls.name.status == 'INVALID' &&newservicerequestForm.controls.name?.touched">{{'views.service.Invalid Input'
                            | translate}}</p>

                    </div>
                </div>
                <div class="form-group" show-errors>
                    <label for="service.technicalId" class="col-sm-2 control-label " style="width:25% !important">{{'views.service.Technical ID' | translate }}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control"
                               id="service.technicalId" name="technicalId" 
                               formControlName="technicalId"
                               style="text-transform:uppercase;"
                               oninput="this.value = this.value.toUpperCase()"
                                required
                                (change)="checkUniquetechnicalidValue()"
                               />
                    </div>
                    <div class="col-sm-2" *ngIf="newservicerequestForm.controls.technicalId.errors!=null">
                        <p class="error-block" *ngIf="newservicerequestForm.controls.technicalId.errors?.required== true &&newservicerequestForm.controls.technicalId?.touched">
                            {{'views.userrequest.Required' | translate}}</p>
                            <p class="error-block" *ngIf="newservicerequestForm.controls['technicalId'].hasError('pattern') && newservicerequestForm.controls.technicalId?.touched">{{'views.service.The technical ID can only contain alphanumeric values.' | translate }}</p>
                        </div>
                        <div class="col-sm-2">
                        <p class="error-block" *ngIf="uniqueValue">{{'views.service.The technical ID already exists.' | translate }}</p>
                    </div>
                </div>
                <div class="form-group" show-errors>

                    <label for="service.description" class="col-sm-2 control-label " style="width:25% !important">{{'views.service.Description' | translate }}</label>

                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="service.description" name="description" formControlName="description"/>
                    </div>
                </div>
                <div class="form-group" show-errors>

                    <label for="service.url" class="col-sm-2 control-label " style="width:25% !important">{{'views.service.URL' | translate }}</label>
                    <div class="col-sm-7">
                       <!-- <input type="text" class="form-control" id="service.url" name="url" formControlName="service.url" required
                        ng-pattngPatternern="/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?|^((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/.*)?$/"/> -->
                        <input type="text" class="form-control" id="service.url" name="url" formControlName="url" required
                       />
                    </div>
                    <div class="col-sm-2">
                        <p class="error-block" *ngIf="newservicerequestForm.controls.url.errors?.required== true &&newservicerequestForm.controls.url?.touched">
                            {{'views.service.Required' | translate}}</p>
                    </div>
                    <div class="col-sm-2">
                        <p class="error-block" *ngIf="newservicerequestForm.controls['url'].hasError('pattern') && newservicerequestForm.controls.url?.touched">{{'views.service.The URL is invalid' | translate }}</p>

                    </div>
                </div>
                <div class="form-group" show-errors>
                    <label for="service.uuid" class="col-sm-2 control-label " style="width:25% !important">{{'views.service.UUID' | translate }}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control"
                               id="service.uuid" name="uuid"
                               formControlName="uuId"
                               (change)="checkUUID()"
                               required
                               />
                    </div>
                    <div class="col-sm-2">
                        <p class="error-block" *ngIf="newservicerequestForm.controls.uuId.errors?.required== true &&newservicerequestForm.controls.uuid?.touched">
                            {{'views.service.Required' | translate}}</p>        
                                    </div>
                    <div class="col-sm-2">
                        <p class="error-block" *ngIf="newservicerequestForm.controls['uuId'].hasError('pattern') && newservicerequestForm.controls.uuId?.touched">{{'views.service.The UUID is invalid.' | translate }}</p>

                        <p class="error-block" *ngIf="uniqueValueuuid && ! newservicerequestForm.controls['uuId'].hasError('pattern') ">{{'views.service.The UUID already exists.' | translate}}</p>
                     
                    </div>
                </div>
                <!--App ID start -->
                <div class="form-group" show-errors>
                    <label for="service.appid" class="col-sm-2 control-label " style="width:25% !important">{{'views.service.AppId' | translate }}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control"
                               id="service.appid" name="appid"
                               formControlName="appId"
                               (change)="checkAppID()"
                               required
                               />
                    </div>
                    <div class="col-sm-2">
                        <p class="error-block" *ngIf="newservicerequestForm.controls.appId.errors?.required== true &&newservicerequestForm.controls.appId?.touched">
                            {{'views.service.Required' | translate}}</p>                     </div>
                    <div class="col-sm-2">
                        <p class="error-block" *ngIf="newservicerequestForm.controls['appId'].hasError('pattern') && newservicerequestForm.controls.appId?.touched">{{'views.service.The AppID is invalid.' | translate }}</p>

                        <p class="error-block" *ngIf="uniqueValueappid && ! newservicerequestForm.controls['appId'].hasError('pattern') ">{{'views.service.The AppID already exists.' | translate }}</p>
                    
                    </div>
                </div>
                <!--App ID end -->
                <div class="form-group" show-errors>

                    <label for="service.appOwner" class="col-sm-2 control-label " style="width:25% !important">{{'views.service.App Owner' | translate }}</label>

                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="service.appOwner" name="appOwner" formControlName="appOwner" />
                    </div>
                    <div class="col-sm-2">
                        <p class="error-block" *ngIf="newservicerequestForm.controls['appOwner'].hasError('pattern') && newservicerequestForm.controls.appOwner?.touched">{{'views.service.The email address is invalid' | translate }}</p>

                    </div>
                </div>
                
                <div class="form-group" show-errors>

                    <label for="service.fss" class="col-sm-2 control-label " style="width:25% !important">{{'views.service.FSS' | translate }}</label>

                    <div class="col-sm-7">
                    
                        <select formControlName="fss"
                        required
                        class="caim-select-field ng-valid ng-not-empty ng-dirty ng-valid-parse input">
                        <option [ngValue]="null" selected>-Select-</option>
                        <option  *ngFor="let sta of fsss" [ngValue]="sta">{{sta}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group" show-errors>

                    <label for="service.memberFirmHA" class="col-sm-2 control-label " style="width:25% !important">{{'views.service.Member firm hosting the app' | translate }}</label>

                    <div class="col-sm-7">
                        <select formControlName="memberFirmHA"
                        required
                        class=" caim-select-field ng-valid ng-not-empty ng-dirty ng-valid-parse input">
                        <option [ngValue]="null" selected>-Select-</option>
                        <option *ngFor='let memberFirmHAValue of memberFirmHAs' [value]="memberFirmHAValue">{{memberFirmHAValue}}

                        </option>
                        </select>
                    </div>
                </div>
                <div class="form-group" show-errors>

                    <label for="service.onboardedDate" class="col-sm-2 control-label " style="width:25% !important">{{'views.service.Onboarded Date' | translate }}</label>

                    <div class="col-sm-7">
                        <p class="input-group" style="width: 100%;">
                           
                            <input id="service.onboardedDate" name="onboardedDate" type="date" class="form-control" 
                                formControlName="onboardedDate" 
                                    style="text-indent: 0px" 
                                placeholder="dd//yyyy"/>
                           
                        </p>
                    </div>
                </div>
                <div class="form-group" show-errors>
                    <label for="service.contactEmail" class="col-sm-2 control-label " style="width:25% !important">{{'views.service.Contact email' | translate }}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="service.contactEmail" name="contactEmail" formControlName="contactEmail" />
                    </div>
                    <div class="col-sm-2">
                        <p class="error-block" *ngIf="newservicerequestForm.controls['contactEmail'].hasError('pattern') && newservicerequestForm.controls.contactEmail?.touched">{{'views.service.The email address is invalid' | translate }}</p>
                    </div>
                </div>
  
                <div class="col-sm-2"></div>
                <div class="col-sm-7" style="width:106% !important; margin :14px -393px;">
                    <div class="btn-group savebutton">
                        <button type="button"
                                class="btn btn-default"
                                (click)="submit()"
                                [attr.disabled]="newservicerequestForm.status === 'INVALID'? '':null">
                            <span class="glyphicon glyphicon-floppy-disk" style="margin-right: 10px"></span>
                            {{'views.service.Save' | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>



</div>
<div style="display: flex">
  <app-terms-conditions></app-terms-conditions>
</div>



import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class ContractService {
  private baseHref;
  token: any;
  constructor(private commonService: CommonService , private http: HttpClient)
  {
    this.baseHref = commonService.baseHref;
    this.token = commonService.token;
   }
  getContractDetails() {
    return this.http.get<any[]>(this.baseHref + '/dis/rest/contract', { observe: 'response' });
  }
  getContractDetailsById(id: any) {
    return this.http.get<any[]>(this.baseHref + '/dis/rest/contract/' + id, { observe: 'response' });
  }
  getContractDetailsAvailableOrganization(id: any) {
    return this.http.get<any[]>(this.baseHref + '/dis/rest/organizationcontract/available/' + id, { observe: 'response' });
  }
  getContractDetailsByOrganization(id: any) {
    return this.http.get<any[]>(this.baseHref + '/dis/rest/organizationcontract/contract/' + id, { observe: 'response' });
  }
  deleteContractDetailsByOrganization(id: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.delete<any[]>(this.baseHref + '/dis/rest/organizationcontract/' + id, requestOptions);
  }
  deleteContractDetailsByServices(id: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.delete<any[]>(this.baseHref + '/dis/rest/contractservice/' + id, requestOptions);
  }
  getContractDetailsAvailableService(id: any) {
    return this.http.get<any[]>(this.baseHref + '/dis/rest/contractservice/available/' + id, { observe: 'response' });
  }
  getContractDetailsServices(id: any)
  {
    return this.http.get<any[]>(this.baseHref + '/dis/rest/contractservice/contract/' + id, { observe: 'response' });
  }
  saveContractOrganization(request: any)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put<any>(this.baseHref + '/dis/rest/organizationcontract', request, requestOptions);
  }
  saveContractService(request: any)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put<any>(this.baseHref + '/dis/rest/contractservice', request, requestOptions);
  }
  getContractServices(id: any)
  {
    return this.http.get<any[]>(this.baseHref + '/dis/rest/contractservice/contract' + id, { observe: 'response' });

  }
  checkExistingContractName(name: any) {
    return this.http.get<any>(this.baseHref + '/dis/rest/contract/checkExistingContractName?value=' + name + '&id=undefined', { observe: 'response' });
  }
  deleteContract(id, request) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put<any>(this.baseHref + '/dis/rest/contract/' + id, request, requestOptions);
  }
  saveContract(request)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put<any>(this.baseHref + '/dis/rest/contract/' , request, requestOptions);
  }
  updateContract(request, id)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.put<any>(this.baseHref + '/dis/rest/contract/' + id , request, requestOptions);
  }
}

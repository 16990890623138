<div id="organizationList" *ngIf="authorized()">

    <ol class="breadcrumb breadcrumb-dis">
        <li><a [routerLink]="['']">{{'views.organization.Home' | translate }}</a></li>
        <li class="active">{{'views.organization.Organization list' | translate }}</li>
        <li class="logout"><a href="./oidc/logout">{{'views.organization.Log out' | translate }}</a></li>
    </ol>
    
    <div class="titleicon"><img src="assets/images/homepage/organizations.png" alt="organization management"/></div>
    <h1>{{'views.organization.Organization list' | translate }}</h1>
    
 

      <div class="panel panel-default">

            <div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
                <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
            </div>
            <div class="panel-heading"></div>
            <div class="panel-body">            
            <table mat-table [dataSource]="filteredAndPagedIssues" class="example-table-container" matSort matSortActive="name" matSortDisableClear matSortDirection="asc" (matSortChange)="resetPaging()" style="width: 100%;">

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;">{{'views.user.Status'|translate}}
                </th>
                <td mat-cell *matCellDef="let row">
                    <img style="width: 25px" *ngIf="row.status === 'suspended'"
                        src="assets/images/icons/bullet_red.png" alt="{{'views.user.suspended' | translate}}"
                        title="{{'views.user.suspended' | translate}}" />
                    <img style="width: 25px" *ngIf="row.status === 'active'"
                        src="assets/images/icons/bullet_green.png" alt="{{'views.user.active' | translate}}"
                        title="{{'views.user.active' | translate}}" />
                </td>
            </ng-container>

            <ng-container matColumnDef="orgname" style="width: flex;">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="width: fit-content; padding: 3%; font-weight: bold; color: black;">Name
                </th>
                <td mat-cell (click)="navigateorgDetail(row)" *matCellDef="let row">{{row.name}}</td>
            </ng-container>
            <ng-container matColumnDef="technicalid" style="width: flex;">
                <th mat-header-cell   *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;">Technical ID
                </th>
                <td mat-cell (click)="navigateorgDetail(row)" *matCellDef="let row">{{row.technicalId}}</td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef >
                 
                <!-- <div *ngIf="row.userId.status  === 'suspended'">
                    <div confirm-activate onconfirm="activateOrganizationUser(organizationuser)"></div>
                    <div confirm-delete onconfirm="deleteOrganizationUser(organizationuser)"></div>
                </div> -->
                </th>
                <td mat-cell *matCellDef="let row">
           
                    <app-confirm-delete (onconfirm)="deleteOrganizationUser(row)"></app-confirm-delete>

                </td>
              </ng-container>
            <ng-container matColumnDef="filter-status">
                <th mat-header-cell *matHeaderCellDef>

                </th>
            </ng-container>
            <ng-container matColumnDef="filter-orgname">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-form-field appearance="outline">
                        <input matInput [formControl]="searchNameControl" />
                    </mat-form-field>
                </th>
            </ng-container>
            <ng-container matColumnDef="filter-technicalid">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-form-field appearance="outline">
                        <input matInput [formControl]="searchtechnicalIdControl" />
                    </mat-form-field>
                </th>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-header-row
                *matHeaderRowDef="['filter-status', 'filter-orgname', 'filter-technicalid']"
                class="example-second-header-row">
            </tr>

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
   
   
    </div>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [length]="resultsLength" [pageSize]="10"
        (page)="getPageDetails($event)"></mat-paginator>
        </div>
            </div>

<div class="btn-group btn-group-justified">
    <div class="btn-group">
        <button type="button" class="btn btn-default" routerLink="/home">
            <span class="glyphicon glyphicon-home" style="margin-right: 10px"></span>{{'views.role.Home' | translate}}</button>
            </div>
                <div class="btn-group">
                    <button type="button" class="btn btn-default" (click)="gotoOrganizationNewPage()">
                        <span class="glyphicon glyphicon-plus" style="margin-right: 10px"></span>{{'views.organization.Add new organization' | translate }}</button>
                </div>
        </div>    
<div style="display: flex;margin-left: 7%;
margin-top: 19%;">
  <app-terms-conditions></app-terms-conditions>
</div>
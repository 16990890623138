<div class="main-container self-service-main-container" style="margin-top: 100px; max-width: 500px; margin-left: 5%">

    <div class="form-container self-service-form-container"
         style="display:block; max-width: none">

        <div class="main-text"><a style="color: #00a3e0" href="" onclick="javascript:window.open('','_self').close()">Close</a></div>
        <div class="title">Cookie Notice</div>
        <p class="main-text" style="font-weight:bold">Last revised May 21st, 2020</p>

        <p class="main-text">
        This Website or Application may use cookies to improve the user experience and ensure that it is functioning effectively.
        </p>

        <p class="main-text">
            In order to provide you with a more personalized and responsive service we may need to remember and store information about how you use this Website or Application. This is done using small text files called cookies. Cookies contain small amounts of information and may be downloaded to your computer or other device by a server for this Website or Application. Your web browser then sends these cookies back to this Website or Application on each subsequent visit so that it can recognize you and remember things like your user preferences.  Please note that this Cookie Notice is part of our
        <a href="/dis/public/privacynotice" style="color: black; text-decoration: underline;">Privacy Notice</a>.
        </p>

        <p class="main-text">
            Whenever you use this Website or Application, information may be collected through the use of cookies and other technologies.
        </p>

        <div class="form-title" style="font-size: 16px;">Cookies we may use and why</div>
        <p class="main-text">
            When we use cookies, we may use strictly necessary cookies which are necessary to enable you to move around the Website or Application and use its features such as accessing secure areas that may contain content for registered users.
        </p>

        <p class="main-text">
            We may also use functional cookies to record information about the choices you have made and to allow us to tailor this Website or Application to our users; for example, to remember your language or region or that you have already completed a survey. This information is usually anonymized and is not used for any purpose unrelated to this Website or Application.
        </p>

        <p class="main-text">
            We or our service providers may also use analytic services to help us understand how effective our content is, what interests our users have, and to improve how this Website or Application works. In addition, we may also use web beacons, tracking pixels and similar services to understand more about the visitors to this Website or Application, and to count visitor numbers and performance cookies to track how many individual users access this Website or Application and how often. When collected, this information is used for statistical purposes and to improve how this Website or Application works and it is not our intention to use such information to personally identify any user. However, if at any time you register and sign into this Website or Application, we may combine such data with information from our web analytic services and cookies to analyze how you use this Website or Application in more detail.
        </p>

        <div class="form-title" style="font-size: 16px;">How to control cookies?</div>

        <p class="main-text">
            You may be able to change your browser settings to manage and control cookies.  You can find detailed information on how to do so at <a style="color: #00a3e0; text-decoration: underline;" href="https://www.aboutcookies.org/">https://www.aboutcookies.org/</a>
        </p>

        <div class="form-title" style="font-size: 16px;">Email communications</div>

        <p class="main-text">
            We may also use tracking technologies to determine whether you have read, clicked on, or forwarded certain email communications we send to you so that we can make our communications more helpful and interesting. If you do not wish us to confirm whether you have opened, clicked on or forwarded our communications, you will need to unsubscribe, as it is not possible for us to send these emails without tracking enabled. Registered subscribers can update their communication preferences at any time by contacting us, or you can unsubscribe following the instructions in the individual email communications you receive from us.
        </p>


        <div class="form-title" style="font-size: 16px;">Changes to this Cookie Notice</div>

        <p class="main-text">
            We may modify or amend this Cookie Notice from time to time at our discretion. When we make changes to this notice, we will amend the revision date at the top of this page, and such modified or amended Cookie Notice shall be effective as to you and your information as of that revision date.
        </p>

        <p class="main-text">
            We encourage you to periodically review this Cookie Notice to be informed about how we are using cookies.
        </p>
    </div>

    <!-- FOOTER -->
    <app-terms-conditions></app-terms-conditions>
</div>
import { AfterViewInit, Component, OnInit , ViewChild } from '@angular/core';
import { CommonService } from 'src/app/common.service';
import { PermissionService } from 'src/app/permission.service';
import { ContractService } from '../contract.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
@Component({
  selector: 'app-contract-detail',
  templateUrl: './contract-detail.component.html',
  styleUrls: ['./contract-detail.component.css']
})
export class ContractDetailComponent implements OnInit, AfterViewInit {
    contractrequest: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.pattern(/^[\w\d\s-']+$/)]),
    startDate: new FormControl('', [Validators.required]),
    endDate: new FormControl('', [Validators.required]),
    wbs: new FormControl(''),
    description: new FormControl(''),
    contactEmail: new FormControl('', [Validators.email]),
    roleNotifications: new FormControl(''),
    status:new FormControl('')
  });
  orgformControl = new FormControl();
  serviceformControl = new FormControl();
  orgfilteredOptions: Observable<[]>;
  servicefilteredOptions: Observable<[]>;
  alerts = [];
  contract;
  id;
  OrgData;
  organizationcount = 0;
  servicecount = 0;
  serviceData;
  availableServices;
  availableorganizations;
  serviceDisplayedColumns: string[] = ['status', 'name', 'action'];
  displayedColumns: string[] = ['status', 'organization', 'technicalId', 'action'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  servicedataSource: MatTableDataSource<any>;
  organizationstoadd: { contractId: { id: any; }; organizationId: string; };
  organizationData: any;
  servicestoadd: { contractId: { id: any; }; serviceId: string; };
  constructor(private contractService: ContractService, private translateService: TranslateService, private route: ActivatedRoute, private commonService: CommonService, private permission: PermissionService, private router: Router) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = +params.get('id');
    });
    this.contractService.getContractDetailsById(this.id).subscribe(data =>
      {
        this.contract = data.body;
        const startDate = new Date(this.contract.startDate);
        const endDate = new Date(this.contract.endDate);
        let day: any = startDate.getDate();
        day = day < 10 ? '0' + day : day;
        let month: any = startDate.getMonth() + 1;
        month = month < 10 ? '0' + month : month;
        const year = startDate.getFullYear();
        this.contract.startDate = year + '-' + month + '-' + day;
        let eday: any = endDate.getDate();
        eday = eday < 10 ? '0' + eday : eday;
        let emonth: any = endDate.getMonth() + 1;
        emonth = emonth < 10 ? '0' + emonth : emonth;
        const eyear = endDate.getFullYear();
        this.contract.endDate = eyear + '-' + emonth + '-' + eday;
        this.contractrequest.get('name').setValue(this.contract.name);
        this.contractrequest.get('startDate').setValue(this.contract.startDate);
        this.contractrequest.get('endDate').setValue(this.contract.endDate);
        this.contractrequest.get('wbs').setValue(this.contract.wbs);
        this.contractrequest.get('description').setValue(this.contract.description);
        this.contractrequest.get('contactEmail').setValue(this.contract.contactEmail);
        this.contractrequest.get('roleNotifications').setValue(this.contract.mobile);
        this.contractrequest.get('status').setValue(this.contract.status);

      });
  }
  navigateorgDetail(row)
   {
    this.router.navigate(['/organization/' + row.organizationId.id])
    .then(nav => {
       // true if navigation is successful
    }, err => {
    });
   }
   navigateServiceDetail(row)
   {
    this.router.navigate(['/service/' + row.serviceId.id])
    .then(nav => {
       // true if navigation is successful
    }, err => {
    });
   }
  updatedorg()
  {
    this.contractService.getContractDetailsByOrganization(this.id).subscribe(data => {
      this.OrgData = data.body;
      this.organizationcount = this.OrgData.length;
      this.dataSource = new MatTableDataSource(this.OrgData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
    this.contractService.getContractDetailsAvailableOrganization(this.id).subscribe(
      data => {
        this.availableorganizations = data.body;

        this.orgfilteredOptions = this.orgformControl.valueChanges
          .pipe(
            startWith({}),
            map(user => user && typeof user === 'object' ? user.name : user),
            map((name: string) => name ? this.orgFilter(name) : this.availableorganizations.slice())
          );

      }, error => {}
    );
  }
  deleteOrganizationUser(row)
  {
    const request = row;
    request[`status`] = 'inactive';
    this.contractService.deleteContractDetailsByOrganization(row[`id`]).subscribe(data =>
    {
      this.updatedorg();
    },
    err =>
    {
    }
    );
  }
  deleteService(row)
  {
    const request = row;
    request[`status`] = 'inactive';
    this.contractService.deleteContractDetailsByServices(row[`id`]).subscribe(data =>
    {
      this.updateService();
    },
    err =>
    {
    }
    );
  }
  updateService()
  {
    this.contractService.getContractDetailsServices(this.id).subscribe(data => {
      this.serviceData = data.body;
      this.servicecount = this.serviceData.length;
      this.servicedataSource = new MatTableDataSource(this.serviceData);
      this.servicedataSource.paginator = this.paginator;
      this.servicedataSource.sort = this.sort;
    });
    this.contractService.getContractDetailsAvailableService(this.id).subscribe(
      data => {
        this.availableServices = data.body;
        this.servicefilteredOptions = this.serviceformControl.valueChanges
          .pipe(
            startWith({}),
            map(user => user && typeof user === 'object' ? user.name : user),
            map((name: string) => name ? this.serviceFilter(name) : this.availableServices.slice())
          );

      }, error => {}
    );
  }
  ngAfterViewInit() {
  this.updatedorg();
  this.updateService();
  }
  serviceFilter(name: string) {
    return this.availableServices.filter(option =>
      option.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }
  orgFilter(name: string) {
    return this.availableorganizations.filter(option =>
      option.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }
  filterData(key, value) {
    this.dataSource = this.OrgData.filter((item: any) => {
      let rowData;
      if (key) {
        rowData = item?.organizationId[key].toLowerCase();
      }
      return rowData.includes(value.toLowerCase());
    });
  }
  servicefilterData(key, value) {
    this.dataSource = this.serviceData.filter((item: any) => {
      let rowData;
      if (key) {
        rowData = item?.serviceId[key].toLowerCase();
      }
      return rowData.includes(value.toLowerCase());
    });
  }
  updateInputorg(event)
  {
    if (event.option.value !== undefined) {
      this.organizationData = event.option.value;
      this.orgformControl.setValue(event.option.value.name);
    }
  }
  updateInputService(event)
  {
    if (event.option.value !== undefined) {
      this.serviceData = event.option.value;
      this.serviceformControl.setValue(event.option.value.name);
    }
  }
  closeAlert(index) {
    this.alerts.splice(index, 1);
  }
  submit() {
    const request = this.contractrequest.value;
    const CurrentDate = new Date().setHours(0, 0, 0, 0);
    const startDate = new Date(this.contractrequest.get('startDate').value).setHours(0, 0, 0, 0);
    const endDate = new Date(this.contractrequest.get('endDate').value).setHours(0, 0, 0, 0);
    request[`id`] = this.id;
    request[`startDate`] = new Date(this.contractrequest.value.startDate).getTime();
    request[`endDate`] = new Date(this.contractrequest.value.endDate).getTime();
    if (request.roleNotifications === undefined)
    {
      request.roleNotifications = false;
    }
    if (startDate < CurrentDate) {
      this.translateService.get('views.js.alerts.contracts.Start date must be greater than or equal to today date.').subscribe(res => {
        this.alerts.push({ type: 'danger', msg: res });
      });
      return;
    }
    if (startDate > endDate) {
      this.translateService.get('views.js.alerts.contracts.Start date must be smaller than end date.').subscribe(res => {
        this.alerts.push({ type: 'danger', msg: res });
      });
      return;
    }
    else {
      this.contractService.updateContract(request, this.id).subscribe(data =>
        {
          this.router.navigate(['contract/list'])
          .then(nav => {
             // true if navigation is successful
          }, err => {
          });
        }
      );
    }
   
  }
  authorized() {
    return this.permission.isAdmin || this.permission.isUserAdmin;
  }
  addOrganization() {

    this.organizationstoadd = {'contractId': {'id': this.id}, 'organizationId': ''};
    this.organizationstoadd.organizationId = this.organizationData;
    this.contractService.saveContractOrganization(this.organizationstoadd).subscribe(data =>
      {
          this.updatedorg();
          this.orgformControl.setValue('');
      });
  }
  addServices()
  {
    this.servicestoadd = {'contractId': {'id': this.id}, 'serviceId': ''};
    this.servicestoadd.serviceId = this.serviceData;
    this.contractService.saveContractService(this.servicestoadd).subscribe(data =>
      {
          this.updateService();
          this.serviceformControl.setValue('');
      });
  }
}

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { CommonService } from 'src/app/common.service';
import { Router } from '@angular/router';
import { PermissionService } from 'src/app/permission.service';
import { ExportExcelService } from 'src/app/excel-export.service';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
@Component({
  selector: 'app-allusers',
  templateUrl: './allusers.component.html',
  styleUrls: ['./allusers.component.css']
})
export class AllusersComponent implements OnInit , AfterViewInit{

  dtOptions: DataTables.Settings = {};
  userData = [];
  organization: any;
  table = $('#example').DataTable();
  info;
  displayedColumns: string[] = ['status', 'lastname', 'firstname', 'email', 'action'];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  filteredAndPagedIssues: Observable<[]>;
  filter = {};
   options = {
    title: 'All Users',
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: true,
    useBom: true,
    headers:['status', 'lastname', 'firstname', 'email', 'action']
  };
  @ViewChild(MatPaginator , {static: false}) paginator: MatPaginator;
  orginalData: any;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
// this.setDataSourceAttributes();
}
  @ViewChild(MatSort , {static: false}) sort: MatSort;
  public constructor(private commonService: CommonService , private permission: PermissionService ,  private router: Router, private excelService: ExportExcelService) {

  }
  
  ngAfterViewInit() {
    this.apiTrigger();
  }
  ngOnInit(): void {

        this.organization = this.commonService.selectedOrg;
        }
 apiTrigger()
  {
    this.filteredAndPagedIssues = merge(this.paginator.page)
    .pipe(
      startWith({}),
      switchMap(() => {
        let request;
        if(Object.keys(this.filter).length>0)
          request = { 'page': this.paginator.pageIndex + 1, 'order': this.sort.direction, 'orderBy': this.sort.active, 'filters': this.filter, 'size': this.paginator.pageSize};
         else
          request = { 'page': this.paginator.pageIndex + 1, 'order': this.sort.direction, 'orderBy': this.sort.active, 'size': this.paginator.pageSize};
        return this.commonService.getAllUserData(request);
      }),
      map(data => {
        // Flip flag to show that loading has finished.
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        this.resultsLength = data.attr.total;
        this.orginalData = data.users;
         ;
        return data.users;
      }),
      catchError(() => {
        this.isLoadingResults = false;
        // Catch if the GitHub API has reached its rate limit. Return empty data.
        this.isRateLimitReached = true;
        return observableOf([]);
      })
    );
  }
  navigateUserDetail(row)
  {

    this.router.navigate(['/user/' + row._id ])
    .then(nav => {
      this.commonService.previoususerlistlocation = '/user/list/all';
       // true if navigation is successful
    }, err => {
       // when there's an error
    });
  }
  authorized() {

    return this.permission.isAdmin || this.permission.isHelpDeskAdmin;
  }
  resetPaging(): void {
    this.apiTrigger();
  }
  applyFilterbyLastName(filterValue: string) {
   this.filter[`sn`] = filterValue!=""?filterValue.toLowerCase():"";
   this.apiTrigger();
   this.paginator.firstPage();

  }
  applyFilterbyFirstName(filterValue: string) {
    this.filter[`givenName`] = filterValue!=""?filterValue.toLowerCase():"";
    this.apiTrigger();
    this.paginator.firstPage();

  }
  applyFilterbyEmail(filterValue: string) {
    this.filter[`mail`] = filterValue!=""?filterValue.toLowerCase():"";

    this.apiTrigger();
    this.paginator.firstPage();


}
suspendOrganizationUser(row)
{
  
  const changes = {
    "action":"suspend",
    "userId":row._id
}
      this.commonService.updateUserStatus(changes).subscribe(data =>{
        this.filter ={};
        this.apiTrigger();
      });
}
activateOrganizationUser(row)
{
  
  const changes = {
    "action":"activate",
    "userId":row._id
}
      this.commonService.updateUserStatus(changes).subscribe(data =>{
        this.filter ={};
        this.apiTrigger();
      });
}
gotoHomePage() {
  this.commonService.titleSub = 'a new';
  this.router.navigate([''])
    .then(nav => {
       // true if navigation is successful
    }, err => {
       // when there's an error
    });
}
exportUsersToExcelFromApi(): void {
  this.commonService.getAllUsers().subscribe((data: any) => {
     ;
    this.excelService.generateExcelForUsers(data, 'User_Data');
});
}
}

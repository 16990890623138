<div id="roleassignmentNew" *ngIf="authorized()">

    <ol class="breadcrumb breadcrumb-dis">
        <li><a href="" routerLink="/home">{{"views.contract-detail.Home"| translate}}</a></li>
        <li><a href="" routerLink="/roleassignment/list/all">{{'views.roleassignment-detail.Role Assignment List'| translate}}</a></li>
        <li class="active">{{'views.admin.role.list'| translate}}
          </li>
        <li class="logout"><a href="./oidc/logout">{{"views.home.Log out"| translate}}</a></li>
    </ol>

    <!-- <div class="titleicon"><img src="images/homepage/roles.png" alt="role management" /></div> -->
    <div *ngFor = "let alert of alerts">
        <p class="alert " [ngClass]="{'alert-danger' : alert.type=='danger','alert-info' : alert.type=='info'}">
            {{alert.msg}}
            <i  (click)="closeAlert(i)" aria-hidden="true" class="fa fa-times" style="
            float:right;
   font-size: 22px; "></i>
        </p>
    </div>
    

    <h1>{{'views.admin.role.list'| translate}} </h1>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="panel-body">
            <form name="roleassignmentForm" class="form-horizontal" role="form" [formGroup]="roleAssignmentForm">
                <!-- <div class="form-group" show-errors>
                    <label for="role.startDate" class="col-sm-2 control-label ">{{'views.service.Start date'|
                        translate}}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="role.name" name="name" ng-model="role.name" required ng-pattern="/^[\w\d\s-']+$/"/>
                    </div>
                    <div class="col-sm-7">
                        <input type="date" class="form-control" formControlName="startDate" [attr.disabled]="true">
                    </div>
                    <div class="col-sm-1" style="max-width: none"
                        *ngIf="roleAssignmentForm.controls['startDate'].hasError('required') && roleAssignmentForm.controls['startDate'].touched">
                        <span class="hint-text call-send-text">{{'views.public.otpmail.Required'| translate}}</span>
                    </div>
                </div> -->
                <!-- <div class="form-group" show-errors>
                    <label for="role.endDate" class="col-sm-2 control-label ">{{'views.service.End date'|
                        translate}}</label>
                  
                    <div class="col-sm-7">
                        <input type="date" class="form-control" formControlName="endDate"
                            (change)="dateChanges($event.target.value)">
                    </div>
                    <div class="col-sm-1" style="max-width: none"
                        *ngIf="roleAssignmentForm.controls['endDate'].hasError('required') && roleAssignmentForm.controls['endDate'].touched">
                        <span class="hint-text call-send-text">{{'views.public.otpmail.Required'| translate}}</span>
                    </div>
                </div> -->
                <!-- <div class="form-group" show-errors>
                    <label for="role.technicalId" class="col-sm-2  control-label ">{{'views.role.Technical ID' |
                        translate }}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" formControlName="technicalId"
                            (keyup)="validateTechnicalId($event)">
                    </div>
                    <div class="col-sm-3">
                        <p class="help-block"
                            *ngIf="roleForm.controls['technicalId'].hasError('required') && roleForm.controls['technicalId'].touched">
                            {{'views.role.Required' |
                            translate }}</p>
                        <p class="help-block" *ngIf="roleForm.controls['technicalId'].hasError('pattern')">
                            {{'views.role.The technical ID can only contain alphanumeric values.' | translate }}</p>
                    </div>
                </div> -->
               
                <div class="form-group" show-errors>
                    <label for="roleassignment.roleId" class="col-sm-2 control-label ">{{'views.organization.Role'|
                        translate}}</label>
                    <div class="col-sm-7">
                        <mat-form-field appearance="fill">
                            <mat-label>Role</mat-label>
                            <mat-select formControlName="role" (selectionChange)="roleChange($event)">
                              <mat-option *ngFor="let role of adminRoles" [value]="role">
                                {{role}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
        
                    </div>
                </div>
                <div class="form-group" show-errors>
                    <label for="roleassignment.user" class="col-sm-2 control-label ">{{"views.role.User"|
                        translate}}</label>
                    <div class="col-sm-7">
                        <input type="text" (keyup)="filterUsers($event.target.value)" class="form-control"
                            formControlName="user" [matMenuTriggerFor]="menu" (input)="fetchUsers()" placeholder="Search user">
                        <div class="col-sm-1" style="max-width: none"
                            *ngIf="roleAssignmentForm.controls['user'].hasError('required') && roleAssignmentForm.controls['user'].touched">
                            <span class="hint-text call-send-text">{{'views.public.otpmail.Required'| translate}}</span>
                        </div>
                        <mat-menu #menu="matMenu">
                            <ng-container *ngFor="let user of currentUsers">
                                <button mat-menu-item (click)="selectUser(user)">{{user?.sn}}
                                    {{user?.givenName}} ({{user?.mail}})</button>
                            </ng-container>
                        </mat-menu>
                        <div *ngIf="roleassignmentForm?.controls.user.touched && currentUsers?.length<=0">
                            <span>No Users Found</span>
                           </div>
                          
                    </div>
                </div>
                <div class="form-group" show-errors *ngIf="orgAdminFlag ">
                    <label for="roleassignment.organizationId" class="col-sm-2 control-label ">{{'views.user.Organization'| translate}}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" formControlName="organization" placeholder="Search Organization"
                            (keyup)="filterOrganizations($event.target.value)"  [attr.disabled]="isMenuDisabled==true ? '':null"   [matMenuTriggerFor]="orgMenu">
                        <mat-menu #orgMenu="matMenu">
                            <ng-container *ngFor="let org of availableOrganizations">
                                <button mat-menu-item (click)="selectOrg(org)">{{org?.name}} </button>
                            </ng-container>
                        </mat-menu>
                        <div *ngIf="roleassignmentForm?.controls.organization.touched && availableOrganizations?.length<=0">
                            <span>No Organizations Found</span>
                           </div>
                    </div>
                </div>
                   <div class="col-sm-2"></div>
                <div class="col-sm-7">
                    <div class="btn-group savebutton">
                        <button type="button" class="btn btn-default" (click)="saveRoleAssignment()"
                            [disabled]="(!roleAssignmentForm.valid)||(isMenuDisabled)">
                            <span class="glyphicon glyphicon-floppy-disk"
                                style="margin-right: 10px"></span>{{'views.role.Save' | translate }}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="panel panel-default"  *ngIf="authorizedBatch()">
        <div class="panel-heading">
            {{"views.roleassignment-new.Batch Upload Role Assignments"| translate}}
        </div>
        <div class="panel-body">
            <div style="float:right">
                <button type="button" class="btn btn-default btn-xs" (click)="showhelp = !showhelp"
                    title="{{'views.user.Show help' | translate}}">
                    <span class="glyphicon glyphicon-question-sign" style="margin-right: 10px"></span>
                </button>
            </div>
            <div *ngIf="showhelp" style="overflow: auto;">

                <p><span class="">{{'views.roleassignment-new.Batch Upload' | translate}}</span></p>

                <p><span class="">{{'views.roleassignment-new.Batch Upload2' | translate}}</span></p>

                <p><span class="batchcmd">{{'views.roleassignment-new.Batch Upload3' | translate}}</span></p>
                <p><span class="">(dd-mm-yyyy)</span></p>

                <p><span class="batchcmd">{{'views.roleassignment-new.OPERATION' | translate}} </span>
                    <span class="">{{'views.user.can be' | translate}} </span>
                    <span class="batchcmd">{{'views.roleassignment-new.REATE,DELETE' | translate}}. </span>
                    <span class="">{{'views.roleassignment-new.All fields are required, but ' | translate}}</span>
                    <!-- <span class="batchcmd">{{'views.roleassignment-new.ORGTECHID' | translate}} & {{'views.roleassignment-new.TARGETSERVICETECHID' | translate}} </span>
                    <span class="">{{'views.roleassignment-new.can be set to NULL' | translate}}</span> -->
                </p>

                <p><span class="">{{'views.user.The CSV file should not contain headers. The first line is data.' | translate}}</span></p>

                <p><span class="">{{'views.user.Examples of correct lines are:' | translate}}</span></p>

                <p><span class="batchcmd">{{'views.roleassignment-new.Create1' | translate}}</span></p>
                <p><span class="batchcmd">{{'views.roleassignment-new.Create2' | translate}}</span></p>
                <p><span class="batchcmd">{{'views.roleassignment-new.Create3' | translate}}</span></p>
                

                <p><span class="">{{'views.roleassignment-new.Create4' | translate}}</span></p>

                <p><span class="">{{'views.user.You will be notified by mail with the results of the batch changes.' | translate}}</span></p>

                <br><br>
            </div>
            <div *ngIf="selected">
                <p><span class="">{{'views.user.Selected file:' | translate}} {{selectedfile.name}} ({{selectedfile.size
                        / 1000 | number:0}} kb)</span></p>
            </div>
            <div *ngIf="showprogress" class="progress" style="height: 20px">
                <div class="progress-bar" role="progressbar" [ngStyle]="{'width': progresswidth}"
                    aria-valuenow="(progressvalue / progressmax)*100 | number:0" aria-valuemin="0" aria-valuemax="100">
                    {{(progressvalue / progressmax)*100 | number:0 }}%
                </div>
            </div>
            <!-- <div class="btn-group">
                <button type="button" class="btn btn-default" (click)="download()" title="Download file">
                    <span class="glyphicon glyphicon-save" style="margin-right: 10px"></span>
                    {{"views.user.Download batch upload template"| translate}}
                </button>
            </div> -->
            <div class="btn-group btn-group-justified">
                <div class="btn-group">
                    <div class="choose_file">
                        <button type="button" class="btn btn-default btn-group" title="Select file">

                            <span class="glyphicon glyphicon-paperclip" style="margin-right: 10px"></span>
                            {{'views.user.Select your CSV file' | translate}}
                        </button>
                        <input type="file" (change)="selectfile($event)" accept=".csv,.CSV">
                    </div>
                </div>
                <div class="btn-group">
                    <button [attr.disabled]="selected==false ? '':null" type="button" class="btn btn-default"
                        (click)="upload()" title="Upload file">
                        <span class="glyphicon glyphicon-open" style="margin-right: 10px"></span>
                        {{'views.user.Upload file' | translate}}
                    </button>
                </div>
            </div>

        </div>
    </div>
    <app-terms-conditions></app-terms-conditions>
</div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizationServiceConfiguration, BaseTokenRequestHandler, GRANT_TYPE_AUTHORIZATION_CODE, StringMap, TokenRequest } from '@openid/appauth';
import { CONFIG } from '../config';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-ssologin',
  templateUrl: './ssologin.component.html',
  styleUrls: ['./ssologin.component.css']
})
export class SsologinComponent implements OnInit {

  code!: string | undefined;
  request: any;
  tokenHandler: any;
  configuration: any;
  spinnerFlag = true;
  constructor(private route: ActivatedRoute, private router: Router,private commonService:CommonService) { }

  ngOnInit(): void {
    
    let requestId = localStorage.getItem('appauth_current_authorization_request');
    this.configuration = new AuthorizationServiceConfiguration(JSON.parse(
      localStorage.getItem(requestId+'_appauth_authorization_service_configuration') as string));
    this.request = JSON.parse(
        localStorage.getItem(requestId+'_appauth_authorization_request') as string);
    this.route.queryParams.subscribe(params => {
      this.code = params['code'];
               let extras: StringMap|undefined = undefined;
      if (this.request && this.request.internal) {
        extras = {};
        extras['code_verifier'] = this.request.internal['code_verifier'];
      }
      this.tokenHandler = new BaseTokenRequestHandler();
      let request: TokenRequest|null = null;  
      const ssoConfig = CONFIG.getSSOConfig();
      // use the code to make the token request.
      request = new TokenRequest({
          client_id: ssoConfig.client_id,
          redirect_uri: ssoConfig.redirect_uri,
          grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
          code: this.code,
          refresh_token: undefined,
          extras: extras
      });
      this.tokenHandler.performTokenRequest(this.configuration, request)
  .then((response: any) => {
    localStorage.setItem('currentUser', JSON.stringify(response));
    const user = JSON.parse(localStorage.getItem('currentUser'));
    localStorage.setItem('authToken', response['accessToken']);
    this.commonService.getCurrentUser().subscribe(data =>
      {
        this.spinnerFlag = false
        localStorage.setItem('currentUserDetails', JSON.stringify(data.body));
        this.router.navigate(['']);
      },
      err =>{
        this.spinnerFlag = false;
        this.router.navigate(['public/404']);

      }
    )
  });
    })
  }

}

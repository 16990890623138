<div id="contractList" *ngIf="authorized()">

    <ol class="breadcrumb breadcrumb-dis">
        <li><a [routerLink]="['']">{{"views.contract-detail.Home"| translate}}</a></li>
        <li class="active">{{"views.contract-detail.Contract List"| translate}}</li>
        <li class="logout"><a href="./oidc/logout">{{"views.home.Log out"| translate}}</a></li>
    </ol>
    
    <div class="titleicon"><img src="assets/images/homepage/contracts.png" alt="contract management"/></div>
    <h1>{{'views.contract-new.New Contract'| translate}}</h1>
    <div *ngFor = "let alert of alerts">
        <p class="alert " [ngClass]="{'alert-danger' : alert.type=='danger','alert-info' : alert.type=='info'}">
            {{alert.msg}}
            <i  (click)="closeAlert(i)" aria-hidden="true" class="fa fa-times" style="
            float:right;
   font-size: 22px; "></i>
        </p>
    </div>
    <div class="panel panel-default">
        <div class="panel-heading">
           
        </div>
        <div class="panel-body">
            <form [formGroup]="contractrequest" (ngSubmit)="onSubmit(contractrequest)" class="form-horizontal" role="form">
                <div class="form-group" show-errors>
                    <label for="contract.name" class="col-sm-2 control-label ">{{'views.public.orgchoice.Name'| translate}}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="name" name="name" (change)="checkName(name)" formControlName="name" required 
                                />
                    </div>
                    <div class="col-sm-3" *ngIf="contractrequest.controls.name.errors!=null">
                        <p class="error-block" *ngIf="contractrequest.controls.name.errors.required && contractrequest.controls.name.touched">{{'views.public.otpmail.Required'| translate}}</p>
                        <p class="error-block" *ngIf="!(contractrequest.controls.name.errors.required) && contractrequest.controls.name.status == 'INVALID' &&contractrequest.controls.name.touched">{{'views.service.Invalid Input' | translate}}</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="error-block"
                        *ngIf="uniqueValue">
                        {{'views.contract-new.This Contract name already exists.'| translate}}
                        </p>
                        </div>
                </div>
                <div class="form-group" show-errors>
                    <label for="contract.startdate" class="col-sm-2 control-label ">{{'views.service.Start date'| translate}}</label>
                    <div class="col-sm-7">
                        <p class="input-group">
                            <input id="contract.startDate" name="startDate" type="date" class="form-control" 
                                   formControlName="startDate" 
                                   style="text-indent: 0px;width:340px" 
                                   required placeholder="dd/MM/yyyy"/>
                        </p>
                    </div>
                    <div class="col-sm-1" *ngIf="contractrequest.controls.startDate.errors != null">
                        <p class="help-block" *ngIf="contractrequest.controls.startDate.errors.required && contractrequest.controls.startDate.touched">{{'views.public.otpmail.Required'| translate}}</p>
                    </div>
                </div>
                <div class="form-group" show-errors>
                    <label for="contract.endDate" class="col-sm-2 control-label ">{{'views.service.End date'| translate}}</label>
                    <div class="col-sm-7">
                        <p class="input-group">
                            <input id="contract.endDate" name="enddate" type="date" class="form-control" 
                                 formControlName="endDate"  
                                 required placeholder="dd/MM/yyyy"
                                 style="text-indent: 0px;width:340px" 
                                 />
                            
                        </p>
                    </div>
                    <div class="col-sm-1" *ngIf="contractrequest.controls.endDate.errors != null">
                        <p class="help-block" *ngIf="contractrequest.controls.endDate.errors.required && contractrequest.controls.endDate.touched">{{'views.public.otpmail.Required'| translate}}</p>
                    </div>
                </div>
                <div class="form-group" show-errors>
                    <label for="contract.wbs" class="col-sm-2 control-label ">{{'views.contract-detail.WBS'| translate}}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="contract.wbs" name="wbs" formControlName="wbs" />
                    </div>
                </div>
                <div class="form-group" show-errors>
                    <label for="contract.description" class="col-sm-2 control-label ">{{'views.service.Description'| translate}}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="contract.description" name="description" formControlName="description" />
                    </div>
                </div>
                <div class="form-group" show-errors>
                    <label  for="contract.contactEmail" class="col-sm-2 control-label ">{{'views.service.Contact email'| translate}}</label>
                    <div class="col-sm-7">
                        <span class="input-group">
                            <span class="input-group-addon">@</span>
                            <input type="text" class="form-control" 
                                   name="email" id="contract.contactEmail" 
                                   formControlName="contactEmail" 
                                   />
                        </span>
                    </div>
                    <div class="col-sm-3">
                        <p class="help-block" *ngIf="contractrequest.controls.contactEmail.errors?.email && contractrequest.controls.contactEmail.touched">{{'views.public.pwdmail.The email address is invalid'| translate}}</p>
                    </div>
                </div>
                 <div class="form-group">
                    <div class="col-sm-offset-2 col-sm-10">
                        <div class="checkbox" >
                            <label>
                                <input type="checkbox" id="contract.roleNotifications" name="roleNotifications" formControlName="roleNotifications"  style="position: relative;"/>
                                <span class="">{{'views.contract-detail.Disable role assign notification e-mail.'| translate}}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-2"></div>
                <div class="col-sm-7">
                    <div class="btn-group savebutton">
                        <button type="button"
                                class="btn btn-default"
                                (click)="submit()"
                                [attr.disabled]="contractrequest.status === 'INVALID'? '':null"
                                >
                            <span class="glyphicon glyphicon-floppy-disk" style="margin-right: 10px"></span>{{'views.user.Save'| translate}}</button>
                    </div>
                </div>
            </form>
            </div>
            </div>
            <div style="display: flex;margin-top: 50%;">
                <app-terms-conditions></app-terms-conditions>
            </div> 
</div>
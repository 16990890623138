import { Component, OnInit } from '@angular/core';
import { PasswordService } from '../password.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OtpappjourneyService } from './otpappjourney.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-otp-set',
  templateUrl: './otp-set.component.html',
  styleUrls: ['./otp-set.component.css']
})
export class OtpSetComponent implements OnInit {
  qrcodeValue: any;

  constructor(private passwordService: PasswordService,private otpJourney:OtpappjourneyService, private route: ActivatedRoute, private router: Router, private translateService: TranslateService) { 
    this.validateOtpForm.valueChanges
    .pipe(debounceTime(200))
    .subscribe(values => {
      // Compare current values to initial values;
      this.showAlert= false;
    })  
    }
  otpvalidated: boolean;
  alertMessage: string;
  showAlert: boolean;
  otpalert: string;
  showfingerprintimage: boolean;
  codes: any;
  code: any;
  alerts=[];
  lang: any;
  error: any;
  path: any;
  qrcodeurl: string;
  organization: any;
  validateOtpForm: FormGroup = new FormGroup({
    'otp': new FormControl('', Validators.required)
  });
  spinner=true;
  qrcodecallbackresponse;
  getcodecallbackresponse;
  ngOnInit(): void {
    this.code = this.route.snapshot.queryParams['code'];
    this.translateService.use('EN');
    this.otpJourney.getQRcodeCallback(this.code).subscribe(data =>{
      this.qrcodecallbackresponse = data;
      const input = this.qrcodecallbackresponse.callbacks[2].output[0].value;
      this.passwordService.generateCode(input).subscribe(data => {
        this.qrcodeurl = '../dis/qrcode';
        const otpUrl = input;
        const secretRegex = /secret=([^&]+)/;
        const secretMatch = otpUrl.match(secretRegex);
    if (secretMatch) {
        const secret = secretMatch[1];
        this.qrcodeValue=secret;
        this.spinner=false;
        this.otpJourney.getcodecallback(this.qrcodecallbackresponse).subscribe(data =>{
          this.getcodecallbackresponse = data;
        })
    } else {
    }

    },err=>{
      this.otpvalidated =false;
      this.otpalert = "QR code loading  failed"
        this.spinner=false;
    })
  },
err=>{

  this.router.navigate(['public/error']);

});

  }
  verifycode(otp) {
    this.showAlert=false;

    this.getcodecallbackresponse.callbacks[0].input[0].value = otp;
    this.otpJourney.sendVerifyCode(this.getcodecallbackresponse).subscribe(data =>{
      
      if(data.callbacks[0].output[0]?.value==="Authenticator App Registration successful")
      {
        
        this.passwordService.successtype = 'otpset';
        this.router.navigate(['public/success']);
      }
      else  if(data.callbacks[0].output[0].value==="Enter verification code")
        {
          this.getcodecallbackresponse = data;
          this.otpvalidated = false;
          this.showAlert=true;
        this.translateService.get('views.js.alerts.emailprocessing.verification of one time password failed').subscribe(res => {
          this.otpalert = res;
        });

        }
    },err=>{

    });

  }
}

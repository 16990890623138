<div id="userList" [hidden]="!(authorized())" >

    <ol class="breadcrumb breadcrumb-dis">
        <li><a [routerLink]="['']">{{'views.user.Home' | translate}}</a></li>
        <li class="active">{{'views.user.Users for organization' | translate}} {{organization?.name}}</li>
        <li class="logout"><a href="./oidc/logout">{{'views.user.Log out' | translate}}</a></li>
    </ol>

    <div class="titleicon"><img src="assets/images/homepage/users.png"
            alt="{{'views.user.role management' | translate}}" />
    </div>
    <h1>{{'views.user.Users for organization' | translate}} {{organization?.name}}</h1>
    <div class="panel panel-default" >
      <div class="panel-heading" style="display: flex; justify-content: space-between; align-items: center;">
        <div style="display: flex; align-items: center;">
          <img src="assets/images/homepage/users.png" alt="{{'views.organization.Users'| translate}}" width="30px" style="margin-right: 10px;"/>
          <span>  {{'views.organization.Users'| translate}} ({{resultsLength}})</span>
          <div class="loader"   *ngIf="isLoadingResults">
            <div class="dot" style="--dot-index:0;"></div>
            <div class="dot" style="--dot-index:1;"></div>
            <div class="dot" style="--dot-index:2;"></div>
          </div>  
        </div>
      
      </div>
                       
              <table mat-table [dataSource]="filteredAndPagedIssues" class="example-table" matSort
                     matSortActive="lastname" matSortDisableClear matSortDirection="asc"
                     (matSortChange)="resetPaging()">
                <!-- Title Column -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;">{{'views.user.Status'|translate}}</th>
                  <td mat-cell *matCellDef="let row">
                    <img style="width: 25px" *ngIf="row?.accountStatus === 'suspended'"
                    src="assets/images/icons/bullet_red.png" alt="{{'views.user.suspended' | translate}}"
                    title="{{'views.user.suspended' | translate}}" />
                <img style="width: 25px" *ngIf="row?.accountStatus === 'active'"
                    src="assets/images/icons/bullet_green.png" alt="{{'views.user.active' | translate}}"
                    title="{{'views.user.active' | translate}}" />
                  </td>
                </ng-container>
                <ng-container matColumnDef="lastname" style="width: fit-content;">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;"><div style="margin:0 -47px">{{'views.user.Last name'|translate}}</div></th>
                  <td mat-cell *matCellDef="let row" (click)="navigateUserDetail(row)"><div style="margin:0 -40px;width:98px">{{row.sn}}</div></td>
                </ng-container>
          
               
                <ng-container matColumnDef="firstname" style="width: fit-content;">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;">
                    <div style="margin:0 -67px">  {{'views.user.First name'|translate}}</div></th>
                  <td mat-cell *matCellDef="let row" (click)="navigateUserDetail(row)"><div style="margin:0 -75px;width: 98px;">{{row.givenName}}</div></td>
                </ng-container>
                <ng-container matColumnDef="email" style="width: fit-content;">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;" >
                      {{'views.user.Email'|translate}}
                    </th>
                    <td mat-cell *matCellDef="let row" (click)="navigateUserDetail(row)">
                  
                      <div style="margin:0 -110px;width:245px;">{{row.mail}}</div></td>
                  </ng-container>
                  <ng-container matColumnDef="action" style="width: flex;">
                    <th mat-header-cell *matHeaderCellDef>
                       
                    <!-- <div *ngIf="row.userId.status  === 'suspended'">
                        <div confirm-activate onconfirm="activateOrganizationUser(organizationuser)"></div>
                        <div confirm-delete onconfirm="deleteOrganizationUser(organizationuser)"></div>
                    </div> -->
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <div *ngIf="row.accountStatus  !== 'suspended'" style="    color: #333;
                      font-weight: 400;
                      margin: 1px;">
                        <app-confirmsuspend (onconfirm)="suspendOrganizationUser(row)"></app-confirmsuspend>
                       <app-confirm-delete (onconfirm)="deleteOrganizationUser(row)"></app-confirm-delete>
              
                     </div> 
                      <div *ngIf="row.accountStatus  === 'suspended'" style="display: flex;color: #333;
                      font-weight: 400;
                      margin: 1px;">
                        <app-confirm-activate (onconfirm)="activateOrganizationUser(row)"></app-confirm-activate>
                        <app-confirm-delete (onconfirm)="deleteOrganizationUser(row)"></app-confirm-delete>
                      </div>
                    </td>
                  </ng-container>
                   <!-- Item Description Column -->
  <ng-container matColumnDef="filter-status">
    <th mat-header-cell *matHeaderCellDef> 
     
       </th>
  </ng-container>

  <!-- Cost Description Column -->
  <ng-container matColumnDef="filter-lastname">
    <th mat-header-cell *matHeaderCellDef> 
       <mat-form-field appearance="outline" style="margin:0 -69px">
      <input
        matInput
        [formControl]="searchLastNameControl"        
      />
    </mat-form-field> </th>
  </ng-container>
   <!-- Item Description Column -->
   <ng-container matColumnDef="filter-firstname">
    <th mat-header-cell *matHeaderCellDef>
      <mat-form-field appearance="outline" style="margin:0 -85px">
        <input
          matInput
          [formControl]="searchFirstNameControl"

         
        />
      </mat-form-field>
    </th>
  </ng-container>

  <!-- Cost Description Column -->
  <ng-container matColumnDef="filter-email">
    <th mat-header-cell *matHeaderCellDef> <mat-form-field appearance="outline" style="
      margin: 0 -111px;
      width: 263px !important;
  ">
      <input
        matInput
        [formControl]="searchEmailControl"
        
        
      />
    </mat-form-field> </th>
  </ng-container>
          
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-header-row *matHeaderRowDef="['filter-status', 'filter-lastname','filter-firstname','filter-email']"
      class="example-second-header-row">
  </tr>

                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
          
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [length]="resultsLength"  (page)="getPageDetails($event)" [pageSize]="10"></mat-paginator>
            
          
    </div>
    <div class="btn-group btn-group-justified">
      <div class="btn-group">
          <button type="button" class="btn btn-default" (click)="gotoHomePage()">
              <span class="glyphicon glyphicon-home" style="margin-right: 10px"></span>
              {{'views.user.Home' | translate}}
          </button>
      </div>
      <div class="btn-group">
          <button type="button" class="btn btn-default" (click)="gotoUserRequestNewPage()">
              <span class="glyphicon glyphicon-plus" style="margin-right: 10px"></span>
              {{'views.user.Register user for organization' | translate}} {{organization?.name}}
          </button>
      </div>
  </div>

    <app-terms-conditions></app-terms-conditions>
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class OtpappjourneyService {
  private _frbasehref: string;
  public get frbasehref(): string {
    return this._frbasehref;
  }
  public set frbasehref(value: string) {
    this._frbasehref = value;
  }
  constructor(private commonService: CommonService, private http: HttpClient) { 
    if (window.location.host.startsWith('accountqa')) {
      this._frbasehref="https://logonqa.deloitte.com"
      
  } else if(window.location.host.startsWith('accountstage')) {
    this._frbasehref="https://logonstage.deloitte.com"

  }
  else if(window.location.host.startsWith('logonlocal')) {
    this._frbasehref="https://logondev.deloitte.com"

  }
  
  else {
    this._frbasehref="https://logon.deloitte.com"


  }  }
  getQRcodeCallback(resumeCode){
    const qrcodecallbackurl=this._frbasehref+"/am/json/realms/O365/authenticate?authIndexType=service&authIndexValue=AuthAppRegistration&resumeCode="+resumeCode
    const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Accept-API-Version': 'resource=2.1,protocol=1.0'
    });
    const requestOptions = { headers};
    return this.http.post<any>(qrcodecallbackurl,{},requestOptions);
  }
  getcodecallback(request)
  {
    const getcodecallbackurl =this._frbasehref+'/am/json/realms/O365/authenticate?authIndexType=service&authIndexValue=AuthAppRegistration'
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-API-Version': 'resource=2.1,protocol=1.0'
   });
   const requestOptions = { headers};
   return this.http.post<any>(getcodecallbackurl,request,requestOptions);
  }
  sendVerifyCode(request)
  {
    const getcodecallbackurl =this._frbasehref+'/am/json/realms/O365/authenticate?authIndexType=service&authIndexValue=AuthAppRegistration'
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-API-Version': 'resource=2.1,protocol=1.0'
   });
   const requestOptions = { headers};
   return this.http.post<any>(getcodecallbackurl,request,requestOptions);
  }
  getusernameCallback()
  {
    const getcodecallbackurl =this._frbasehref+'/am/json/realms/O365/authenticate?authIndexType=service&authIndexValue=ResetPassword'
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-API-Version': 'resource=2.1,protocol=1.0'
   });
   const requestOptions = { headers};
   return this.http.post<any>(getcodecallbackurl,{},requestOptions);
  }
  sendPasswordemail(request)
  {
    const getcodecallbackurl =this._frbasehref+'/am/json/realms/O365/authenticate?authIndexType=service&authIndexValue=ResetPassword'
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-API-Version': 'resource=2.1,protocol=1.0'
   });
   const requestOptions = { headers};
   return this.http.post<any>(getcodecallbackurl,request,requestOptions);
  }
}

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { CommonService } from '../../common.service';
import { MatTableDataSource } from '@angular/material/table';
import { RolesService } from '../../roles-service.service';
import { Router } from '@angular/router';
import { PermissionService } from '../../permission.service';
import { FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-role-assignments',
  templateUrl: './role-assignments.component.html',
  styleUrls: ['./role-assignments.component.css']
})
export class RoleAssignmentsComponent implements OnInit, AfterViewInit {
  searchOrglControl=new FormControl();
  searchRoleControl=new FormControl();
  searchtechnicalControl=new FormControl();

  constructor(private commonService: CommonService,private router: Router, private roleService: RolesService, private permission: PermissionService) { 
    this.searchOrglControl.valueChanges
    .pipe(
      debounceTime(300), // Delay of 300 ms
      distinctUntilChanged() // Only emit when the current value is different from the last
    )
    .subscribe(newValue => {
     
      this.spinnerFlag=true;

      this.filter['orgName'] = newValue;
      this.apiTrigger();
        //this.apiTrigger(); // Trigger your method here
    });
   
    this.searchRoleControl.valueChanges
    .pipe(
      debounceTime(300), // Delay of 300 ms
      distinctUntilChanged() // Only emit when the current value is different from the last
    )
    .subscribe(newValue => {
     
        this.spinnerFlag=true;

      this.filter['roleName'] = newValue;
      this.apiTrigger();
      
     // this.apiTrigger(); // Trigger your method here
    });
    this.searchtechnicalControl.valueChanges
    .pipe(
      debounceTime(300), // Delay of 300 ms
      distinctUntilChanged() // Only emit when the current value is different from the last
    )
    .subscribe(newValue => {
    
          this.spinnerFlag=true;
  
      this.filter['technicalId'] = newValue;
      this.apiTrigger(); // Trigger your method here
        
    });
  }
  filterData(key, value) {
    this.filteredAndPagedIssues = this.originalData.filter((item: any) => {
      let rowData;
      
        rowData = item.key.toLowerCase();
  
      return rowData.includes(value.toLowerCase());
    });
  }
  filteredAndPagedIssues= new MatTableDataSource<any>([]);
  isLoadingResults: boolean;
  alltxt = 'All';
  filter = {};
  data: any;
  displayedColumns: string[] = ['status', 'role',  'organization','service','action'];
  resultsLength = 0;
  originalData: any;
  email=new FormControl('',[Validators.required,Validators.email]);
  currentOrganization: string;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    // this.setDataSourceAttributes();
  }
  spinnerFlag=false;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  ngOnInit(): void {
    this.currentOrganization = this.commonService.selectedOrg[`name`]; ;
    this.filteredAndPagedIssues.paginator = this.paginator;
    this.email.valueChanges
    .pipe(
      debounceTime(300), // Delay of 300 ms
      filter(value => this.email.valid)
    )
    .subscribe(newValue => {
      this.email.markAsTouched();
      this.spinnerFlag=true;
      this.paginator.pageIndex = 0; // Reset pagination when search changes
      this.apiTrigger();      //this.apiTrigger(); // Trigger your method here
    });
  }
  ngAfterViewInit() {
    // this.apiTrigger();
  }
  navigateRoleAssignmentDetail(row)
  {
    this.router.navigate(['/roleassignment/'+row.roleId+"/"+row.orgId+"/"+row.userId])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }
  
  apiTrigger() {
    let request={}
    if(Object.keys(this.filter).length>0)
     request = { 'page': this.paginator.pageIndex + 1, 'order':'asc', 'orderBy':'role' , 'filters': this.filter, 'size': this.paginator.pageSize};
    else
    request = { 'page': this.paginator.pageIndex + 1, 'order': 'asc', 'orderBy': 'role', 'size': this.paginator.pageSize};
    const mail =this.email.value!=""?this.email.value.toLowerCase():""
    this.roleService.getRoleAssignmentsList(request,mail).subscribe(data=>{
      this.spinnerFlag=false;
      if(data.message.roleAssignmentData!=null)
      {
        this.resultsLength = data.message.totalPagedResults;
        this.filteredAndPagedIssues.data = data.message.roleAssignmentData;
        this.paginator.length=this.resultsLength
      }
      else
      {
        this.resultsLength = 0;
        this.filteredAndPagedIssues.data = [];
        this.paginator.length=0 
      }
    },error=>{
      this.spinnerFlag=false;
    });
    
  }
  authorized()
  {
    return this.permission.isAdmin || this.permission.isOrganizationAdmin || this.permission.isHelpDeskAdmin;

  }
  
  
  showAllRecords() {
    this.paginator.pageSize = this.resultsLength;
  }
  showAllRoleAssignments()
  {
    this.paginator.pageSize = this.resultsLength;
    this.apiTrigger();
    if (this.paginator) {
      this.paginator.firstPage();
    }
  }
  activateRole(row)
  {
    let request ={
      "userId":"",
      "roleId":"",
      "orgId":"",
      "status":""
      }
      request.userId=row.userId;
      request.roleId = row.roleId;
      request.orgId =row.orgId;
      request.status = "active";
    this.commonService.updateRoleAssignmentStatus(request).subscribe(data =>{  
      this.apiTrigger();
 
    });
  }
  // true if navigation is successful
  
  deleteRole(row)
  {
    let request ={
      "userId":"",
      "roleId":"",
      "orgId":"",
      "status":""
      }   
    request.userId=row.userId;
    request.roleId = row.roleId;
    request.orgId =row.orgId;
    request.status = "inactive";

       this.commonService.deleteRoleAssignment(request).subscribe(data =>{
      this.apiTrigger();
    });
  }
  suspendRole(row)
  {
    let request ={
      "userId":"",
      "roleId":"",
      "orgId":"",
      "status":""
      }  
      request.userId=row.userId;
      request.orgId =row.orgId;
      request.roleId =row.roleId;
      request.status = "suspended";
    this.commonService.updateRoleAssignmentStatus(request).subscribe(data =>{
      this.apiTrigger();

    })
  }
 
  
  // filterData(key, value) {
  //   this.filteredAndPagedIssues.data = this.originalData.filter((item: any) => {
  //     let rowData;
  //     if (key === 'name') {
  //       rowData = item[key].toLowerCase();
  //     } else {
  //       rowData = item.serviceId.name.toLowerCase();
  //     }
  //     return rowData.includes(value.toLowerCase());
  //   });
  // }
  gotoHomePage() {
    this.commonService.titleSub = 'a new';
    this.router.navigate([''])
      .then(nav => {
        // true if navigation is successful
      }, err => {
        // when there's an error
      });
  }
  gotoRoleNewPage()
  {
    this.router.navigate(['/roleassignment/new'])
    .then(nav => {
       // true if navigation is successful
    }, err => {
       // when there's an error
    });
  }
  getPageDetails(event) {
    this.apiTrigger();
  }
  resetPaging() {
    this.apiTrigger();
  }

}

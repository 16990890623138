import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-activate',
  templateUrl: './confirm-activate.component.html',
  styleUrls: ['./confirm-activate.component.css']
})
export class ConfirmActivateComponent implements OnInit {

  isActivate = false;
  @Output() onconfirm = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }

  startActivate()
  {
    this.isActivate = true;
  }
  confirm()
  {
    this.onconfirm.emit();
  }
  cancel()
  {
    this.isActivate = false;
  }

}

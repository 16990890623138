import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ParamMap, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/common.service';
import { PermissionService } from 'src/app/permission.service';
import { RolesService } from 'src/app/roles-service.service';
import { TranslateService } from '@ngx-translate/core';
import { ServiceManagementService } from 'src/app/Service_Management/service-management.service';
import { OrganizationService } from 'src/app/Organization_Management/organization-service.service';

@Component({
  selector: 'app-role-assignment-detail',
  templateUrl: './role-assignment-detail.component.html',
  styleUrls: ['./role-assignment-detail.component.css']
})
export class RoleAssignmentDetailComponent implements OnInit {

  roleassignment ;
  alerts=[];
  roleassignmentForm: FormGroup;
  roleId: string;
  orgId: string;
  userId: string;
  serviceDetails: any;
  isLoading=true;
  orgdetail: any;
  role: any;
  user ;
  initialFormValues;
  updatedFormFields: any;
  constructor(private orgService:OrganizationService,private servicemgmntService:ServiceManagementService,private translateService: TranslateService,private router: Router,private commonService: CommonService, private roleService: RolesService,  private permission: PermissionService,private route: ActivatedRoute) {
    this.roleassignmentForm = new FormGroup({
      'startDate': new FormControl(null, Validators.required),
      'endDate': new FormControl(null, [Validators.required]),
      'userId': new FormControl(null),
      'roleId': new FormControl(null, Validators.required),
      'organizationId': new FormControl(null),
      'targetServiceId': new FormControl(null),
    });
   }
  closeAlert(index) {
    this.alerts.splice(index, 1);
  }
  private formatDate(dateString)
  {
        const date = new Date(dateString);

        // Extracting year, month, and day as numbers
        const year = date.getFullYear(); // 2024
        const month = date.getMonth() + 1; // 12
        const day = date.getDate(); // 12

        // Formatting month and day with leading zeros for string representation
        const monthString = month < 10 ? '0' + month : month.toString();
        const dayString = day < 10 ? '0' + day : day.toString();

        // Constructing the formatted date string
        const formattedDate = `${year}-${monthString}-${dayString}`;
        return formattedDate
  }
  ngOnInit(): void {
    this.roleId = this.route.snapshot.params['roleId'];
   this.orgId = this.route.snapshot.params['orgId'];
   this.userId = this.route.snapshot.params['userId'];
   const req ={
    "roleID":this.roleId,
    "orgId":this.orgId,
    "userId":this.userId
   }
   this.roleService.getroleassignmentdetailswithallid(req).subscribe((data: any) => {
        if (data) {
          this.roleassignment = data;
          this.roleassignmentForm.get('startDate').patchValue(this.formatDate(new Date(data.startDate)));
          this.roleassignmentForm.get('endDate').patchValue(this.formatDate(new Date(data.endDate)));
          this.roleassignmentForm.get('organizationId').patchValue(data.orgName);
          this.commonService.userDetails(this.userId).subscribe(userData =>{
             this.user = userData;
            this.roleassignmentForm.get('userId').patchValue(this.user['givenName']+' '+this.user['familyName']+' ('+ userData['email'] +')');

          },error =>{
            this.isLoading =false;
          })
          this.orgService.getOrganizationDetails(this.orgId).subscribe(orgservice =>{
           this.orgdetail = orgservice;
          this.roleassignmentForm.get('organizationId').patchValue(this.orgdetail.name +' - '+ this.orgdetail.technicalId);

          },error =>{
            this.isLoading=false;
          });
          this.roleService.getRoleDetails(this.roleId).subscribe((data: any) => {
            ;
            this.role = data.body;

           if(this.role.serviceId!=null)
           { 
             this.servicemgmntService.getServiceDetailswithId(this.role.serviceId).subscribe(serviceData =>{
               this.serviceDetails = serviceData.body;
               this.isLoading=false;
               this.roleassignmentForm.get('roleId').patchValue(this.role.name +' - '+ this.role.technicalId +' ('+ this.serviceDetails.name+')');
                if(this.serviceDetails.technicalId!=null)
               this.roleassignmentForm.get('targetServiceId').patchValue(this.serviceDetails.technicalId);
                this.initialFormValues=this.roleassignmentForm.value;

   
             });
             
   
           }
           else
           {
            this.isLoading=false;

            this.roleassignmentForm.get('roleId').patchValue(this.role.name +' - '+this. role.technicalId );
          this.initialFormValues=this.roleassignmentForm.value;
          }
          },err=>{
            this.isLoading = false;
          }
        );
        //   if(data.services!=null)
        //   this.roleassignmentForm.get('serviceId').patchValue(data.services.name);
        // else
        // {
        //   this.roleassignmentForm.get('serviceId').patchValue('');
        // }


        }
      },err=>{
        this.isLoading=false;
      });
      }
 
  submit()
  {
    
    const startDate = this.formatDate(new Date(this.roleassignmentForm.get('startDate').value));
    const endDate = this.formatDate(new Date(this.roleassignmentForm.get('endDate').value));
    const CurrentDate = this.formatDate(new Date().setHours(0, 0, 0, 0));
    this.roleassignment['startDate'] = startDate;
    this.roleassignment['endDate'] = endDate;
    if (startDate <= CurrentDate) {
      this.translateService.get('views.js.alerts.contracts.Start date must be greater than or equal to today date.').subscribe(res => {
        this.alerts.push({ type: 'danger', msg: res });
      });
      return;
    }
    if (startDate > endDate) {
      this.translateService.get('views.js.alerts.contracts.Start date must be smaller than end date.').subscribe(res => {
        this.alerts.push({ type: 'danger', msg: res });
      });
      return;
    }
    else
    {
      const updateJson = {
        [this.orgId]: {
          [this.roleId]: {
            status: "active",
            startDate: startDate,
            endDate: endDate
          },
          status: "active",
          tenantName: "Global"
        }
      };
      const changes = [];
      changes.push({
        operation: "replace",
        field: "userRelationship",
        value: updateJson
      });
      this.commonService.updateUser(this.userId,changes).subscribe(data =>{  
        this.router.navigate(['roleassignment/list'])
   
      });
   
    
  }

  }
  gotoRoleAssignmentListPage()
  {
    this.router.navigate(['roleassignment/list'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }
  gotoHomePage() {
    this.router.navigate([''])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }

  authorized()
  {
     return this.permission.isAdmin || this.permission.isOrganizationAdmin || this.permission.isHelpDeskAdmin;
  
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-notice',
  templateUrl: './privacy-notice.component.html',
  styleUrls: ['./privacy-notice.component.css']
})
export class PrivacyNoticeComponent implements OnInit {
privacynoticeLanguages = ['Global-EN', 'China-EN', 'China-ZH', 'Japanese', 'Belgium-EN','Swiss-EN'];
privacyLang;
currentPrivacynoticeLanguage;
  constructor() { }

  ngOnInit(): void {
    const urlCountry = location.href.match(/[\w\/:#\.]*\?country=([\w-]+)/);
    this.currentPrivacynoticeLanguage=(urlCountry && urlCountry[1]) || this.privacynoticeLanguages[0];
    this.privacyLang = this.currentPrivacynoticeLanguage;

  }
  privacychangeLanguage(language)
  {
    this.currentPrivacynoticeLanguage = language;
  }

}

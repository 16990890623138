<div class="main-container self-service-main-container" style="margin-top: 100px; max-width: 500px; margin-left: 5%">
    <div class="form-container self-service-form-container" style="display:block; max-width: none">
        <div class="title">Oops, something went wrong!</div>
      

        <p class="main-text" style="margin-top: 100px">
            Please contact the help desk if this issue persists.
        </p>


    </div>

    <app-terms-conditions></app-terms-conditions>

</div>
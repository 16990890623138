import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { CommonService } from '../../common.service';
import { PermissionService } from '../../permission.service';
import { RolesService } from '../../roles-service.service';

@Component({
  selector: 'app-all-role-requests',
  templateUrl: './all-role-requests.component.html',
  styleUrls: ['./all-role-requests.component.css']
})
export class AllRoleRequestsComponent implements OnInit, AfterViewInit {


  constructor(private commonService: CommonService, private roleService: RolesService,private permission: PermissionService) { }
  filteredAndPagedIssues = new MatTableDataSource<any>([]);
  isLoadingResults: boolean;
  alltxt = 'All';
  filter = {};
  displayedColumns: string[] = ['status', 'email', 'role', 'organization'];
  resultsLength = 0;
  originalData: any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    // this.setDataSourceAttributes();
  }
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  ngOnInit(): void {
    // this.commonService.getCurrentUserRoles().subscribe(data => {
    //    ;
    //   this.filteredAndPagedIssues = data;
    // });
    this.roleService.getCurrentOrganization().subscribe(data => {
      this.roleService.token = data.headers.get('X-CSRF-TOKEN');
      this.apiTrigger();
       ;
    });
  }
  
  ngAfterViewInit() {
    // this.apiTrigger();
    this.filteredAndPagedIssues.paginator = this.paginator;
    this.filteredAndPagedIssues.sort = this.sort;
  }
  authorized()
  {
     this.permission.isAdmin || this.permission.isUserAdmin;
  
  }
  apiTrigger() {
    // this.commonService.getCurrentUserRoles().subscribe((data: any) => {
    //    ;
    //   this.filteredAndPagedIssues.data = data;
    //   this.originalData = data;
    //   this.resultsLength = data.length;
    // });
    // merge()
    //   .pipe(
    //     startWith({}),
    //     switchMap(() => {
    //       this.isLoadingResults = true;
    //       const request = { 'page': this.paginator.pageIndex + 1, 'order': this.sort.direction, 'orderBy': this.sort.active, 'filters': this.filter, 'size': this.paginator.pageSize };
    //       return this.roleService.getRoleAssignmentsList(request);
    //     }),
    //     map((data: any) => {
    //       // Flip flag to show that loading has finished.
    //       // this.isLoadingResults = false;
    //       // this.isRateLimitReached = false;
    //       this.resultsLength = data.length;
    //        ;
    //       this.originalData = data;
    //       this.filteredAndPagedIssues.data = data;
    //       return data;
    //     }),
    //     catchError(() => {
    //       // this.isLoadingResults = false;
    //       // // Catch if the GitHub API has reached its rate limit. Return empty data.
    //       // this.isRateLimitReached = true;
    //       return observableOf([]);
    //     })
    //   );
    const request = { 'page': this.paginator.pageIndex + 1, 'order': this.sort.direction, 'orderBy': this.sort.active, 'filters': this.filter, 'size': this.paginator.pageSize };
    this.roleService.getAllRoleRequests(request).subscribe((data: any) => {
      this.originalData = data.userRoleRequests;
      this.filteredAndPagedIssues.data = data.userRoleRequests;
      this.resultsLength = data.attr.total;
      this.paginator.length = data.attr.total;
      this.filteredAndPagedIssues.paginator = this.paginator;
      this.filteredAndPagedIssues.paginator.length = data.attr.total;
    });
  }
  applyFilterbyRole(filterValue: string) {
    this.filter[`r.name`] = filterValue;
    this.apiTrigger();
  }
  applyFilterByOrganization(filterValue: string) {
    this.filter[`organization`] = filterValue;
    this.apiTrigger();
  }
  applyFilterByService(filterValue: string) {
    this.filter[`service`] = filterValue;
    this.apiTrigger();
  }
  applyFilterbyEmail(filterValue: string) {
    this.filter[`u.email`] = filterValue;
    this.apiTrigger();
  }
  deleteRole(params) {
    params.status = 'inactive';
    this.commonService.deleteRoleRequest(params.id, params).subscribe(data => {
       ;
      this.apiTrigger();
    });
  }
  downloadCSV()
  {
    const options = { 
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: false
      , 
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ['Status',  'User email', 'Role', 'Organization'],
      nullToEmptyString: true,
    };
    let arr =[];
    if(this.originalData != undefined)
    {
      this.originalData.forEach(function (myans) {
        let obj ={};
        obj['status'] = myans.status;
        obj['email'] = myans.userId.email;
        obj['role'] = myans.roleId.name;
        obj['organization'] = myans.organizationId.name;
        arr.push(obj);
      });

    new Angular5Csv(arr, 'rolerequests',options);
    }
    else{
      new Angular5Csv([], 'rolerequests',options);

    }

  }
  showAllRecords() {
    this.paginator.pageSize = this.resultsLength;
    this.apiTrigger();
    if (this.paginator) {
      this.paginator.firstPage();
    }
  }
  suspendRole(params) {
    params.status = 'suspended';
    this.commonService.suspendRoleAssignment(params.id, params).subscribe(data => {
      this.apiTrigger();
    });
  }
  activateRole(params) {
    params.status = 'active';
    this.commonService.activateRoleAssignment(params.id, params).subscribe(data => {
      this.apiTrigger();
    });
  }
  filterData(key, value) {
    this.filteredAndPagedIssues.data = this.originalData.filter((item: any) => {
      let rowData;
      if (key === 'name') {
        rowData = item[key].toLowerCase();
      } else {
        rowData = item.serviceId.name.toLowerCase();
      }
      return rowData.includes(value.toLowerCase());
    });
  }
  getPageDetails(event) {
    this.apiTrigger();
  }
  resetPaging() {
    this.apiTrigger();
  }



}

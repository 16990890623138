import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getCookie } from 'xsrf-cookie';
import { CommonService } from './common.service';
import { Observable } from 'rxjs-compat';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root'
})
export class PasswordService {
  private baseHref;
  private _successtype;
  public get successtype() {
    return this._successtype;
  }
  public set successtype(value) {
    this._successtype = value;
  }
  private _token: any;
  public get token(): any {
    return this._token;
  }
  public set token(value: any) {
    this._token = value;
  }
  constructor(private commonService: CommonService, private http: HttpClient) {
    this.baseHref = this.commonService.baseHref;
  }
  getaction(code): Observable<any> {

    return this.http.get<any>(this.baseHref + '/dis/rest/public/activation/action/' + code);

  }
  getuserrequest(code) {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'body'
    };
    return this.http.get<any>(this.baseHref + '/dis/rest/public/activation/userrequest/' + code, httpOptions1);

  }

  getPassWordCallBack(code) {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'body'
    };
    return this.http.get<any>(this.baseHref + '/dis/rest/public/activation/passwordCallBack/' + code, httpOptions1);

  }
  getuser(code) {
    // observe: 'response' as 'response'
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'body'
    };
    return this.http.get<any>(this.baseHref + '/dis/rest/public/activation/user/' + code, httpOptions1);

  }
  getorg(code) {
    return this.http.get<any>(this.baseHref + '/dis/rest/public/activation/org/' + code);

  }
  findTenantName(email)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this.baseHref + '/dis/rest/public/activation/findTenantName', email, requestOptions);

  }
  generateCode(code) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this.baseHref + '/dis/rest/public/activation/generatecode', code, requestOptions);
  }
  getotpappkey(email) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this.baseHref + '/dis/rest/public/activation/getotpappkey/'+email, {'email':email}, requestOptions);
  }
  // verifycode:$resource('../rest/public/activation/verifycode', {code: "@code"}, {'save': {method:'POST'}})

  verifyCode(code) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this.baseHref + '/dis/rest/public/activation/verifycode', code, requestOptions);
  }

  sendOTP(code) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this.baseHref + '/dis/rest/public/twilio/sendOTP', code, requestOptions);
  }
  verifyOTP(code) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this.baseHref + '/dis/rest/public/twilio/verifyOTP', code, requestOptions);
  }
  activateUser(code, params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this.baseHref + '/dis/rest/public/activation/activate/' + code, params, requestOptions);
  }
  activateOtp(code, params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this.baseHref + '/dis/rest/public/activation/activate/otp/' + code, params, requestOptions);
  }
  validatePassword(code, params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this.baseHref + '/dis/rest/public/activation/validatePassword/' + code, params, requestOptions);
  }
  resetPassword(code, params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this.baseHref + '/dis/rest/public/activation/resetpassword/' + code, params, requestOptions);
  }


  postPassWordCallBack(code, params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this.baseHref + '/dis/rest/public/activation/postPassWordCallBack/' + code, params, requestOptions);
  }

  postMobileOption(code, params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this.baseHref + '/dis/rest/public/activation/postMobileOption/' + code, params, requestOptions);
  }

  postTelephoneNumber(code, params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this.baseHref + '/dis/rest/public/activation/postTelephoneNumber/' + code, params, requestOptions);
  }

  postAuthDeviceRegistration(code, params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this.baseHref + '/dis/rest/public/activation/postAuthDeviceRegistration/' + code, params, requestOptions);
  }

  postOTPSet(code, params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this.baseHref + '/dis/rest/public/activation/postOTPSet/' + code, params, requestOptions);
  }


  postAuthAPPOTPSet(code, params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this.baseHref + '/dis/rest/public/activation/postAuthAPPOTPSet/' + code, params, requestOptions);
  }

  

  postMFAPreference(code, params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this.baseHref + '/dis/rest/public/activation/postMFAPreference/' + code, params, requestOptions);
  }

  confirmDeviceRegistration(code, params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this.baseHref + '/dis/rest/public/activation/confirmDeviceRegistration/' + code, params, requestOptions);
  }


  passResetCallBack(code) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this.baseHref + '/dis/rest/public/activation/passResetCallBack/' + code, requestOptions);
  }


  passWordResetStartJourney(code) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this.baseHref + '/dis/rest/public/activation/passWordResetStartJourney/' + code, requestOptions);
  }

  passWordResetStartJourneyProgress(code,params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this.baseHref + '/dis/rest/public/activation/passWordResetStartJourneyProgress/' + code, params, requestOptions);
  }
}

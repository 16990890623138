<div class="example-loading-shade" [hidden]="!spinnerFlag">
    <mat-spinner *ngIf="spinnerFlag"></mat-spinner>
</div>
<div [hidden]="spinnerFlag">
<div *ngIf="mainPage" class="login-page reset-password-page ">
    <!-- <app-spinner></app-spinner> -->
    <div class="body-copy "></div>
    <div class="header-self-service" style="z-index: 3">
        <div class="logo-white">
            <img src="assets/img/self-service/deloitte-logo-white.svg">
        </div>
        <div>

            <select  [(ngModel)]="mainLang" id="language" (change)="changeLanguage()">

                <option *ngFor="let language of languages" value={{language.langCode}}>{{language.langValue}}</option>
            </select>

        </div>
    </div>
    <div class="main-container self-service-main-container" style="margin-left: 5%; z-index: 2">
        <div class="form-container self-service-form-container" style="display: inline; max-width: none; width: 700px; background: white">
            <br /><br />
            <div style="width: 700px; background: white; padding-right: 10px;padding-top: 50px;">
                <router-outlet></router-outlet>
            </div>
            
        </div>
        
    </div>
    <div class="img-container"></div>
</div>
<div *ngIf="!mainPage">
 <router-outlet></router-outlet>
</div>
<!-- Modal -->
<div class="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    style="    height: 268px;
    display: block;
    top: 291px;" aria-hidden="true" #confirmModal>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div id="userrequestconfirmation">
                <div class="modal-header">
                    <h4 class="modal-title" style="color:black">Your Session is about to expire!
                    </h4>
                </div>
                <div class="modal-body">
                    <span style="color:black">Session will expire in <b>{{countdown}}</b> seconds.<br />
                        Do you want to reset?</span>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary btn-sm" type="button" (click)="logout()" >{{'views.confirmationmodal.Yes'|
                        translate }}</button>
                    <button class="btn btn-warning" type="button" (click)="reset()">{{'views.confirmationmodal.No'|
                        translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<!-- <ng-template #content let-modal>
    <div id="userrequestconfirmation">
        <div class="modal-header">
            <h2 class="modal-title" style="color:black">{{'views.confirmationmodal.Confirmation!'| translate }}
            </h2>
        </div>
        <div class="modal-body">
            <h3 style="color:black">{{text}}</h3>
        </div>
        <div class="modal-footer">
            <button class="btn btn-primary" type="button" ng-click="ok()">{{'views.confirmationmodal.Yes'|
                translate }}</button>
            <button class="btn btn-warning" type="button" (click)="modal.dismiss()">{{'views.confirmationmodal.No'|
                translate }}</button>
        </div>
    </div>
</ng-template>

<button class="btn btn-lg btn-outline-primary" (click)="openModal(content)">Launch demo modal</button> -->
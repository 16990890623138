import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RolesService } from '../../roles-service.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { PermissionService } from '../../permission.service';
@Component({
  selector: 'app-role-new',
  templateUrl: './role-new.component.html',
  styleUrls: ['./role-new.component.css']
})
export class RoleNewComponent implements OnInit {

  roleForm: FormGroup;
  dataModel: any;
  originalServices: any;
  selectedService: any;
  filteredOptions: Observable<string[]>;
  availableServices: any;
  invalidFlag = false;
  technicalInvalidFlag = 'false';
  roleNameInvalidFlag='false'
  alerts: any[] = [];
   private alertIdCounter = 0;

    constructor(private rolesService: RolesService, private router: Router, private permission: PermissionService) {
   
    this.roleForm = new FormGroup({
      'name': new FormControl(null, Validators.required),
      'technicalId': new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9]+$/)]),
      'description': new FormControl(null),
      'serviceId': new FormControl(null, Validators.required),
    });
  }
  setAutoClose(id: number) {
    setTimeout(() => {
        this.closeAlert(this.alertIdCounter);
    }, 10000); // Close alert after 10 seconds
}

  closeAlert(index) {
    this.alerts.splice(index, 1);
  }
  ngOnInit(): void {
    this.rolesService.getAvailableServices().subscribe(data => {
       ;
      this.originalServices = data.body;
      this.availableServices = data.body;
      this.filteredOptions = this.roleForm.controls.serviceId.valueChanges.pipe(
        startWith(''),
        map(value => {
          const name = typeof value === 'string' ? value : value?.name;
          return name ? this._filter(name as string) : this.availableServices.slice();
        }),
      );
    });
  }
  displayFn(user): string {
    return user && user.name ? user.name : '';
  }
  private _filter(name: string) {
    const filterValue = name.toLowerCase();

    return this.availableServices.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  validateRoleName(event)
  {
    this.rolesService.checkRoleName(event.target.value).subscribe(data => {
      ;
     this.roleNameInvalidFlag = data;
     });
  }
  validateTechnicalId(event) {
    this.rolesService.checkTechnicalID(event.target.value).subscribe(data => {
     ;
    this.technicalInvalidFlag = data;
    });
  }
  filterService(searchString) {
    this.invalidFlag = this.availableServices.filter(
      function(data){ return data.name === searchString; }
  );
  }
  authorized()
  {
    return this.permission.isAdmin || this.permission.isRoleAdmin;

  }
  saveRole() {
    const params = {
      name: this.roleForm.value.name,
      technicalId: this.roleForm.value.technicalId,
      description: this.roleForm.value.description,
      serviceId: this.selectedService.id
    };
    this.rolesService.saveRole(params).subscribe(data => {
      if(data!=null && data[`code`]==403)
      {
        this.alerts.push({ type: 'danger', msg:"Role Name ALready Exists" });
        this.alertIdCounter++;
        this.setAutoClose(this.alertIdCounter);
      }
      else
      {
      this.router.navigate(['role/list']);
      }
    },error =>{
      this.alerts.push({ type: 'danger', msg:"Something went wrong" });

    }
  );
  }
  selectService(service) {
    this.roleForm.get('serviceId').setValue(service.name);
    this.selectedService = service;
  }
  updatePortCode(event: MatAutocompleteSelectedEvent) {
    
    if (event.option.value !== undefined) {
      this.selectedService = event.option.value;
    }
  }
}

<div class="login-page reset-password-page activate-page">
    <div ng-translate-language-pub-select></div>
    <div class="header-self-service">
        <div class="logo-white">
            <img src="../../dis/assets/img/self-service/deloitte-logo-white.svg">
        </div>
    </div>
    <div class="main-container  form-container-reset-password">
        <div class="form-container self-service-form-container">
            <div class="form">
                <div class="logo-successful">
                    <img src="../../dis/assets/img/self-service/activation-successful.svg">
                </div>
                <div class="form-title title title-successful">
                    {{successtitle}}
                </div>
                <div class="form-second-description main-text main-text-activation-successful">{{successtext}}</div>
                <!-- <div><p>{{"views.public.success.Click" | translate}} <a href="..">{{"views.public.success.here" | translate}}</a> {{"to go back to the self-service." | translate}}</p></div> -->
            </div>
        </div>
        <!-- <ng-include src="'../footer.html'"></ng-include> -->
        <!-- <div class="footer" style="padding-bottom: 0px">
            <div class="info-terms">
                <div class="info-terms-link-container">
                    <a href="/dis/public/#/tou" target="_blank" class="link-info link">Terms of use</a>
                    <a href="/dis/public/#/privacynotice" target="_blank" class="link-info link">Privacy statement</a>
                </div>
                <div class="info-text">
                    <div class="main-text info-text-footer">
                        <p style="font-size:10px">Deloitte refers to one or more of Deloitte Touche Tohmatsu Limited (“DTTL”), its global network of member firms, and their related entities (collectively, the “Deloitte organization”). DTTL (also referred to as “Deloitte Global”) and each of its member firms and related entities are legally separate and independent entities, which cannot obligate or bind each other in respect of third parties. DTTL and each DTTL member firm and related entity is liable only for its own acts and omissions, and not those of each other. DTTL does not provide services to clients. Please see",
                        <a class="link-description link" href="https://www.deloitte.com/about">www.deloitte.com/about</a>
                        to learn more.
                    </div>
                    <div class="main-text info-text-footer">© <span id = 'year'></span>. For information, contact Deloitte Global.</div>
                </div>
            </div>
        </div> -->
        <app-terms-conditions></app-terms-conditions>
        <script>
            var date = new Date();
            var year = date.getFullYear();
            document.getElementById('year').innerHTML = year;
        </script>
    </div>
    <!-- <div class="img-container">
    </div> -->
</div>
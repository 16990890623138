import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ServiceManagementService } from '../../service-management.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';
import { PermissionService } from 'src/app/permission.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { RolesService } from 'src/app/roles-service.service';

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.css']
})
export class ServiceDetailsComponent implements OnInit, AfterViewInit {
  @ViewChild('paginatorroles') paginatorRole: MatPaginator;
  rolefilteredtabledata: MatTableDataSource<any>;
  rolecount =0;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  roledisplayedColumns: string[] = ['status', 'name','action'];
  updatedFormFields={};
  servicedetailrequestForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.pattern(/^[\w\d\s-']+$/)]),
    technicalId: new FormControl(''),
    description: new FormControl('',),
    url: new FormControl('', [Validators.required, Validators.pattern(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/)]),
    uuid: new FormControl(''),
    appid: new FormControl(''),
    appOwner: new FormControl('', [Validators.pattern(/^[a-zA-Z0-9À-힣.!#$%&'*/=?^_`{|}~-]+@[a-zA-Z0-9À-힣](?:[a-zA-Z0-9À-힣-]{0,61}[a-zA-Z0-9À-힣])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)]),
    //technicalLead: new FormControl('', Validators.pattern(/^[a-zA-Z0-9À-힣.!#$%&'*/=?^_`{|}~-]+@[a-zA-Z0-9À-힣](?:[a-zA-Z0-9À-힣-]{0,61}[a-zA-Z0-9À-힣])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)),
    fss: new FormControl(null),
    memberFirmHA: new FormControl(null),
    onboardedDate: new FormControl(),
    contactEmail: new FormControl('', Validators.pattern(/^[a-zA-Z0-9À-힣.!#$%&'*/=?^_`{|}~-]+@[a-zA-Z0-9À-힣](?:[a-zA-Z0-9À-힣-]{0,61}[a-zA-Z0-9À-힣])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)),
    requestable: new FormControl(''),
    id: new FormControl('')
  });
  initialFormValues: any;
  alerts = [];
  uniqueValue;
  baseHref: string;
  memberFirmHAs = ['Brazil', 'Africa', 'Australia','Austria','Belgium','Canada/Chile','CBC','Central Europe','China','CIS','Cyprus','Denmark','DTTL',
                            'Finland','France','Germany','Greece','Iceland','India','Ireland','Israel','Italy','Japan','Korea','Luxembourg','Malta','Middle East','New Zealand','Norway','Netherlands','Portugal',
                            'S-LATAM','Southeast Asia','Spain','Sweden','Switzerland','Taiwan','Turkey','United Kingdom ','United States (US)'];
  fsss = ['Advisory', 'Audit', 'Consulting', 'Tax', 'Others']; 
  uniqueValueuuid: Object;
  uniqueValueappid: Object;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    width: 'auto',
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'fontName',
      ],
      ,
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
    customClasses: [],
  };
  id;
  serviceData;
  originalData: any;
  roleoriginalData: any;
  constructor(private rolesservice:RolesService,private servicemanagementservice: ServiceManagementService, private route: ActivatedRoute, private commonService: CommonService, private permission: PermissionService, private router: Router) { }
  ngAfterViewInit() {
	this.route.params.subscribe(params => {
      this.id = params['id'];
      });
    this.roleapiTrigger();
  }
  roleapiTrigger()
  {
    this.servicemanagementservice.getRoleService(this.id).subscribe((data: any) => {
      this.rolefilteredtabledata = new MatTableDataSource(data);
      this.rolecount = data.length;
      this.roleoriginalData = data;
      this.rolefilteredtabledata.paginator = this.paginatorRole;
      this.rolefilteredtabledata.sort = this.sort;
   });
  }
  closeAlert(index) {
    this.alerts.splice(index, 1);
  }
  applyRoleFilter(key, value: string) {
    this.rolefilteredtabledata.data = this.roleoriginalData.filter((item: any) => {
    let rowData;
    if (key) {
      rowData = item[key].toLowerCase();
    }
    return rowData.includes(value.toLowerCase());
    });
  }
  suspendRole(row)
  {
    let request ={operation: "replace",
    field: "status",
    value: "suspended"
  }
    this.rolesservice.suspend(request,row._id).subscribe(data =>
      {
        this.roleapiTrigger();
      })
  }
  
  deleteRole(row)
  {
    
    this.rolesservice.deleteRole(row._id).subscribe(data =>
      {
        this.roleapiTrigger();
      })

  }
  activateRole(row)
  {
    
    let request ={operation: "replace",
      field: "status",
      value: "active"
    }
      this.rolesservice.suspend(request,row._id).subscribe(data =>
        {
          this.roleapiTrigger();
        })
  }
  authorizedRoles()
  {
    return this.permission.isAdmin || this.permission.isRoleAdmin;

  }
  navigateRoleDetail(row)
  {
    this.router.navigate(['/role/' + row._id])
    .then(nav => {
       // true if navigation is successful
    }, err => {
       // when there's an error
    });
  }
  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
    });
    this.servicemanagementservice.getServiceDetailswithId(this.id).subscribe(data =>
      {
        this.serviceData=data.body;
        if(this.serviceData.onboardedDate != undefined)
        {
        const onboardedDate = new Date(this.serviceData.onboardedDate);
        let day: any = onboardedDate.getDate();
        day = day < 10 ? '0' + day : day;
        let month: any = onboardedDate.getMonth() + 1;
        month = month < 10 ? '0' + month : month;
        const year = onboardedDate.getFullYear();
        this.serviceData.onboardedDate = year + '-' + month + '-' + day;
        }
        this.servicedetailrequestForm.get('id').setValue(this.serviceData.id ===undefined ? '' : this.serviceData.id);

        this.servicedetailrequestForm.get('name').setValue(this.serviceData.name ===undefined ? '' : this.serviceData.name);
        this.servicedetailrequestForm.get('technicalId').setValue(this.serviceData.technicalId ===undefined ? '' : this.serviceData.technicalId);
        this.servicedetailrequestForm.get('description').setValue(this.serviceData.description ===undefined ? '' : this.serviceData.description);
        this.servicedetailrequestForm.get('url').setValue(this.serviceData.url===undefined ? '' : this.serviceData.url);
        this.servicedetailrequestForm.get('uuid').setValue(this.serviceData.uuid ===undefined ? '' : this.serviceData.uuid);
        this.servicedetailrequestForm.get('appid').setValue(this.serviceData.appid ===undefined ? '' : this.serviceData.appid);
        this.servicedetailrequestForm.get('appOwner').setValue(this.serviceData.appOwner ===undefined ? '' : this.serviceData.appOwner);
        //this.servicedetailrequestForm.get('technicalLead').setValue(this.serviceData.technicalLead ===undefined ? '' : this.serviceData.technicalLead);
        this.servicedetailrequestForm.get('fss').setValue(this.serviceData.fss ===undefined ? null : this.serviceData.fss);
        this.servicedetailrequestForm.get('memberFirmHA').setValue(this.serviceData.memberFirmHA ===undefined ? null : this.serviceData.memberFirmHA);
        this.servicedetailrequestForm.get('onboardedDate').setValue(this.serviceData.onboardedDate ===undefined ? '' : this.serviceData.onboardedDate);
        this.servicedetailrequestForm.get('contactEmail').setValue(this.serviceData.contactEmail===undefined ? '' : this.serviceData.contactEmail);
        this.servicedetailrequestForm.get('requestable').setValue(this.serviceData.requestable===undefined ? false : this.serviceData.requestable);
        this.initialFormValues = this.servicedetailrequestForm.value;
      });
      this.onChanges();
  }
  onChanges(): void {
    this.servicedetailrequestForm.valueChanges
    .pipe(debounceTime(200))
    .subscribe(values => {
      // Compare current values to initial values
      for (const field in values) {
        if (values[field] !== this.initialFormValues[field]) {
          this.updatedFormFields[field] = values[field]; // Update stored initial value on change
        }
      }
});  }
  
  authorized() {
 return this.permission.isAdmin ;
  }
  submit()
  { 
    const changes = [];
    for (const field in this.updatedFormFields) {
      
      if (this.updatedFormFields[field]!=null&&this.updatedFormFields[field] !== this.initialFormValues[field]) {

        changes.push({
          operation: "replace",
          field: field,
          value: this.updatedFormFields[field]
        });
      }
    }
    if (changes.length > 0) {
      this.servicemanagementservice.UpdateService(this.id,changes).subscribe(data => {
        this.router.navigate(['services/list']);
      },err =>{
            this.alerts.push({ type: 'danger', msg:"Unable to update service" });
      }
    );
    } else {
    }

  }
  gotoServiceListPage()
  {
    this.servicemanagementservice.saveService(this.servicedetailrequestForm.value).subscribe(data =>
      {
        this.router.navigate(['services/list'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
      })
  }
}

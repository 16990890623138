<div class="login-page activate-page">
    <div class="main-container self-service-main-container">
        <div class="form-container self-service-form-container ">
            <div class="form">
                <div class="form-title title">
                    {{"views.public.otpset.Register your Authenticator App"| translate}}
                </div>
                <div class="form-title title">
                    {{error}}
                </div>
                <div class="form-second-description main-text">{{"views.public.otpset.Configure
                    your One-Time-Password generator app" | translate}}</div>
                <div class="form-controls configure-method">
                    <!-- MFA Authenticator -->
                    <div class="authenticator-container">
                        <div class="form-group">
                            <!-- Show the authenticator container if the OTP was not validated yet-->
                            <div class="authenticator-container" *ngIf="!(otpvalidated)">
                                <!-- <p style="color: red;">{{"views.public.pwdset.Configure your Authenticator app in
                                three quick steps."| translate}}</p> -->
                                <!--Step 1 -->
                                <div class="authenticator-text-step">
                                    <b><span class="authenticator-text-step-label">{{"views.public.pwdset.Step 1"|
                                            translate}}</span></b>
                                    <p class="authenticator-text-step-description">{{"views.public.pwdset.Download
                                        and Install"| translate}}</p>
                                </div>
                                <!--Step 2-->
                                <div class="authenticator-text-step">
                                    <b><span class="authenticator-text-step-label">{{"views.public.pwdset.Step 2"|
                                            translate}}</span></b>
                                    <p class="authenticator-text-step-description">{{"views.public.pwdset.ScanQR
                                        Code"| translate}}</p>

                                    <div  class="qr-container">
                                        <div class="spinner" *ngIf="spinnerFlag"></div>

                                        <img *ngIf="!spinnerFlag" style="filter: grayscale(100%); height:100px"
                                                        src="{{qrcodeurl}}" alt="qrcode" />
                                                    <span class="qr-code">{{qrcodeValue}}</span>
                                    </div>
                                </div>
                                <!--Step 3-->
                                <div class="authenticator-text-step">
                                    <b><span class="authenticator-text-step-label">{{"views.public.pwdset.Step 3"|
                                            translate}}</span></b>
                                    <p class="authenticator-text-step-description">{{"views.public.pwdset.Enter the
                                        one time password"| translate}}</p>
                                    <form [formGroup]="validateOtpForm">
                                        <div class="confirm-form">
                                            <input formControlName="otp" type="password" autocomplete="off"
                                                name="codevalidation" class="input"
                                                placeholder="{{'views.public.pwdset.Code'| translate}}" required>
                                            <button class="confirm-btn"
                                                (click)="verifycode(validateOtpForm.value.otp)">{{"views.public.pwdset.Validate
                                                OTP"| translate}}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <p class="alert" [ngClass]="[(otpvalidated)? 'alert-success':'alert-danger']"
                                *ngIf="showAlert">
                                {{otpalert}}</p>
                            <!-- Show if OTP was valid- -->
                            <div *ngIf="otpvalidated" class="mobile-method-container">
                                <div class="confirm-form confirm-form-send">
                                    <span class="send-password-text">{{"views.public.pwdset.OTPValid"|
                                        translate}}</span>
                                </div>
                            </div>
                            <!-- <br /> -->
                            <!-- Show if OTP was not valid  -->
                            <!-- <div *ngIf="otpalert !== null && otpalert !== '' && otpalert.length > 0 && !otpvalidated" class="mobile-method-container">
                                    <div class="confirm-form confirm-form-send">
                                        <span class="main-text-error">{{otpalert}}</span>
                                    </div>
                                </div> -->

                        </div>

                    </div>
                </div>
            </div>
        </div>
        <app-terms-conditions></app-terms-conditions>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PasswordService } from '../password.service';

@Component({
  selector: 'app-success-screen',
  templateUrl: './success-screen.component.html',
  styleUrls: ['./success-screen.component.css']
})
export class SuccessScreenComponent implements OnInit {

  constructor(public translateService: TranslateService, private passwordService: PasswordService) { }
  successtext: any;
  successtitle: any;
  ngOnInit(): void {
    //   $translate('views.js.alerts.emailprocessing.Password reset successful head').then(function (msgSec) {
    //     $scope.successtitle = msgSec;
    // });
    // $translate('views.js.alerts.emailprocessing.Password reset successful body').then(function (msgSec) {
    //     $scope.successtext = msgSec;
    // });
    if (this.passwordService.successtype === 'activation') {
      this.translateService.get('views.js.alerts.emailprocessing.Activation successful head').subscribe(msgSec => {
        this.successtitle = msgSec;
      });
      this.translateService.get('views.js.alerts.emailprocessing.Activation successful body').subscribe(msgSec => {
        this.successtext = msgSec;
      });
    } else if (this.passwordService.successtype === 'passwordupdate') {
      this.translateService.get('views.js.alerts.emailprocessing.Password reset successful head').subscribe(msgSec => {
        this.successtitle = msgSec;
      });
      this.translateService.get('views.js.alerts.emailprocessing.Password reset successful body').subscribe(msgSec => {
        this.successtext = msgSec;
      });
    } else {
      this.translateService.get('views.js.alerts.emailprocessing.OTP application registration successful head').subscribe(msgSec => {
        this.successtitle = msgSec;
      });
      this.translateService.get('views.js.alerts.emailprocessing.OTP application registration successful body').subscribe(msgSec => {
        this.successtext = msgSec;
      });
    }

  }

}

import { PermissionService } from 'src/app/permission.service';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';
import { TranslateService } from '@ngx-translate/core';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ServiceManagementService } from '../../service-management.service';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
@Component({
  selector: 'app-service-list',
  templateUrl: './view-services.component.html',
  styleUrls: ['./view-services.component.css']
})
export class ViewServicesComponent implements OnInit , AfterViewInit{

  displayedColumns: string[] = ['status', 'servicename', 'technicalid', 'appid', 'action'];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  filteredAndPagedServices: Observable<[]>;
  // servicedatasource: MatTableDataSource<any>;
  filter = {};
  @ViewChild('pagingservice') paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  originalData: any;

constructor(private servicemanagementservice: ServiceManagementService , private translateService: TranslateService , private commonService: CommonService , private permission: PermissionService , private router: Router) { }

  ngAfterViewInit() {
    this.apiTrigger();
    // this.servicedatasource.paginator = this.paginator;
    // this.servicedatasource.sort = this.sort;
  }
  applyFilter(key, value: string) {
    this.filter[key] = value!=""?value.toLowerCase():"";
    this.apiTrigger();
    this.paginator.firstPage();

    // this.servicedatasource.data = this.originalData.filter((item: any) => {
    // let rowData;
    // if (key) {
    //   rowData = item[key].toLowerCase();
    // }
    // return rowData.includes(value.toLowerCase());
    // });
  }
  deleteServices(row)
  {
    
    const request = row;
    request[`status`] = 'inactive';
    this.servicemanagementservice.deleteService(row[`id`] , request).subscribe(data =>
      {
        this.apiTrigger();
      },
      err =>
      {
      }
      );
  }
  gotoHomePage()
  {
    this.router.navigate([''])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }
  getPageDetails(event) {
    this.apiTrigger();
  }

  gotoServiceNewPage()
  {
    this.router.navigate(['services/new'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }
  navigateServiceDetail(row)
  {
    this.router.navigate(['/service/' + row.id])
    .then(nav => {
       // true if navigation is successful
    }, err => {
       // when there's an error
    });
  }
  apiTrigger()
  {
    this.filteredAndPagedServices = merge(this.paginator.page)
    .pipe(
      startWith({}),
      switchMap(() => {
        let request={}
        if(Object.keys(this.filter).length>0)
         request = { 'page': this.paginator.pageIndex + 1, 'order': this.sort.direction, 'orderBy': this.sort.active, 'filters': this.filter, 'size': this.paginator.pageSize};
        else
         request = { 'page': this.paginator.pageIndex + 1, 'order': this.sort.direction, 'orderBy': this.sort.active, 'size': this.paginator.pageSize};

        return this.servicemanagementservice.getAllServices(request);
      }),
      map(data => {
        // Flip flag to show that loading has finished.
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        this.resultsLength = data.attr.total;
       // this.orginalData = data.organizations;
        return data.dtos;
      }),
      catchError(() => {
        this.isLoadingResults = false;
        // Catch if the GitHub API has reached its rate limit. Return empty data.
        this.isRateLimitReached = true;
        return observableOf([]);
      })
    );
    // this.servicemanagementservice.getServiceDetails().subscribe((data: any) => {
    //   this.servicedatasource = new MatTableDataSource(data.body);
    //   this.resultsLength = data.body.length;
    //   this.originalData = data.body;
    //   this.servicedatasource.paginator = this.paginator;
    //   this.servicedatasource.sort = this.sort;
  //  });
  }
  resetPaging(): void {
    this.apiTrigger();
  }
  ngOnInit(): void {
  }
  authorized() {

    return this.permission.isAdmin ;
  }
  

}

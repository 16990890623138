<div id="contractList" *ngIf="authorized()">

    <ol class="breadcrumb breadcrumb-dis">
        <li><a [routerLink]="['']">{{"views.contract-detail.Home"| translate}}</a></li>
        <li class="active">{{"views.contract-detail.Contract List"| translate}}</li>
        <li class="logout"><a href="./oidc/logout">{{"views.home.Log out"| translate}}</a></li>
    </ol>
    
    <div class="titleicon"><img src="assets/images/homepage/contracts.png" alt="contract management"/></div>
    <h1>{{"views.contract-detail.Contract List"| translate}}</h1>
    
    <div class="btn-group btn-group-xs" style="float: right">
        <div class="btn-group btn-group-xs">
            <button type="button" class="btn btn-sm" (click)="showAllContracts()">All</button>
        </div>
        <div class="btn-group btn-group-xs">
            <a class="btn btn-primary" (click)="downloadCSV()">{{"views.contract-list.CSV"|  translate}}</a>
        </div>
    </div>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="panel-body">
                <!-- <div class="example-loading-shade"
                     *ngIf="isLoadingResults || isRateLimitReached">
                  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                 
                </div> -->
                  <table mat-table [dataSource]="filteredAndPagedIssues" class="example-table"  matSort matSortActive="name"
                  matSortDisableClear matSortDirection="asc" (matSortChange)="resetPaging()">
                  
                    <!-- Title Column -->
                    <ng-container matColumnDef="status">
                      <th mat-header-cell *matHeaderCellDef style="font-weight: bold; color: black;">{{'views.user.Status'|translate}}</th>
                      <td mat-cell *matCellDef="let row">
                        <img style="width: 25px" *ngIf="row.status === 'suspended'"
                        src="assets/images/icons/bullet_red.png" alt="{{'views.user.suspended' | translate}}"
                        title="{{'views.user.suspended' | translate}}" />
                    <img style="width: 25px" *ngIf="row.status === 'active'"
                        src="assets/images/icons/bullet_green.png" alt="{{'views.user.active' | translate}}"
                        title="{{'views.user.active' | translate}}" />
                      </td>
                    </ng-container>
              
                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-weight: bold; color: black;" >Name</th>
                      <td mat-cell *matCellDef="let row" (click)="navigatecontractDetail(row)" >{{row.name}}</td>
                    </ng-container>
              
                    <ng-container matColumnDef="wbs">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-weight: bold; color: black;">
                      WBS</th>
                      <td mat-cell *matCellDef="let row" (click)="navigatecontractDetail(row)" >{{row.wbs}}</td>
                    </ng-container>
                    <ng-container matColumnDef="startDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-weight: bold; color: black;">
                         Start Date
                        </th>
                        <td mat-cell *matCellDef="let row" (click)="navigatecontractDetail(row)" >{{row.startDate | date:"dd/MM/yyyy"}}</td>
                      </ng-container>
                      <ng-container matColumnDef="endDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-weight: bold; color: black;">
                          End Date
                        </th>
                        <td mat-cell *matCellDef="let row" (click)="navigatecontractDetail(row)" >{{row.endDate | date:"dd/MM/yyyy"}}</td>
                      </ng-container>
                      <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef  style="font-weight: bold; color: black;">
                         
                        <!-- <div *ngIf="row.userId.status  === 'suspended'">
                            <div confirm-activate onconfirm="activateOrganizationUser(organizationuser)"></div>
                            <div confirm-delete onconfirm="deleteOrganizationUser(organizationuser)"></div>
                        </div> -->
                        </th>
                        <td mat-cell *matCellDef="let row">
                   
                            <app-confirm-delete (onconfirm)="deleteOrganizationUser(row)"></app-confirm-delete>
        
                        </td>
                      </ng-container>
                       <!-- Item Description Column -->
      <ng-container matColumnDef="filter-status">
        <th mat-header-cell *matHeaderCellDef> 
         
           </th>
      </ng-container>
    
      <!-- Cost Description Column -->
      <ng-container matColumnDef="filter-name">
        <th mat-header-cell *matHeaderCellDef> 
           <mat-form-field  class="namest" appearance="outline">
          <input
            matInput
            (keyup)="applyFilter('name',$event.target.value)"
            
          />
        </mat-form-field> </th>
      </ng-container>
       <!-- Item Description Column -->
       <ng-container matColumnDef="filter-wbs">
        <th mat-header-cell *matHeaderCellDef>
          <mat-form-field class= "wbsst" appearance="outline">
            <input
              matInput
              (keyup)="applyFilter('wbs',$event.target.value)"
             
            />
          </mat-form-field>
        </th>
      </ng-container>
    
      <!-- Cost Description Column -->
      <ng-container matColumnDef="filter-startDtae">
        <th mat-header-cell *matHeaderCellDef> 
          <mat-form-field class="startdate" appearance="outline">
          <input
            matInput
            (keyup)="applyFilter('startDate',$event.target.value)"
            
          />
        </mat-form-field> </th>
      </ng-container>
      <ng-container matColumnDef="filter-endDate">
        <th mat-header-cell *matHeaderCellDef> 
            <mat-form-field class="enddate" appearance="outline">
          <input
            matInput
            (keyup)="applyFilter('endDate',$event.target.value)"
            
          />
        </mat-form-field> </th>
      </ng-container>
              
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-header-row *matHeaderRowDef="['filter-status', 'filter-name','filter-wbs','filter-startDtae','filter-endDate']"
          class="example-second-header-row">
      </tr>
    
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                
              
                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [length]="resultsLength" [pageSize]="10"></mat-paginator>
              
            </div>
            </div>
            <div class="btn-group btn-group-justified">
              <div class="btn-group">
                  <button type="button" class="btn btn-default" (click)="gotoHomePage()">
                      <span class="glyphicon glyphicon-home" style="margin-right: 10px"></span>{{"views.contract-detail.Home"| translate}}</button>
              </div>
              <div class="btn-group">
                  <button type="button" class="btn btn-default" (click)="gotoContractNewPage()">
                      <span class="glyphicon glyphicon-plus" style="margin-right: 10px"></span>{{"views.contract-list.Add new contract"| translate}}</button>
              </div>
              
          </div>
          <div style="display: flex;margin-top: 50%;">
            <app-terms-conditions></app-terms-conditions>
        </div>
    </div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/common.service';
import { PermissionService } from 'src/app/permission.service';
import { ServiceManagementService } from '../../service-management.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.css']
})
export class AddServiceComponent implements OnInit {
  newservicerequestForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.pattern(/^[\w\d\s-']+$/)]),
    technicalId: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9]+$/)]),
    description: new FormControl('',),
    url: new FormControl('', [Validators.required, Validators.pattern(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/)]),
    uuId: new FormControl('', [Validators.required, Validators.pattern(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)]),
    appId: new FormControl('', [Validators.required, Validators.pattern(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)]),
    appOwner: new FormControl('', [Validators.pattern(/^[a-zA-Z0-9À-힣.!#$%&'*/=?^_`{|}~-]+@[a-zA-Z0-9À-힣](?:[a-zA-Z0-9À-힣-]{0,61}[a-zA-Z0-9À-힣])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)]),
    //technicalLead: new FormControl('', Validators.pattern(/^[a-zA-Z0-9À-힣.!#$%&'*/=?^_`{|}~-]+@[a-zA-Z0-9À-힣](?:[a-zA-Z0-9À-힣-]{0,61}[a-zA-Z0-9À-힣])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)),
    fss: new FormControl(null),
    memberFirmHA: new FormControl(null),
    onboardedDate: new FormControl(),
    contactEmail: new FormControl('', Validators.pattern(/^[a-zA-Z0-9À-힣.!#$%&'*/=?^_`{|}~-]+@[a-zA-Z0-9À-힣](?:[a-zA-Z0-9À-힣-]{0,61}[a-zA-Z0-9À-힣])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)),
  });
  uniqueValue;
  baseHref: string;
  memberFirmHAs = ['Brazil', 'Africa', 'Australia','Austria','Belgium','Canada/Chile','CBC','Central Europe','China','CIS','Cyprus','Denmark','DTTL',
                            'Finland','France','Germany','Greece','Iceland','India','Ireland','Israel','Italy','Japan','Korea','Luxembourg','Malta','Middle East','New Zealand','Norway','Netherlands','Portugal',
                            'S-LATAM','Southeast Asia','Spain','Sweden','Switzerland','Taiwan','Turkey','United Kingdom ','United States (US)'];
  fsss = ['Advisory', 'Audit', 'Consulting', 'Tax', 'Others']; 
  uniqueValueuuid: Object;
  uniqueValueappid: Object;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    width: 'auto',
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'fontName',
      ],
      ,
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
    customClasses: [],
  };
  constructor(private servicemanagementservice: ServiceManagementService , private translateService: TranslateService , private commonService: CommonService , private permission: PermissionService , private router: Router) { }
  update($event)
  {
  }
  getServiceDetails() {
   }
  checkExistingServiceName(name: any) {
   }
  ngOnInit(): void {
  }
  submit()
  {
    //const onboardedDate = new Date(this.newservicerequestForm.value.onboardedDate).getTime();
    //this.newservicerequestForm.get('onboardedDate').setValue(new Date(onboardedDate));
    //if(this.newservicerequestForm.get('requestable').value === '' || this.newservicerequestForm.get('onboardedDate').value === undefined)
    //{
    //  this.newservicerequestForm.get('requestable').setValue(false);
    //}
    const formValue = { ...this.newservicerequestForm.value };
    Object.keys(formValue).forEach(key => {
      if (formValue[key] === null) {
        formValue[key] = ''; // Replace null with an empty string
      }
    });

    this.servicemanagementservice.saveService(formValue).subscribe(data =>
      {
        this.router.navigate(['services/list'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
      })

  }
  
  onSubmit(serviceForm)
  {
  }
  authorized() {

    return this.permission.isAdmin ;
  }
  checkUniquetechnicalidValue() {
    const e = this.newservicerequestForm.value.technicalId;
    this.servicemanagementservice.uniqueValue(e, '').subscribe(data => {
       ;
      this.uniqueValue = data;

    },
      err => {
      }
    );
  }
  checkUUID() {
    const uuId = this.newservicerequestForm.value.uuId;
    this.servicemanagementservice.uniqueUUIDValue(uuId, '').subscribe(data => {
       ;
      this.uniqueValueuuid = data;

    },
      err => {
      }
    );
  }
  checkAppID() {
    const appId = this.newservicerequestForm.value.appId;
    this.servicemanagementservice.uniqueappidValue(appId, '').subscribe(data => {
       ;
      this.uniqueValueappid = data;

    },
      err => {
      }
    );
  }
}

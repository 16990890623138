import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookienotice',
  templateUrl: './cookienotice.component.html',
  styleUrls: ['./cookienotice.component.css']
})
export class CookienoticeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

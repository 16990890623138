import { Component, OnInit } from '@angular/core';
import { PermissionService } from 'src/app/permission.service';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
@Component({
  selector: 'app-batch',
  templateUrl: './batch.component.html',
  styleUrls: ['./batch.component.css']
})
export class BatchComponent implements OnInit {
  showhelp = false;
  selectedOrgName: any;
  //create our array
  alerts = [];
  selected = false;
  selectedfile = {};
  showprogress = false;
  progressmax = 0;
  progressvalue = 0;
  progresswidth ;
  constructor(private translateService: TranslateService , private commonService: CommonService , private permission: PermissionService , private router: Router) { }
  closeAlert(index) {
    this.alerts.splice(index, 1);
  }
  ngOnInit(): void {
    this.commonService.getCurrentOrg().subscribe(
      data => {
        this.commonService.selectedOrg = data.body;
        this.selectedOrgName = data.body.name;
        this.commonService.token = data.headers.get('X-CSRF-TOKEN');
      }, error => {}
    );
  }

  authorized() {
    return this.permission.isAdmin || this.permission.isOrganizationAdmin || this.permission.isHelpDeskAdmin;
  }
  download()
  {
    window.location.href = '/dis/assets/resources/Batch_upload_template.csv';
  }
  selectfile(event)
  {
    const files = event.target.files;
    this.alerts = [];
    this.showprogress = false;
    if (files && files.length && files[0].type === 'text/csv' ) {
      if (files[0].size < 1048576) {
          this.selectedfile = files[0];
          this.selected = true;
      } else {
        this.selected = false;
        this.translateService.get('views.js.alerts.user.Selected file exceeds maximum file size of 1MB.').subscribe(res => {
          //our alert message 
          this.alerts.push({type: 'danger', msg: res});
        });
      }
  } else {
    this.selected = false;
    this.translateService.get('views.js.alerts.organizations.Selecting file failed, make sure you choose a .csv file.').subscribe(res => {
      this.alerts.push({type: 'danger', msg: res});
    });
  }
  }
  upload()
  {
    this.alerts = [];
    this.progressmax = this.selectedfile[`size`];
    this.progressvalue = 0;
    this.progresswidth = '0';
    this.showprogress = true;
    this.commonService.batchUploadModule(this.selectedfile).subscribe(
      (event: HttpEvent<Blob>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            const percentDone = Math.round(100 * event.loaded / event.total);
            if (event.loaded > this.progressmax) {
              this.progressmax = event.loaded;
            }
            this.progressvalue = event.loaded;
            this.progresswidth = (this.progressvalue / this.progressmax) * 100 + '%' ;
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            break;
          case HttpEventType.Response:
            this.translateService.get('views.js.alerts.user.Uploaded file. An email will be sent once the changes have been processed.').subscribe(res => {
              this.alerts.push({type: 'info', msg: res});
            });
            this.selectedfile = '';
            this.selected = false;
        }
      },
      (err: HttpErrorResponse) => {
        this.translateService.get('views.js.alerts.user.An error occured while uploading the file.').subscribe(res => {
          this.alerts.push({type: 'danger', msg: res});
        });
      }
    );
  }

}

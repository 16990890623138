import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-federation-update-details',
  templateUrl: './federation-update-details.component.html',
  styleUrls: ['./federation-update-details.component.css']
})
export class FederationUpdateDetailsComponent implements OnInit,AfterViewInit,OnDestroy{
  federationForm: FormGroup;
  alerts: any[] = []; 
  clientDetails: any;
  federationDetails: any;
  clientData: any[] = [];
  emptymessage: string;
  clientFormFlag = false;;
  showButton = false;
  initialFormValues: any;
  updatedFormFields:any ={};
  private unsubscribe$ = new Subject<void>();
changes= [];
  constructor(private translateService: TranslateService,private formBuilder: FormBuilder, private commonService: CommonService, private router: Router) { }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  ngOnInit(): void {
    this.federationForm = this.formBuilder.group({
      federatedclientname: new FormControl('', [Validators.required, Validators.pattern(/^[^>+\:\;|\/*^$%@\(\)\{\}\[\]!\\~=#*]+$/)]),
      searchclientname:new FormControl(''),
      clientdomains: new FormControl('', [Validators.required]),
      clienthelpdeskemail: new FormControl('', [Validators.pattern(/^[a-zA-Z0-9._%-\'. \-_!#^~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]),
      clienthelpdeskphone: new FormControl('', [Validators.pattern(/^\+[1-9]\d{7,14}$/)]),
      clientIAMemail: new FormControl('', [Validators.pattern(/^[a-zA-Z0-9._%-\'. \-_!#^~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]),
      clientIAMphone: new FormControl('', [Validators.pattern(/^\+[1-9]\d{7,14}$/)]),
      _id:['']
    });
    
  }
  formChangeVal(field)
  {
    this.alerts =[];
    const updatedVal=this.federationForm.get(field).value;
    if(this.updatedFormFields!=null ||this.updatedFormFields!=undefined)
      {
      if (updatedVal!=null &&updatedVal!== this.federationDetails[field]) {
        if(field === 'clientdomains')
          {
            const onlychangeddomains = this.getUpdatedParts(this.federationDetails[field],updatedVal);
            if(onlychangeddomains!=null&&onlychangeddomains.length==0)
              {
                this.changes = this.updateOrAddChange(this.changes,field,updatedVal)
              }
              else
              {
                for (const item of onlychangeddomains) {
                  this.commonService.getFederatedClientByDomain(item).subscribe(data=>{
                    if(data.status ==2)
                      {
                        this.translateService.get('views.federated.Domains already exists').subscribe(res => {
                          this.alerts.push({type: 'danger', msg:item+" "+res});
                          this.federationForm.setErrors({ 'invalid': true });

                        });
                      }
                      else if(data.status==200)
                      {
                        this.federationForm.setErrors({ 'invalid': false });
                        this.changes = this.updateOrAddChange(this.changes,field,updatedVal)
                      }
                  },error=>{
                    this.alerts.push({type: 'danger', msg: error.status});

                  }
                )
                }
              }
          }
          else
          {
            this.changes = this.updateOrAddChange(this.changes,field,updatedVal)
      }
      }
  }
  }
  updateOrAddChange(changesArray, field, newValue) {
    let itemExists = false;

    // Loop through the changesArray to find and update the item
    for (let item of changesArray) {
        if (item.field === field) {
            item.value = newValue;
            itemExists = true;
            break;
        }
    }

    // If the item was not found, push a new item to the array
    if (!itemExists) {
        changesArray.push({
            operation: "replace",
            field: field,
            value: newValue
        });
    }
    return changesArray;

}
  ngAfterViewInit() {
    
    }
  showClientDetails(selected)
  {
    this.federationDetails = selected;
    this.federationForm.get('federatedclientname').setValue(this.federationDetails.federatedclientname);
    this.federationForm.get('clientdomains').setValue(this.federationDetails.clientdomains);
    this.federationForm.get('clienthelpdeskemail').setValue(this.federationDetails.clienthelpdeskemail);
    this.federationForm.get('clienthelpdeskphone').setValue(this.federationDetails.clienthelpdeskphone);
    this.federationForm.get('clientIAMemail').setValue(this.federationDetails.clientIAMemail);
    this.federationForm.get('clientIAMphone').setValue(this.federationDetails.clientIAMphone);
    this.federationForm.get('_id').setValue(this.federationDetails._id);
    this.initialFormValues = this.federationForm.value;
    this.updatedFormFields = this.initialFormValues;

    this.clientFormFlag=true;
    this.clientData = [] ;
  }
  closeAlert(index) {
    this.alerts.splice(index, 1);
  }
  fetchClient(clientName: string) {
    if(clientName==""||clientName==undefined)
    {
        this.emptymessage ="";
        this.clientFormFlag=false;
        this.clientData=[];
    }
    else
    {
    this.commonService.getFederations(clientName.toLowerCase()).subscribe((action: any[]) => {
      if(action.length==0)
      {
        this.emptymessage ="Federated / B2B Client not found";
        this.clientData=[];
        this.clientFormFlag=false;
      }
      else
      {
        this.clientData=action;
        this.clientFormFlag=false;
      }
    });
}


  }
   getUpdatedParts(oldValue: string | null, newValues: string | null): string[] {
    // Check for null or empty inputs
    if (!oldValue || !newValues) {
      if(oldValue ==null)
        {

          const newValuesArray = newValues.includes(',') ? newValues.split(',').map(val => val.trim()) : [newValues.trim()];  
          return newValuesArray;

        }
        else
        {
      return [];
        }
    }
    const oldValuesArray = oldValue.includes(',') ? oldValue.split(',').map(val => val.trim()) : [oldValue.trim()];
    const newValuesArray = newValues.includes(',') ? newValues.split(',').map(val => val.trim()) : [newValues.trim()];  
    
    const updatedParts = newValuesArray.filter(newVal => !oldValuesArray.includes(newVal));
  
    return updatedParts;
  }
  update(form: FormGroup, details: any) { 
    const id= this.federationForm.get('_id').value;
        if (this.changes.length > 0) {
          this.commonService.updateFederation(id,this.changes).subscribe(data => {
                this.federationDetails = {};
                this.federationForm.get('searchclientname').setValue('');
                this.alerts=[];
                this.clientFormFlag=false;
                this.clientData =[];
                this.changes =[];
              
          });
        } else {
          this.alerts.push({type: 'danger', msg: "No changes to update"});
        }
  }
  gotoHomePage() {
    this.commonService.titleSub = 'a new';
    this.router.navigate([''])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }

  gotoNewFedratedPage() {
    this.commonService.titleSub = 'a new';
    this.router.navigate(['federation/new'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }
}
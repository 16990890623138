<div id="organizationNew" *ngIf="authorized()">
    <ol class="breadcrumb breadcrumb-dis">
        <li><a [routerLink]="['']" >{{'views.organization.Home' | translate }}</a></li>
        <li><a (click)="gotoOrganizationListPage()" >{{'views.organization.Organization list' | translate }}</a></li>
        <li class="active">{{'views.organization.New organization' | translate }}</li>
        <li class="logout"><a href="./oidc/logout">{{'views.organization.Log out' | translate }}</a></li>
    </ol>    
    <div class="titleicon"><img src="assets/images/homepage/organizations.png" alt="organization management"/></div>
    <h1>{{'views.organization.New organization' | translate }}</h1>
    <div *ngFor = "let alert of alerts">
        <p class="alert " [ngClass]="{'alert-danger' : alert.type=='danger','alert-info' : alert.type=='info'}">
            {{alert.msg}}
            <i  (click)="closeAlert(i)" aria-hidden="true" class="fa fa-times" style="
             float:right;
    font-size: 22px; "></i>
        </p>
    </div>
      <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="panel-body">
            <form [formGroup]="organizationRequest" (ngSubmit)="onSubmit(organizationRequest)" class="form-horizontal" role="form">
                <div class="form-group" show-errors>
                    <label for="organization.name" class="col-sm-2 control-label ">{{'views.organization.Name' | translate }}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="organization.name" name="name" formControlName="name" required />
                    </div>
                    <div class="col-sm-3" *ngIf="organizationRequest.controls.name.errors!=null">
                        <p class="error-block" *ngIf="organizationRequest.controls.name.errors.required && organizationRequest.controls.name.touched">{{'views.public.otpmail.Required'| translate}}</p>
                        <p class="error-block" *ngIf="!(organizationRequest.controls.name.errors.required) && organizationRequest.controls.name.status == 'INVALID' &&organizationRequest.controls.name.touched">{{'views.service.Invalid Input' | translate}}</p>
                    </div>
                </div>
                <div class="form-group" show-errors>
                    <label for="organization.description" class="col-sm-2 control-label ">{{'views.organization.Description' | translate }}</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="organization.description" name="description" formControlName="description"/>
                    </div>
                </div>
                <div class="form-group" *ngIf="authorizedActivationParam()" show-errors>
                    <label for="organization.emailLinkValidityDurationHours" class="col-sm-2 control-label ">{{'views.organization.Email activation link validity duration (hours)' | translate }}</label><!-- TODO Mathijs, can we make this column wider?-->
                    <div class="col-sm-7">
                        <input type="text"  (keydown)="numericOnly($event)"  class="form-control" id="organization.emailLinkValidityDurationHours" min=1 name="emailLinkValidityDurationHours" formControlName="emailLinkValidityDurationHours"/>
                    </div>
                    <div class="col-sm-3">
                        <p class="error-block" *ngIf="organizationRequest.controls['emailLinkValidityDurationHours'].status == 'INVALID' && organizationRequest.controls.emailLinkValidityDurationHours.touched">{{'views.organization.Please enter a value equal or greater than 1.'| translate }}</p>
                    </div>
                </div>
                <div class="form-group" show-errors>
                    <label for="organization.tenantName" class="col-sm-2 control-label ">{{'views.organization.Tenant Name' | translate }}</label>
                    <div class="col-sm-7">
                        <select formControlName="tenantName"
                        required
                        class="ng-touched caim-select-field ng-valid ng-not-empty ng-dirty ng-valid-parse input">
                        <option [ngValue]="null" selected>-Select-</option>
                        <option *ngFor="let tenantName of tenantNames" [ngValue]="tenantName">{{tenantName}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group" show-errors>
                    <label  for="organization.contactEmail" class="col-sm-2 control-label ">
                    {{'views.organization.Contact email'| translate}}</label>
                    <div class="col-sm-7">
                        <span class="input-group">
                            <span class="input-group-addon">&#64;</span>
                            <input type="text" class="form-control" 
                                   name="contactEmail" id="organization.contactEmail" 
                                   formControlName="contactEmail"
                            />
                        </span>
                    </div>
                    <div class="col-sm-3">
                        <p class="error-block" *ngIf="organizationRequest.controls['contactEmail'].hasError('pattern') && organizationRequest.controls.contactEmail.touched">{{'views.organization.The email address is invalid.'| translate}}</p>
                    </div>
                </div>
                <div class="form-group" *ngIf="authorizedActivationParam()">
                    <div class="col-sm-offset-2 col-sm-10">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" id="sendActivationEmail" name="requestable" formControlName="sendActivationEmail"/>
                                <span class="">
                                {{'views.organization.Send the activation e-mail to the requester.'| translate }}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="authorizedEmailOtpParam()">
                    <div class="col-sm-offset-2 col-sm-10">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" id="organization.emailOtp" name="requestable" formControlName="emailOtp"/>
                                <span class="">
                                {{"views.public.pwdset.ApprovedEmailOTP" | translate }}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-2"></div>

                <div class="form-group" *ngIf="authorized()">
                    <div class="col-sm-offset-2 col-sm-10">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" id="organization.featureflag1" name="requestable" formControlName="featureflag1"/>
                                <span class="">
                                {{"views.home.view organizationsDetails.Featureflag1" | translate }}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="authorized()">
                    <div class="col-sm-offset-2 col-sm-10">
                        <div class="checkbox">
                            <label>
                                
                                <input type="checkbox" id="organization.featureflag2" name="requestable" formControlName="featureflag2"/>
                                <span class="">
                                {{"views.home.view organizationsDetails.Featureflag2" | translate }}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="authorized()">
                    <div class="col-sm-offset-2 col-sm-10">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" id="organization.featureflag3" name="requestable" formControlName="featureflag3"/>
                                <span class="">
                                {{"views.home.view organizationsDetails.Featureflag3" | translate }}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="authorized()">
                    <div class="col-sm-offset-2 col-sm-10">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" id="organization.featureflag4" name="requestable" formControlName="featureflag4"/>
                                <span class="">
                                {{"views.home.view organizationsDetails.Featureflag4" | translate }}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="authorized()">
                    <div class="col-sm-offset-2 col-sm-10">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" id="organization.featureflag5" name="requestable" formControlName="featureflag5"/>
                                <span class="">
                                {{"views.home.view organizationsDetails.Featureflag5" | translate }}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="authorized()">
                    <div class="col-sm-offset-2 col-sm-10">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" id="organization.featureflag6" name="requestable" formControlName="featureflag6"/>
                                <span class="">
                                {{"views.home.view organizationsDetails.Featureflag6" | translate }}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-7">
                    <div class="btn-group savebutton">
                        <button type="button"
                                class="btn btn-default"
                                (click)="submit()"
                                [attr.disabled]="organizationRequest.status === 'INVALID'? '':null">
                            <span class="glyphicon glyphicon-floppy-disk" style="margin-right: 10px"></span>{{'views.organization.Save' | translate }}</button>
                    </div>
                </div>
            </form>
            </div>
            </div>
            <div style="display: flex;margin-top: 50%;">
                <app-terms-conditions></app-terms-conditions>
            </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent implements OnInit {

  @Input() title: string = '';
  @Input() message: string = '';
  @Input() data: any; // Data to be sent with the API call

  constructor(public dialogRef: MatDialogRef<ConfirmationModalComponent>) {}
  ngOnInit(): void {
  }

  confirm() {
   this.dialogRef.close(true);
   
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
}

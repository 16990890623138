import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpClientXsrfModule, HttpXsrfTokenExtractor } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './User_Management/home/home.component';
import { UserComponent } from './User_Management/user/user.component';
import { AppRoutingModule } from './app.routing-module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AccordionModule } from 'ngx-bootstrap';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { PendingComponent } from './User_Management/pending/pending.component';
import { DataTablesModule } from 'angular-datatables';
import { AllusersComponent } from './User_Management/allusers/allusers.component';
import { PendingallusersComponent } from './User_Management/pendingallusers/pendingallusers.component';
import { NewuserComponent } from './User_Management/newuser/newuser.component';
import { TrueFalseValueDirective } from './truefalsevalue.directive';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { HttpXSRFInterceptor } from './xsrf.interceptor';
import { ConfirmSuspendDirective } from './confirm-suspend.directive';
import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ConfirmsuspendComponent } from './confirmsuspend/confirmsuspend.component';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { ConfirmActivateComponent } from './confirm-activate/confirm-activate.component';
import { UserdetailComponent } from './User_Management/userdetail/userdetail.component';
import { UserhistoryComponent } from './User_Management/History/userhistory/userhistory.component';
import { PwdsetComponent } from './pwdset/pwdset.component';
import { ErrorComponent } from './error/error.component';
import { UserrequestDetailComponent } from './User_Management/userrequest-detail/userrequest-detail.component';
import { SuccessScreenComponent } from './success-screen/success-screen.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PwdResetComponent } from './pwd-reset/pwd-reset.component';
import { OtpSetComponent } from './otp-set/otp-set.component';
import { LogoutComponent } from './logout/logout.component';
import { SelfServiceComponent } from './self-service/self-service.component';
import { BatchComponent } from './BatchUserManagement/batch/batch.component';
import { ContractListComponent } from './contract_Management/contract-list/contract-list.component';
import { ContractNewComponent } from './contract_Management/contract-new/contract-new.component';
import { RolesListComponent } from './Roles/roles-list/roles-list.component';
import { RoleAssignmentsComponent } from './Role Assignment/role-assignments/role-assignments.component';
import { AllRoleAssignmentsComponent } from './Role Assignment/all-role-assignments/all-role-assignments.component';
import { RoleNewComponent } from './Roles/role-new/role-new.component';
import { RoleAssignmentNewComponent } from './Role Assignment/role-assignment-new/role-assignment-new.component';
import { AdminRoleAssignmentNewComponent } from './Role Assignment/admin-role-assignment-new/admin-role-assignment-new.component';
import { RoleDetailsComponent } from './Roles/role-details/role-details.component';
import { DatePipe } from '@angular/common';
import { ContractDetailComponent } from './contract_Management/contract-detail/contract-detail.component';
import { AddServiceComponent } from './Service_Management/addservice/add-service/add-service.component';
import { ViewServicesComponent } from './Service_Management/viewservices/view-services/view-services.component';
import { HelpDeskComponent } from './help-desk/help-desk.component';
// import { DetailsService } from './Service_Management/details-service/details-service.component';
import { OrganizationDetailsComponent } from './Organization_Management/organizationdetails/organization-details/organization-details.component';
import { OrganizationListComponent } from './Organization_Management/organizationlist/organization-list/organization-list.component';
import { OrganizationNewComponent } from './Organization_Management/organizationnew/organization-new/organization-new.component';
import { OrganizationBatchComponent } from './Organization_Management/organizationbatch/organization-batch/organization-batch.component';
import 'hammerjs'; 
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ServiceDetailsComponent } from './Service_Management/servicedetails/service-details/service-details.component';
import { FederationNewComponent } from './Federation_Management/federation-new/federation-new.component';
import { FederationUpdateDetailsComponent } from './Federation_Management/federation-update-details/federation-update-details.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup

import { MomentModule } from 'angular2-moment';
import { RoleAssignmentDetailComponent } from './Role Assignment/role-assignment-detail/role-assignment-detail.component'; // optional, provides moment-style pipes for date formatting
import { RoleRequestsComponent } from './Role Request/role-requests/role-requests.component';
import { AllRoleRequestsComponent } from './Role Request/all-role-requests/all-role-requests.component';
import { PrivacyNoticeComponent } from './privacy-notice/privacy-notice.component';
import { TouComponent } from './tou/tou.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { AuthInterceptor } from './loading.interceptor';
import { ErrorMfaComponent } from './error-mfa/error-mfa.component';
import { PwdmailComponent } from './pwdmail/pwdmail.component';
import { CookienoticeComponent } from './cookienotice/cookienotice.component';
import { AuthGuard } from './_guards';
import { Privacynoticeb2bComponent } from './privacynoticeb2b/privacynoticeb2b.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { SortPipe } from './shared/pipes/sort.pipe';

import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { OrganizationListDialogComponentComponent } from './organization-list-dialog-component/organization-list-dialog-component.component';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/resources/', '.json');

}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UserComponent,
    PendingComponent,
    AllusersComponent,
    PendingallusersComponent,
    NewuserComponent,
    TrueFalseValueDirective,
    ConfirmSuspendDirective,
    ConfirmsuspendComponent,
    ConfirmDeleteComponent,
    ConfirmActivateComponent,
    UserdetailComponent,
    UserhistoryComponent,
    PwdsetComponent,
    ErrorComponent,
    UserrequestDetailComponent,
    SuccessScreenComponent,
    TermsConditionsComponent,
    PwdResetComponent,
    OtpSetComponent,
    LogoutComponent,
    SelfServiceComponent,
    BatchComponent,
    ContractListComponent,
    ContractNewComponent,
    BatchComponent,
    RolesListComponent,
    RoleAssignmentsComponent,
    AllRoleAssignmentsComponent,
    RoleRequestsComponent,
    AllRoleRequestsComponent,
    RoleNewComponent,
    RoleAssignmentNewComponent,
    AdminRoleAssignmentNewComponent,
    RoleDetailsComponent,
    ContractDetailComponent,
    RoleDetailsComponent,
    ViewServicesComponent,
    ServiceDetailsComponent,
    AddServiceComponent,
    HelpDeskComponent,
    OrganizationDetailsComponent,
    OrganizationListComponent,
    OrganizationNewComponent,
    OrganizationBatchComponent,
    FederationNewComponent,
    FederationUpdateDetailsComponent,
    RoleAssignmentDetailComponent,
    PrivacyNoticeComponent,
    TouComponent,
    SpinnerComponent,
    ErrorMfaComponent,
    PwdmailComponent,
    CookienoticeComponent,
    Privacynoticeb2bComponent,
    NotfoundComponent,
    SortPipe,
    ConfirmationModalComponent,
    OrganizationListDialogComponentComponent
  ],
  imports: [
    BrowserModule,
    DataTablesModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularEditorModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatTooltipModule,
    MomentModule,
    NgIdleKeepaliveModule.forRoot(),
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-CSRF-TOKEN',
    }),
    RouterModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    NgxMatSelectSearchModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatListModule,
    AppRoutingModule,
    Ng2SmartTableModule,
    MatIconModule,
    AccordionModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      useDefaultLang: true,
    })

  ],  providers: [ DatePipe,SortPipe,AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

<div class="login-page reset-password-page activate-page">
    <div ng-translate-language-pub-select></div>    
    <div class="header-self-service">
        <div class="logo-white">
            <img src="../img/self-service/deloitte-logo-white.svg">
        </div>
        <div>
             <select style='font-family:Arial, FontAwesome; '
                    ng-model="currentLocaleDisplayName" ng-options="localesDisplayName for localesDisplayName in localesDisplayNames"
                    ng-change="changeLanguage(currentLocaleDisplayName)" class="ng-dirty ng-valid-parse ng-not-empty">
                <option value="" disabled selected hidden >&#xf1ab; Language</option>
            </select>
        </div>
    </div>
    <div class="main-container  form-container-reset-password">
        <div class="form-container self-service-form-container">
            <div class="form">
                <!--
                <div class="logo-successful">
                    <img src="../img/self-service/activation-successful.svg">
                </div>
                -->
                <div class="form-title title">
                    {{"views.js.alerts.emailprocessing.The page you are trying to access has expired"| translate}}
                </div>
                <div class="form-second-description main-text ng-binding" style="margin-top: 50px; font-weight: 700; font-size: 22px; line-height: 24px">
                    {{"views.public.error.If the problem persists, please contact the Deloitte Helpdesk."| translate}}
                    <a href="mailto:dttltechnologysupport@deloitte.com" target="_blank" style="font-size: 22px; color: #3abaeb; line-height: 24px">dttltechnologysupport@deloitte.com</a> OR +1 (718) 354 -1249
                </div>

            </div>
        </div>
        <!-- <ng-include src="'../footer.html'"></ng-include> -->
    </div>
    <!-- <div class="img-container">
    </div> -->
    <app-terms-conditions></app-terms-conditions>
</div>
<div id="wrapper">
    <!-- Show warning if no JavaScript enabled -->
    <noscript>Your browser does not support JavaScript!</noscript>
    <!-- Show angular view -->
    <div class="login-page activate-page">
        <div class="header-self-service">
            <div class="logo-white">
                <img src="../../assets/img/self-service/deloitte-logo-white.svg">
            </div>
        </div>
        <div class="main-container self-service-main-container"
            style="margin-top: 100px; max-width: 700px; margin-left: 5%">
            <div class="form-container self-service-form-container" style="display:block; max-width: none">
                <div class="title">{{'views.loggedout.Logged out'| translate}}</div>
                <br />
                <p class="main-text">
                    {{'views.loggedout.You have successfully logged out.'| translate}}
                    {{'views.loggedout.Log in again by clicking'| translate}} <a href="/dis">{{'views.loggedout.here'|
                        translate}}</a>.
                </p>
            </div>
            <!-- <ng-include src="'../footer.html'"></ng-include> -->
            <app-terms-conditions></app-terms-conditions>
        </div>
        <div class="img-container self-service-img-container">
        </div>
    </div>
</div>
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { CommonService } from '../../common.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PermissionService } from '../../permission.service';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { RolesService } from 'src/app/roles-service.service';
@Component({
  selector: 'app-roles-list',
  templateUrl: './roles-list.component.html',
  styleUrls: ['./roles-list.component.css']
})
export class RolesListComponent implements OnInit, AfterViewInit {

  constructor(private commonService: CommonService, private router: Router,private permission: PermissionService,private rolesservice:RolesService) { }
  filteredAndPagedIssues = new MatTableDataSource<any>([]);
  isLoadingResults: boolean;
  alltxt = 'All';
  displayedColumns: string[] = ['status', 'name', 'service', 'action'];
  resultsLength = 0;
  originalData: any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  // @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
  //   this.paginator = mp;
  //   // this.setDataSourceAttributes();
  // }
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  ngOnInit(): void {
    // this.commonService.getCurrentUserRoles().subscribe(data => {
    //    ;
    //   this.filteredAndPagedIssues = data;
    // });
  }
  ngAfterViewInit() {
    this.apiTrigger();
    this.filteredAndPagedIssues.paginator = this.paginator;
    this.filteredAndPagedIssues.sort = this.sort;
  }
  downloadCSV()
  {
    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: false
      , 
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ['Status', 'Name', 'Service'],
      nullToEmptyString: true,
    };
    let arr =[];
      this.originalData.forEach(function (myans) {
        let obj ={};
        obj['status'] = myans.status;
        obj['name'] = myans.name;
        obj['service'] = myans.serviceId.name;
        arr.push(obj);
      });
    new Angular5Csv(arr, 'roles',options);

  }
  authorized()
  {
    return this.permission.isAdmin || this.permission.isRoleAdmin;

  }
  apiTrigger() {
    this.rolesservice.fetchAllRolesFromIDM().subscribe((data: any) => {
       ;
      this.filteredAndPagedIssues.data = data;
      this.originalData = data;
      this.resultsLength = data.length;
    });

  }
  
  showAllRecords() {
    this.filteredAndPagedIssues.paginator.pageSize = this.resultsLength;
  }
  showAllRoles()
  {
    this.paginator.pageSize = this.resultsLength;
    this.apiTrigger();
    if (this.paginator) {
      this.paginator.firstPage();
    }
  }
  suspendRole(row)
  {
    let request ={operation: "replace",
    field: "status",
    value: "suspended"
  }
  let array=[];
  array.push(request)
    this.rolesservice.updateRole(array,row._id).subscribe(data =>
      {
        this.apiTrigger();
      })
  }
  
  deleteRole(row)
  {
    
    this.rolesservice.deleteRole(row._id).subscribe(data =>
      {
        this.apiTrigger();
      })

  }
  activateRole(row)
  {
    
    let request ={operation: "replace",
      field: "status",
      value: "active"
    }
    let array=[];
    array.push(request)
      this.rolesservice.updateRole(array,row._id).subscribe(data =>
        {
          this.apiTrigger();
        })
  }
  filterData(key, value) {
    this.filteredAndPagedIssues.data = this.originalData.filter((item: any) => {
      let rowData;
      if (key === 'name') {
        rowData = item[key].toLowerCase();
        return rowData.includes(value.toLowerCase());

      } else {
        if(item.services.length>0)
        {
        rowData = item.services[0].name.toLowerCase();
        return rowData.includes(value.toLowerCase());
        }
      }
    });
  }
  resetPaging() {
    this.apiTrigger();
  }
}

<div class="loader" *ngIf="isLoading">
    <div class="dot" style="--dot-index:0;"></div>
    <div class="dot" style="--dot-index:1;"></div>
    <div class="dot" style="--dot-index:2;"></div>
  </div>
<div id="roleassignmentNew" [hidden]="isLoading">

    <ol class="breadcrumb breadcrumb-dis">
        <li><a  (click)="gotoHomePage()">{{'views.contract-detail.Home'| translate}}</a></li>
        <li><a  (click)="gotoRoleAssignmentListPage()">{{'views.roleassignment-detail.Role Assignment List'| translate}}</a></li>
        <li class="active">{{role.name}} {{'views.roleassignment-detail.role for'| translate}} {{user.givenName}}</li>
        <li class="logout"><a href="./oidc/logout">{{"views.home.Log out"| translate}}</a></li>
    </ol>
    
    <div class="titleicon"><img src="assets/images/homepage/roles.png" alt="role management"/></div>
    <h1>{{role.name}} {{'views.roleassignment-detail.role for'| translate}} {{user.givenName}} {{user.familyName}}</h1>
    
     <!--The alerts come here -->
     <div *ngFor = "let alert of alerts">
        <p class="alert " [ngClass]="{'alert-danger' : alert.type=='danger','alert-info' : alert.type=='info'}">
            {{alert.msg}}
            <i  (click)="closeAlert(i)" aria-hidden="true" class="fa fa-times" style="
            float:right;
   font-size: 22px; "></i>
        </p>
    </div>
     <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="panel-body">
            <form name="roleassignmentForm" class="form-horizontal" role="form"  [formGroup]="roleassignmentForm">
                <div class="form-group" show-errors>
                    <label for="role.startDate" class="col-sm-2 control-label ">{{'views.service.Start date'|
                        translate}}</label>
                    <!-- <div class="col-sm-7">
                        <input type="text" class="form-control" id="role.name" name="name" ng-model="role.name" required ng-pattern="/^[\w\d\s-']+$/"/>
                    </div> -->
                    
                    <div class="col-sm-7">
                        <input type="date"  class="form-control" formControlName="startDate">
                    </div>
                    <div class="col-sm-1" style="max-width: none"
                        *ngIf="roleassignmentForm.controls['startDate'].hasError('required') && roleassignmentForm.controls['startDate'].touched">
                        <span class="hint-text call-send-text">{{'views.public.otpmail.Required'| translate}}</span>
                    </div>
                </div>
                <div class="form-group" show-errors>
                    <label for="role.endDate" class="col-sm-2 control-label ">{{'views.service.End date'|
                        translate}}</label>
                    <!-- <div class="col-sm-7">
                        <input type="text" class="form-control" id="role.name" name="name" ng-model="role.name" required ng-pattern="/^[\w\d\s-']+$/"/>
                    </div> -->
                    <div class="col-sm-7">
                        <input type="date" class="form-control" formControlName="endDate"
                            >
                    </div>
                    <div class="col-sm-1" style="max-width: none"
                        *ngIf="roleassignmentForm.controls['endDate'].hasError('required') && roleassignmentForm.controls['endDate'].touched">
                        <span class="hint-text call-send-text">{{'views.public.otpmail.Required'| translate}}</span>
                    </div>
                </div>
                <div class="form-group" show-errors>
                    <label for="roleassignment.userId" class="col-sm-2 control-label ">{{"views.role.User"| translate}}</label>
                    <div class="col-sm-8">
                        <input class=" form-control" id="roleassignment.userId" name="userId"  
                               type="text" formControlName="userId" [attr.disabled]="true"/>
                    </div> 
              
    
                </div>
                <div class="form-group" show-errors>
                    <label for="roleassignment.roleId" class="col-sm-2 control-label ">{{'views.organization.Role'| translate}}</label>
                    <div class="col-sm-8">
                        <input class=" form-control" id="roleassignment.roleId" name="roleId"
                               placeholder="Search role" type="text" formControlName="roleId" 
                        [attr.disabled]="true"/>
                    </div>                
            
                </div>
                <div class="form-group" show-errors>
                    <label for="roleassignment.organizationId" class="col-sm-2 control-label ">{{'views.user.Organization'| translate}}</label>
                    <div class="col-sm-8">                        
                        <input class=" form-control" id="roleassignment.organizationId" name="organizationId"
                               placeholder="Search organization" type="text" formControlName="organizationId" 
                               [attr.disabled]="true"
                        />                        
                    </div>
                </div>
                <!-- <div class="form-group" show-errors *ngIf="serviceDetails?.technicalId==='DIAS'"> -->

                    <div class="form-group" show-errors>
                        <label for="roleassignment.targetServiceId" class="col-sm-2 control-label ">{{'views.roleassignment-detail.Target Service'| translate}}</label>
                    <div class="col-sm-8">
                        <input class=" form-control" id="roleassignment.targetServiceId" name="targetServiceId"
                               placeholder="Search service" type="text" formControlName="targetServiceId" 
                              [attr.disabled]="true"/>  
                    </div>
                </div>                 
                <div class="col-sm-2"></div>
                <div class="col-sm-8">
                    <div class="btn-group savebutton">
                        <button type="button"
                                class="btn btn-default"
                                (click)="submit()"
                                [attr.disabled]="roleassignmentForm.status === 'INVALID'? '':null"
                            >
                            <span class="glyphicon glyphicon-floppy-disk" style="margin-right: 10px"></span>{{'views.user.Save'| translate}}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>    
</div>

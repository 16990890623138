import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CommonService } from '../common.service';
import { PermissionService } from '../permission.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-help-desk',
  templateUrl: './help-desk.component.html',
  styleUrls: ['./help-desk.component.css']
})
export class HelpDeskComponent implements OnInit {
  user ;
  datasource: MatTableDataSource<any>;
  // userHistorydisplayedColumns: string[] = ['historyTs','status', 'firstname', 'lastname', 'uid', 'email', 'mobile','telephone','mainLanguage','eidFingerprint','employeenumber','shortusername','masterSource','photourl','createdOn','createdBy','modifiedOn','modifiedBy'];
  // orghistorydisplayedcolumns: string[]  = ['historyTs','organization', 'createdOn', 'createdBy', 'modifiedOn', 'modifiedBy'];
  // rolehistorydisplayedcolumns: string[]  = ['historyTs','role', 'createdOn', 'createdBy', 'modifiedOn', 'modifiedBy'];
  // emailhistorydisplayedcolumns: string[]  = ['sentOn','validUntil', 'initiatedBy', 'emailType', 'status'];
  tenantValue ='';
  // @ViewChild('userhistorypage') userhistorypage: MatPaginator;
  // @ViewChild('orghistorypage') orgpaginator: MatPaginator;
  // @ViewChild('rolehistorypage') rolepaginator: MatPaginator;
  // @ViewChild('emailhistorypage') emailpaginator: MatPaginator;
  historydisplayedColumns: string[] = ['email', 'createdOn', 'modifiedOn', 'modifiedBy', 'changedField', 'initialValue', 'changedValue'];
  orgdisplayedColumns: string[] = ['organization', 'createdOn', 'modifiedOn', 'modifiedBy', 'changedField', 'initialValue', 'changedValue'];
  roledisplayedColumns: string[] = ['role', 'createdOn', 'modifiedOn', 'modifiedBy', 'changedField', 'initialValue', 'changedValue'];

  @ViewChild(MatTable) table: MatTable<any>;

  userHistorydataSource: MatTableDataSource<any>;
  orgHistorydataSource: MatTableDataSource<any>;
  roleuserHistorydataSource: MatTableDataSource<any>;
  // emailHistoryData: MatTableDataSource<any>;
  resultsLength = 0;
  userHistoryData;
  historycount;
  organizationhistorycount;
  userroleshistorycount;
  orgHistoryData;
  roleHistoryData;
  uniqueValue ;
  @ViewChild('paginatorroles') paginatorroles: MatPaginator;
  @ViewChild('paginatoruser') paginatoruser: MatPaginator;
  @ViewChild('paginatororg') paginatororg: MatPaginator;  
  @ViewChild(MatSort) sort: MatSort;


  id: number;
  photourl: any;
  resetPasswordButtonClicked: boolean;
  infoalert = false;
  msg: any;
  dangeralert = false;
  dangermsg: any;
  infomsg: any;
  alerts =[];
  registerOtpButtonClicked  = false;
  userrequestForm: FormGroup = new FormGroup({
    userType: new FormControl('external', Validators.required),
    firstname: new FormControl('', [Validators.required, Validators.pattern(/^[^>+\:\;|\/*^$%@\(\)\{\}\[\]!\\~=#*]+$/), ]),
    lastname: new FormControl('', [Validators.required, Validators.pattern(/^[^>+\:\;|\/*^$%@\(\)\{\}\[\]!\\~=#*]+$/), ]),
    mainLanguage: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9À-힣.!#$%&'*/=?^_`{|}~-]+@[a-zA-Z0-9À-힣](?:[a-zA-Z0-9À-힣-]{0,61}[a-zA-Z0-9À-힣])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/), ]),
    mobile: new FormControl({ value: '', disabled: false }),
  });
  userRolesHistoryData: any[] = []; // Replace with your actual data
  // displayedRoleColumns: string[] = ['historyTs', 'status', 'role', 'createdOn', 'createdBy', 'modifiedOn', 'modifiedBy'];

  emailForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%-\'. \-_!#^~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/), ]),
  });

  open: boolean = true;
  closeAlert(index) {
    this.alerts.splice(index, 1);
  }
  gotoHomePage()
  {
    this.router.navigate([''])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }
  
retrieveLDSAttributes() {
  // const email = this.emailForm.get('email').value;
  const email = this.emailForm.get('email').value!=""?(this.emailForm.get('email').value).toLowerCase():"";
  this.commonService.helpdeskUsersbymail(email).subscribe(data =>
    {
      this.user = data;
      if(data!=null)
      {
      this.commonService.fetchHelpdeskUserTenantName(this.user.email).subscribe(data =>
        {
          if(data)
          this.tenantValue = data.response.toLowerCase();
        })
      this.userrequestForm.get('firstname').setValue(this.user.firstname);
      this.userrequestForm.get('lastname').setValue(this.user.lastname);
      this.userrequestForm.get('email').setValue(this.user.email);
      this.userrequestForm.get('mobile').setValue(this.user?.mobile);
      this.userrequestForm.get('mainLanguage').setValue(this.user?.mainLanguage);
      this.getHistoryAPI();
   
      // this.emailHistoryAPI();
      if (this.user.photourl !== undefined
      && this.user.photourl !== null) {
  const url = this.user.photourl;
  this.photourl = url.replace('/gsiweb', '');
} else {
  this.photourl = 'assets/images/icons/empty_user.png';
}
      }
      else
      {
        this.alerts.push({type: 'danger', msg: "No User available with searched email"});

      }
    });
  
}
getHistoryAPI()
{
  const userID =this.user.id;
  let userData=[];
  let rolesData=[];
  let orgData=[];
  this.commonService.userHistoryDetails(userID).subscribe(data =>{
    const resultresp=data.result;
    if(resultresp.length>0)
    {
      resultresp.forEach(dataresp =>{
        if(dataresp.operationType=="UserUpdate")
        {
          if (dataresp.changedField && dataresp.changedField.length) {
            for (let i = 0; i < dataresp.changedField.length; i++) {
              userData.push({
                email: dataresp.mail,
                createdOn: dataresp.createdOn,
                modifiedOn: dataresp.modifiedOn,
                modifiedBy: dataresp.modifiedBy,
                changedField: dataresp.changedField[i],
                initialValue: dataresp.initialValue[i] || null, // Handle null or undefined
                changedValue: dataresp.changedValue[i] || null
              });
            }
          }
        }
        else  if(dataresp.operationType=="OrganizationUpdate")
          {
            if (dataresp.changedField && dataresp.changedField.length) {
              for (let i = 0; i < dataresp.changedField.length; i++) {
                orgData.push({
                  organization: dataresp.organization,
                  createdOn: dataresp.createdOn,
                  modifiedOn: dataresp.modifiedOn,
                  modifiedBy: dataresp.modifiedBy,
                  changedField: dataresp.changedField[i],
                  initialValue: dataresp.initialValue[i] || null, // Handle null or undefined
                  changedValue: dataresp.changedValue[i] || null
                });
              }
            }
          }
          else  if(dataresp.operationType=="RoleUpdate")
            {
              if (dataresp.changedField && dataresp.changedField.length) {
                for (let i = 0; i < dataresp.changedField.length; i++) {
                  rolesData.push({
                    role: dataresp.role,
                    createdOn: dataresp.createdOn,
                    modifiedOn: dataresp.modifiedOn,
                    modifiedBy: dataresp.modifiedBy,
                    changedField: dataresp.changedField[i],
                    initialValue: dataresp.initialValue[i] || null, // Handle null or undefined
                    changedValue: dataresp.changedValue[i] || null
                  });
                }
              }
            }
      });
      this.userHistoryData= userData.sort((a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime());
       this.historycount = this.userHistoryData.length;
        this.userHistorydataSource = new MatTableDataSource(this.userHistoryData);
        this.userHistorydataSource.paginator = this.paginatoruser;
        this.userHistorydataSource.sort = this.sort;
        this.orgHistoryData= orgData.sort((a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime());
        this.organizationhistorycount = this.orgHistoryData.length;
        this.orgHistorydataSource = new MatTableDataSource(this.orgHistoryData);
        this.orgHistorydataSource.paginator = this.paginatororg;
        this.orgHistorydataSource.sort = this.sort;
        this.roleHistoryData= rolesData.sort((a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime());
        this.userroleshistorycount = this.roleHistoryData.length;
   this.roleuserHistorydataSource = new MatTableDataSource(this.roleHistoryData);
   this.roleuserHistorydataSource.paginator = this.paginatorroles;
   this.roleuserHistorydataSource.sort = this.sort;
    }
    else
    {

    }
  })
}
resetPassword()
{
  if (this.user.status == "suspended") {
    this.infoalert = false;
    this.dangeralert = true;
    this.translate.get('views.js.alerts.user.ResetSuspendedMsg').subscribe(res => {
    this.alerts.push({type: 'danger', msg: res});
  });
} else {
  // const email=this.userrequestForm.get('email').value;
  const email = this.userrequestForm.get('email').value!=""?(this.userrequestForm.get('email').value).toLowerCase():"";
  this.resetPasswordButtonClicked = true;
  this.commonService.resetPassword(email).subscribe(data =>
    {
      if (data.value === 'true')
      {
        this.infoalert = true;
        this.dangeralert = false;
        this.translate.get('views.js.alerts.user.The reset password email has been sent.').subscribe(res => {
          this.alerts.push({type: 'info', msg: res});

      });
      }
      if (data.value === 'false')
      {
        this.infoalert = false;
        this.dangeralert = true;
        this.translate.get('views.js.alerts.user.ResetTooEarlyMsg').subscribe(res => {
          this.alerts.push({type: 'danger', msg: res});
        });
      }
    }, err => {
      this.infoalert = false;
      this.dangeralert = true;
      this.dangermsg = err.status;
    }
    );
  }
}
test()
{
}
registerOtp()
{
  if (this.user.status == "suspended") {
    this.infoalert = false;
    this.dangeralert = true;
    this.translate.get('views.js.alerts.user.AuthAppLinkSuspendedMsg').subscribe(res => {
    this.alerts.push({type: 'danger', msg: res});
  });
} else {
  // const email=this.userrequestForm.get('email').value;
  const email = this.userrequestForm.get('email').value!=""?(this.userrequestForm.get('email').value).toLowerCase():"";

  this.registerOtpButtonClicked = true;
  this.commonService.registerAuthApp(email).subscribe(data => {
  this.infoalert = true;
  this.dangeralert = false;
  this.translate.get('views.js.alerts.user.An email is sent to the user with an authenticator app registration link.').subscribe(res => {
    this.alerts.push({type: 'info', msg: res});
   });
  });
}
}

constructor(private translate: TranslateService, private router: Router, private commonService: CommonService, private route: ActivatedRoute, private permission: PermissionService) { }
ngOnInit(): void {
}

authorized() {

  return this.permission.isAdmin || this.permission.isHelpDeskAdmin;
}


// emailHistoryAPI()
// {
//   const email = this.emailForm.get('email').value;
//   this.commonService.emailHistoryBymail(email).subscribe((data: any) => {
//     this.emailHistoryData = new MatTableDataSource(data);
//     this.emailhistorycount = data.length;
//     this.emailORiginalData = data;
//     this.emailHistoryData.paginator = this.emailpaginator;
//     this.emailHistoryData.sort = this.sort;
//  });
// }

}

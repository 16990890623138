import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacynoticeb2b',
  templateUrl: './privacynoticeb2b.component.html',
  styleUrls: ['./privacynoticeb2b.component.css']
})
export class Privacynoticeb2bComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

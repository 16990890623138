import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private _isAdmin: boolean ;
  public get isAdmin(): boolean {
    return this._isAdmin;
  }
  public set isAdmin(value: boolean) {
    this._isAdmin = value;
  }
  private _isRoleAdmin: boolean ;
  public get isRoleAdmin(): boolean {
    return this._isRoleAdmin;
  }
  public set isRoleAdmin(value: boolean) {
    this._isRoleAdmin = value;
  }
  private _isRoleAssignmentAdmin: boolean ;
  public get isRoleAssignmentAdmin(): boolean {
    return this._isRoleAssignmentAdmin;
  }
  public set isRoleAssignmentAdmin(value: boolean) {
    this._isRoleAssignmentAdmin = value;
  }
  private _isContractAdmin: boolean ;
  public get isContractAdmin(): boolean {
    return this._isContractAdmin;
  }
  public set isContractAdmin(value: boolean) {
    this._isContractAdmin = value;
  }
  private _isUserAdmin: boolean ;
  public get isUserAdmin(): boolean {
    return this._isUserAdmin;
  }
  public set isUserAdmin(value: boolean) {
    this._isUserAdmin = value;
  }
  private _isBatchAdmin: boolean ;
  public get isBatchAdmin(): boolean {
    return this._isBatchAdmin;
  }
  public set isBatchAdmin(value: boolean) {
    this._isBatchAdmin = value;
  }
  private _isOrganizationAdmin: boolean ; public get isOrganizationAdmin(): boolean {
    return this._isOrganizationAdmin;
  }
  public set isOrganizationAdmin(value: boolean) {
    this._isOrganizationAdmin = value;
  }
  private _isGroupAdmin: boolean ;
  public get isGroupAdmin(): boolean {
    return this._isGroupAdmin;
  }
  public set isGroupAdmin(value: boolean) {
    this._isGroupAdmin = value;
  }
  private _isGroupAssignAdmin: boolean ;
  public get isGroupAssignAdmin(): boolean {
    return this._isGroupAssignAdmin;
  }
  public set isGroupAssignAdmin(value: boolean) {
    this._isGroupAssignAdmin = value;
  }
  private _isServiceAdmin: boolean ;
  public get isServiceAdmin(): boolean {
    return this._isServiceAdmin;
  }
  public set isServiceAdmin(value: boolean) {
    this._isServiceAdmin = value;
  }
  private _isHelpDeskAdmin: boolean ;
  public get isHelpDeskAdmin(): boolean {
    return this._isHelpDeskAdmin;
  }
  public set isHelpDeskAdmin(value: boolean) {
    this._isHelpDeskAdmin = value;
  }
  private _isStatsAdmin: boolean ;
  public get isStatsAdmin(): boolean {
    return this._isStatsAdmin;
  }
  public set isStatsAdmin(value: boolean) {
    this._isStatsAdmin = value;
  }
  private _isSPregistrarAdmin: boolean ;
  public get isSPregistrarAdmin(): boolean {
    return this._isSPregistrarAdmin;
  }
  public set isSPregistrarAdmin(value: boolean) {
    this._isSPregistrarAdmin = value;
  }

  constructor(private http: HttpClient, private commonService: CommonService) {

  }
  getRoles() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${this.commonService.getAccessToken()}`
    });
    const requestOptions = { headers };
    return this.http.get(this.commonService.baseHref + '/dis/rest/currentuser/roles', requestOptions);
  }
  userCookie(request)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${this.commonService.token}`
    });
    const requestOptions = { headers };
    return this.http.post<any>(this.commonService.baseHref + '/dis/rest/loginSession', request, requestOptions);
  }

}

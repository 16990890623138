import { CoreEnvironment } from '@angular/compiler/src/compiler_facade_interface';
import { Component, OnInit } from '@angular/core';
import { AuthorizationRequest, AuthorizationServiceConfiguration, 
  BasicQueryStringUtils, DefaultCrypto, FetchRequestor, LocalStorageBackend, 
  RedirectRequestHandler } from '@openid/appauth';
import CryptoJS from 'crypto-js';
import { CONFIG } from '../config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor() { }

  base64URLEncode(words: CryptoJS.lib.WordArray): string {
    return CryptoJS.enc.Base64.stringify(words)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
 }
  ngOnInit(): void {
    const ssoConfig = CONFIG.getSSOConfig();
    const authorizationHandler = new RedirectRequestHandler(new LocalStorageBackend(), new BasicQueryStringUtils(), window.location, new DefaultCrypto()
    );
    AuthorizationServiceConfiguration.fetchFromIssuer(
      ssoConfig.auth_endpoint, new FetchRequestor()
    ).then((response) => {  
      const authRequest = new AuthorizationRequest({
        client_id: ssoConfig.client_id,
        redirect_uri: ssoConfig.redirect_uri,
        scope: ssoConfig.scope,
        response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
        state: undefined
      });
      authorizationHandler.performAuthorizationRequest(
        response, authRequest
      );
    })
      .catch((error) => {
        //
      });
  }
}

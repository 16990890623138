
import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class ExportExcelService {

  constructor() {}

  generateExcelForUsers(data: any[], fileName: string): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('User Data');

    const headers = ['Status', 'Email', 'First Name', 'Last Name'];
    worksheet.addRow(headers);

    // Add data rows
    data.forEach((user) => {
      worksheet.addRow([user.status, user.email, user.firstname, user.lastname]);
    });

    // Save the workbook as an Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${fileName}.xlsx`);
    });
  }
  generateExcelForPendingUsers(data: any[], fileName: string): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Pernding Users Data');

    const headers = ['Status', 'Email', 'First Name', 'Last Name'];
    worksheet.addRow(headers);

    // Add data rows
    data.forEach((user) => {
      worksheet.addRow([user.status, user.email, user.firstname, user.lastname]);
    });

    // Save the workbook as an Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${fileName}.xlsx`);
    });
  }
  generateExcelForAllUsers(data: any[], fileName: string): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('All Users Data');

    const headers = ['Status', 'Email', 'First Name', 'Last Name'];
    worksheet.addRow(headers);

    // Add data rows
    data.forEach((user) => {
      worksheet.addRow([user.status, user.email, user.firstname, user.lastname]);
    });

    // Save the workbook as an Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${fileName}.xlsx`);
    });
  }
  
  generateExcelForOrganizations(data: any[], fileName: string): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Org Data');

    const headers = ['Status', 'Name', 'Technical ID'];
    worksheet.addRow(headers);

    // Add data rows
    data.forEach((org) => {
      worksheet.addRow([org.status, org.name, org.technical_id]);
    });

    // Save the workbook as an Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${fileName}.xlsx`);
    });
  }
  generateExcelForContracts(data: any[], fileName: string): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Contract Data');

    const headers = ['Status', 'Name', 'WBS', 'Start Date', 'End Date'];
    worksheet.addRow(headers);

    // Add data rows
    data.forEach((contract) => {
      worksheet.addRow([contract.status, contract.name, contract.wbs, contract.start_date, contract.end_date]);
    });

    // Save the workbook as an Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${fileName}.xlsx`);
    });
  }
  generateExcelForServices(data: any[], fileName: string): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Services Data');

    const headers = ['Status', 'Name', 'Technical ID', 'App ID'];
    worksheet.addRow(headers);

    // Add data rows
    data.forEach((service) => {
      worksheet.addRow([service.status, service.name, service.technical_id, service.appid]);
    });

    // Save the workbook as an Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${fileName}.xlsx`);
    });
  }








  generateExcelForApplicationAdmin(data: any[], fileName: string): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Application Admin Data');

    // Add headers
    const headers = ['First Name', 'Last Name', 'Email', 'Application Name', 'Created On', 'End Date'];
    worksheet.addRow(headers);

    // Add data rows
    data.forEach((user) => {
      worksheet.addRow([user.firstname, user.lastname, user.email, user.email, user.created_on, user.end_date ]);
    });

    // Save the workbook as an Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${fileName}.xlsx`);
    });
  }
  generateExcelForApplication(data: any[], fileName: string): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Application Data');

    const headers = ['Application Name', 'App business owner', 'App Technical owner', 'user count', 'FSS', 'Client', 'Member firm hosting the app', 'Date the app was onboarded to CIAM', 'Client'];
    worksheet.addRow(headers);

    // Add data rows
    data.forEach((app) => {
      worksheet.addRow([app.name, app.contact_email, app.contact_email, app.user_count, app.fss, app.client ]);
    });

    // Save the workbook as an Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${fileName}.xlsx`);
    });
  }
  generateExcelForAllEmailOTP(data: any[], fileName: string): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Alll Email OTP Data');

    const headers = ['First Name', 'Last Name', 'Email', 'Status', 'Created On', 'Last Login', 'Preferred MFA', 'Email OTP', 'Email Otp Approval Date', 'User Type', 'Organizations'];
    worksheet.addRow(headers);

    // Add data rows
    data.forEach((user) => {
      worksheet.addRow([user.firstname, user.lastname, user.email, user.status, user.created_on, user.login_lastsuccess, user.preferredMFA, user.email_otp, user.emailotp_approvaldate, user.user_type, user.organizations]);
    });
    // Save the workbook as an Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${fileName}.xlsx`);
    });
  }
  generateExcelForOTP(data: any[], fileName: string): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('OTP Data');

    const headers = ['First Name', 'Last Name', 'Email', 'Status', 'Created On', 'Last Login', 'Preferred MFA', 'Email OTP', 'Email Otp Approval Date', 'User Type', 'Organizations'];
    worksheet.addRow(headers);

    // Add data rows
    data.forEach((user) => {
      worksheet.addRow([user.firstname, user.lastname, user.email, user.status, user.created_on, user.login_lastsuccess, user.preferredMFA, user.email_otp, user.emailotp_approvaldate, user.user_type, user.organizations]);
    });

    // Save the workbook as an Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${fileName}.xlsx`);
    });
  }
}

<div *ngIf="!resetFlag" class="login-page activate-page">
    <div class="main-container self-service-main-container">
        <div class="form-container self-service-form-container ">
            <div class="form">
                <div class="form-title title reset-text">
                    {{"views.public.pwdreset.Password Reset"| translate}}
                </div>
                <!-- Step 1: Set password -->
                <div class="form-controls controls-mb">
                    <div class="form-controls-container">
                        <form [formGroup]="passwordForm" name="passwordForm" class="form-horizontal">
                            <div class="form-group forms-controls-container" show-errors>
                                <input formControlName="tmpkey" id="password" [type]="itype" name="tmpkey"
                                    class="input input-password"
                                    placeholder="{{'views.public.pwdset.Password' | translate}}" (keyup)='check()'>
                                <i class=" eye-class fa fa-eye eye-dis" id="togglePassword" (click)="changeType()"></i>
                                <div class="main-text-error" *ngIf="tmpkeyFlag" >
                                    <span class="">
                                        {{"views.public.pwdset.Make sure your password contains:"| translate}}
                                        <ul>
                                            <li *ngIf="!passwordContainsTen">  {{"views.public.pwdset.At least 10 characters"| translate}} </li>
                                            <li *ngIf="passwordContainsTen" style="color: green;"> 
                                                {{"views.public.pwdset.At least 10 characters"| translate}}  </li>
                                            <li *ngIf="!passwordContainsUppercase">  {{"views.public.pwdset.Uppercase letters"| translate}} (A, B, C) </li>
                                            <li *ngIf="passwordContainsUppercase" style="color: green;"> 
                                                {{"views.public.pwdset.Uppercase letters"| translate}} (A, B, C) </li>
                                            <li *ngIf="!passwordContainsLowercase"> • {{"views.public.pwdset.Lowercase letters"| translate}} (a, b, c) </li>
                                            <li *ngIf="passwordContainsLowercase" style="color: green;"> 
                                                {{"views.public.pwdset.Lowercase letters"| translate}} (a, b, c) </li>
                                            <li *ngIf="!passwordContainsNumeral">  {{"views.public.pwdset.Numerals"|
                                                translate}} (0, 1, 2) </li>
                                            <li *ngIf="passwordContainsNumeral" style="color: green;"> •
                                                {{"views.public.pwdset.Numerals"| translate}} (0, 1, 2) </li>
                                            <li *ngIf="!passwordNonAlphaNumeric"> 
                                                {{"views.public.pwdset.Non-alphanumeric"| translate}} (#, &, !, %, &#64;, ?,
                                                -, *) </li>
                                            <li *ngIf="passwordNonAlphaNumeric" style="color: green;"> 
                                                {{"views.public.pwdset.Non-alphanumeric"| translate}} (#, &, !, %, &#64;, ?,
                                                -, *) </li>
                                                <li *ngIf="passwordHasIdenticalPattern"> 
                                                    {{"views.public.pwdset.passwordHasIdenticalPattern"| translate}} (#, &, !, %, @, ?,
                                                    -, *) </li>
                                            <li *ngIf="passwordHasConsecutivePattern"> 
                                                    {{"views.public.pwdset.passwordHasConsecutivePattern"| translate}} (#, &, !, %, @, ?,
                                                    -, *) </li>
                                            <li *ngIf="passwordHasDictionaryWord"> 
                                                    {{"views.public.pwdset.passwordHasDictionaryWord"| translate}} (#, &, !, %, @, ?,
                                                    -, *) </li>
                                        </ul>
                                    </span>
                                </div>
                                <div class="main-text-error">
                                    <p class="" *ngIf="(!pwddoesnotcontainuserattributes)">
                                        {{"views.public.pwdset.The password cannot contain your first name, last name or email address!" | translate}}
                                    </p>
                                </div>
                                <div class="main-text-error">
                                    <p class="" *ngIf="pwdAlreadyUsed">
                                        {{"views.public.pwdset.passwordHistoryFalse" | translate}}
                                    </p>
                                </div>
                            </div>
                            <div class="form-group forms-controls-container" show-errors>
                                <input formControlName="tmpkey2" id="password2" [type]="password2type" name="tmpkey2"
                                    class="input input-password" required
                                    placeholder="{{'views.public.pwdset.Repeat password' | translate}}"
                                    (keyup)="check()">
                                <i class="fa fa-eye eye-class eye-dis" id="togglePassword2"
                                    (click)="changePasswordtype2()"></i>
                                <div class="main-text-error">
                                    <p class="" *ngIf="(!matching && !passwordForm.controls.tmpkey2.errors?.required)">
                                        {{"views.public.pwdset.The passwords are not equal"| translate}}</p>
                                </div>
                            </div>
                        </form>
                        <button type="button" class="btn-form btn-black login-btn" (click)="pwdReset(passwordForm.value.tmpkey)"
                            [disabled]="!matching || !pwddoesnotcontainuserattributes || isFormInvalid() || pwdAlreadyUsed">
                            {{"views.public.pwdreset.Reset"| translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <app-terms-conditions></app-terms-conditions>
    </div>
</div>
<div *ngIf="resetFlag" class="main-container form-container-reset-password">
    <div class="form-container" style="max-width: 100% !important;">
        <div class="form" style="max-width: 100% !important;">
    <div  *ngIf="!(otpappLastchanged === undefined && mobile === ''  && mobile === null)" class="form-title title">
{{"views.public.pwdset.Choose Verification Method"| translate}}     
   </div>
   <form  name="preferredMFAForm" class="form-horizontal" role="form">
    <label  *ngIf="!(otpappLastchanged === undefined)" class="label-container mobile-primary-method-radio mobile-primary-method-radio-first" >
        <input type="radio"   name="radio" [(ngModel)]="preferredMFA" value="AuthenticatorApp" >
        <span class="label-text">{{'views.selfservice.Authenticator app'| translate}}</span>
        <span class="checkmark"></span>
    </label>
    <label *ngIf="!(mobile === '' || mobile === null || mobile === undefined)"  class="label-container mobile-primary-method-radio" >
        <input *ngIf="preferredMFA===sms" type="radio" name="radio"  [(ngModel)]="preferredMFA" value="sms" >
        <input *ngIf="preferredMFA===voice" type="radio" name="radio"  [(ngModel)]="preferredMFA" value="voice" >

        <span class="label-text">{{'views.public.pwdset.Phone' | translate}}</span>
        <div class="loader"   *ngIf="isLoadingResults">
            <div class="dot" style="--dot-index:0;"></div>
            <div class="dot" style="--dot-index:1;"></div>
            <div class="dot" style="--dot-index:2;"></div>
          </div> 
        <span class="checkmark"></span>
    </label>
    </form>
    <h1 *ngIf="otpappLastchanged === undefined && mobile === ''">Please set your MFA</h1>
    <div  class="authenticator-text-step" *ngIf="preferredMFA == 'AuthenticatorApp'">
        <!-- <b><span class="authenticator-text-step-label">{{"views.public.pwdset.Step 3"| translate}}</span></b> -->
        <p class="authenticator-text-step-description">{{"views.public.pwdset.Enter the one time password"| translate}}</p>
        <div class="confirm-form">
            <input [(ngModel)]="codevalidation"
                   type="password"
                   autocomplete="off"
                   name="codevalidation"
                   class="input"
                   (change) = "resetauthErrors()"
                   placeholder="{{'views.public.pwdset.Code'| translate}}"
                   [required] = "preferredMFA == 'AuthenticatorApp'">
            <button  type="button" class="confirm-btn" (click)="verifycodeauth($event,codevalidation)">{{"views.public.pwdset.Verify OTP"| translate}}</button>
        </div>
        <div *ngIf="loaderFlag" class="spinner-border" role="status">
            <span class="sr-only"></span>
          </div>
        <span style="color : #a94442" *ngIf="!verifyauthcodeFlag">{{errormessage | translate}}</span>
        <span style="color : green" *ngIf="verifyauthcodeFlag">{{successmessage | translate}}</span>

    </div>
    <div class="mobile-method-container" *ngIf="preferredMFA == 'sms' || preferredMFA == 'voice'">
        <!-- <span class="hint-text call-send-text" >{{"views.public.pwdset.Please enter your phone number to be registered."| translate}}</span> -->
        <span class="hint-text-phone call-send-text" style="line-height: 27px !important;">{{"view.public.pwdset.confirmation" | translate}} {{hideMobile}} </span>
<br> <br>
        <form  name="phoneForm" [formGroup]="phoneForm" class="form-horizontal" role="form" autocomplete="off" *ngIf="!(validateOTP)">
       
           <div class="confirm-form phone-call-form" style="width:187% !important;
            max-width: 222% !important;
            /* padding-left: 21px; */
           ">
                <ngx-intl-tel-input [preferredCountries]="preferredCountries"
                [cssClass]="'tel-input'" [enableAutoCountrySelect]="true"
                [enablePlaceholder]="true" [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, 
SearchCountryField.Name]" [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="15"
                [phoneValidation]="true" [separateDialCode]="true" name="mobile"
                formControlName="mobile_new"
                style="width :45%"
                (ngModelChange)="checkNumber()">
            </ngx-intl-tel-input>
              <!-- <input  placeholder="{{'view.public.pwdset.Format_Registered' | translate}}"
                       type="text"
                       id="newPhone"
                       name="mobile"
                       class="input input-password"
                       required
                       autocomplete="off"
                       ng-pattern="/^\+[1-9]\d{7,14}$/"
                       
                       
                       > -->
                      <!-- onclick="document.getElementsByName('preferredMFAForm')[0].reset();"> -->
                </div>
        
            <span *ngIf="validateMobileMessage(phoneForm,mobile_new)" style="color : #a94442">{{"views.public.pwdset.phone validation check" | translate}}</span>
            <span class="hint-text main-text-error" >{{otpErrorMsg}}</span>
            <span class="hint-text main-text-error" >{{otpErrorMsg}}</span>
           
     
        <div class="form-group" *ngIf="sendSMSClicked">
            <!-- Show the authenticator container if the OTP was not validated yet-->
            <div class="authenticator-container" *ngIf="!(validateOTP)">  
                <!--Step 3-->
                <div class="authenticator-text-step">                                
                   
                    <p class="authenticator-text-step-description">{{"views.public.pwdset.Entertheonetimepasswordregisteredmobile"| translate}}</p>
                    <div class="confirm-form">
                        <input formControlName="otpvalidation"
                               type="password"
                               autocomplete="off"
                               name="otpvalidation"
                               class="input"
                               placeholder="{{'views.public.pwdset.Code'| translate}}"
                               required>
                        <button class="confirm-btn" (click)="verifyOTP(mobile_new,otpvalidation)">{{"views.public.pwdset.Verify OTP"| translate}}</button>
                    </div>
                    <br/>
                    <a style="text-decoration: underline;color: #0d6efd; " (click)="sendOTP('sms',mobile_new)">Please resend code</a>
                </div>
            </div>
            <!--Show if OTP was valid-->
            <div *ngIf="validateOTP" class="mobile-method-container">
                <div class="confirm-form confirm-form-send">
                    <span class="send-password-text">{{"views.public.pwdset.OTPValid.mobile"| translate}}</span>
                </div>
            </div>
            <br />
            <!-- Show if OTP was not valid -->
            <div *ngIf="twiliootpalert !== null && twiliootpalert !== ''  && !validateOTP" class="mobile-method-container">
                <div class="confirm-form confirm-form-send">
                    <span class="main-text-error">{{twiliootpalert}}</span>
                </div>
            </div>
        </div>
    </form>
    </div> 

</div>
</div>
<div style="display: flex;margin-top: 50%;">
    <app-terms-conditions></app-terms-conditions>
</div>
</div>